import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import LockerAddModal from './LockerAddModal';
import LockerInfo from '../../../Component/LockerComponent/LockerInfo';

import FetchModule from '../../Share/Network/FetchModule';
import LockerChangeNameModal from './LockerChangeNameModal';
import LockerUsingStopModal from './LockerUsingStopModal';
import LockerStartModal from './LockerStartModal';
import LockerHistoryModal from './LockerHistoryModal';
import CustomAlert from '../../Share/Normal/CustomAlert';
import LockerExtendsModal from './LockerExtendsModal';
import LockerDivisionInput from '../../../Component/LockerComponent/LockerDivisionInput';

import WhiteTrashIcon from '../../../image/WhiteTrashIcon.png';

function LockerView() {
  const history = useHistory();

  const [isClickedAdd, setIsClickedAdd] = useState(false);
  const [isClickedChangeName, setIsClickedChangeName] = useState(false);
  const [isClickedStart, setIsClickedStart] = useState(false);
  const [isClickedStop, setIsClickedStop] = useState(false);
  const [isClickedHistory, setIsClickedHistory] = useState(false);
  const [isClickedExtend, setIsClickedExtend] = useState(false);
  const [isViewCustomAlert, setIsViewCustomAlert] = useState(false);

  const [lockerDivisions, setLockerDivisions] = useState([]);
  const [lockerInfo, setLockerInfo] = useState(undefined);
  const [isClickedAddLockerDivision, setIsClickedAddLockerDivision] = useState(false);

  const [newLockerName, setNewLockerName] = useState('');
  const [isInProgressAddLocker, setIsInProgressAddLocker] = useState(false);
  const [isInProgressDeleteLocker, setIsInProgressDeleteLocker] = useState(false);
  const [isInProgressLockerDivision, setIsInProgressLockerDivision] = useState(false);
  const [isClickedLockerDivisionId, setIsClickedLockerDivisionId] = useState(0);
  const [totalLockers, setTotalLockers] = useState([]);
  const [selectedAddLockerDivision, setSelectedAddLockerDivision] = useState({ id: 0, name: '전체' });

  const [text, setText] = useState('');
  const [lockerData, setLockerData] = useState([]);
  const [index, setIndex] = useState(-1);
  const [lockerIndex, setLockerIndex] = useState(-1);
  const [rendering, setRendering] = useState(false);

  const AddModalRef = useRef();
  const NameModalRef = useRef();
  const StartModalRef = useRef();
  const LockerRef = useRef([]);

  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    // 	async () => {
    // 		// const responseData = await fetchModule.getData('locker/', 'GET');
    // 		const responseData = await fetchModule.postData('locker/', 'POST');

    // 		if (responseData.status === 200) {
    //       setLockerData(responseData.data);
    // 		}
    // 		else if (responseData.status === 401) {
    //       alert("로그인 후 사용하세요");
    //       history.push('/');
    //     }
    // 	}
    // )();

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('locker/', 'GET');

        if (responseData.status === 200) {
          let divisions = responseData.data.lockerDivisions.slice();
          let lockers = responseData.data.lockers.slice();
          let newTotalLockers = [];

          divisions.unshift({ id: 0, name: '전체' });

          // newTotalLockers.push({ id: 0, name: '전체', lockers: [] });

          for (let i = 0; i < divisions.length; i++) {
            let newDivision = {
              id: divisions[i].id,
              name: divisions[i].name,
              lockers: []
            }

            newTotalLockers.push(newDivision);
          }
          // setLockerData(lockers);
          // console.log(lockers);
          for (let i = 0; i < lockers.length; i++) {
            if (lockers[i].lockerDivisionId === undefined || lockers[i].lockerDivisionId === 0) newTotalLockers[0].lockers.push(lockers[i]);
            else {
              for (let j = 0; j < newTotalLockers.length; j++) {
                if (lockers[i].lockerDivisionId === newTotalLockers[j].id) {
                  newTotalLockers[j].lockers.push(lockers[i]);
                  break;
                }
              }
            }
          }

          // console.log(newTotalLockers);

          setLockerDivisions(divisions);
          setTotalLockers(newTotalLockers);
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }, [history, rendering])

  // 락커 구분 이름 결정 //
  function decideDivisionText() {
    if (isClickedLockerDivisionId === 0) return '전체';
    else {
      for (let i = 0; i < lockerDivisions.length; i++) {
        if (lockerDivisions[i].id === isClickedLockerDivisionId) return lockerDivisions[i].name;
      }
    }
  }

  function modalExitClick() {
    setSelectedAddLockerDivision({ id: 0, name: '전체' });
    setIsClickedAdd(false);
  }

  function addModalSaveClick() {
    // var value = AddModalRef.current.getValue();

    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postData('locker/create/'
    //       // + window.sessionStorage.getItem('StoreId') 
    //       + window.localStorage.getItem('StoreId')
    //       + '/'
    //       + value, 'POST');

    //     if (responseData.status === 200) {
    //       setRendering(!rendering);
    //       setIsClickedAdd(false);
    //     }
    //     else if (responseData.status === 204) { }
    //     else if (responseData.status === 401) {
    //       alert("로그인 후 사용하세요");
    //       history.push('/');
    //     }
    //   }
    // )();


    let count = AddModalRef.current.getValue();

    if (count === 0) {
      alert('추가할 락커 개수를 입력해주세요');
      return;
    }
    else if (isInProgressAddLocker) {
      alert('락커 저장 진행중입니다');
      return;
    }

    setIsInProgressAddLocker(true);

    let requestData = {
      count: count,
      lockerDivisionId: selectedAddLockerDivision.id === 0 ? null : selectedAddLockerDivision.id
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('locker/save', 'POST', requestData);

        if (responseData.status === 200) {
          setRendering(!rendering);
          setIsInProgressAddLocker(false);
          setIsClickedAdd(false);
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
          setIsInProgressAddLocker(false);
        }
        else {
          alert('락커 추가에 실패했습니다\n잠시 후 다시시도해주세요');
          setIsInProgressAddLocker(false);
        }
      }
    )();
  }

  function changeModalSaveClick(name) {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.patchData('locker/modify/name/'
          + totalLockers[index].lockers[lockerIndex].id
          + '/'
          + name, 'PATCH');

        if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedChangeName(false);
          setIndex(-1);
          clickMoreButton();
        }
        else if (responseData.status === 204) { }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  function clickMoreButton() {
    // for (var i = 0; i < lockerData.length; i++) {
    //   LockerRef.current[i].setClickedMore(false);
    // }

    for (let i = 0; i < totalLockers.length; i++) {
      for (let j = 0; j < totalLockers[i].lockers.length; j++) {
        LockerRef.current[i][j].setClickedMore(false);
      }
    }
  }

  function clickUsageHistory(index, lockerIndex) {
    setIndex(index);
    setLockerIndex(lockerIndex);
    setIsClickedHistory(true);
  }

  //
  function clickChangeState(index, lockerIndex) {
    if (totalLockers[index].lockers[lockerIndex].state === '사용중') {
      setText('사용 종료후 다시시도 해주세요');
      setIsViewCustomAlert(true);
      return;
    }
    else if (totalLockers[index].lockers[lockerIndex].state === '사용불가') {
      setText('이미 잠금 상태입니다');
      setIsViewCustomAlert(true);
      return;
    }

    let state = '';

    if (totalLockers[index].lockers[lockerIndex].state === '비어있음') state = 'Invalid';

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.patchData('locker/modify/'
          + totalLockers[index].lockers[lockerIndex].id
          + '/'
          + state, 'PATCH');

        if (responseData.status === 200) {
          setRendering(!rendering);
          clickMoreButton();
        }
        else if (responseData.status === 204) { }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }


  function clickDeleteLocker(index) {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.deleteData('locker/delete/'
          + totalLockers[index].lockers[lockerIndex].id, 'DELETE');

        if (responseData.status === 200) {
          setRendering(!rendering);
          clickMoreButton();
        }
        else if (responseData.status === 205) {
          // alert("사용중인 락커입니다. 사용을 종료 후 다시시도해 주세요.");
          setText('사용중인 락커입니다.\n사용을 종료 후 다시시도해 주세요.');
          setIsViewCustomAlert(true);
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  function clickChangeName(index, lockerIndex) {
    setIndex(index);
    setLockerIndex(lockerIndex);
    setIsClickedChangeName(true);
  }

  function clickUsingStartOrEndOrChangeValid(index, lockerIndex) {
    setIndex(index);
    setLockerIndex(lockerIndex);

    let state = '';

    if (totalLockers[index].lockers[lockerIndex].state === '사용불가') {
      state = 'Empty';

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.patchData('locker/modify/'
            // + lockerData[index].id
            + totalLockers[index].lockers[lockerIndex].id
            + '/'
            + state, 'PATCH');

          if (responseData.status === 200) {
            setRendering(!rendering);
            clickMoreButton();
          }
          else if (responseData.status === 204) { }
          else if (responseData.status === 401) {
            alert("로그인 후 사용하세요");
            history.push('/');
          }
        }
      )();
      return;
    }

    if (totalLockers[index].lockers[lockerIndex].state === '사용중') setIsClickedStop(true);
    else if (totalLockers[index].lockers[lockerIndex].state === '비어있음') setIsClickedStart(true);
  }

  function startModalSaveClick() {

    var storeId = parseInt(window.localStorage.getItem('StoreId'));
    var lockerId = totalLockers[index].lockers[lockerIndex].id;
    // var lockerName = lockerData[index].lockerName;
    var memberId = StartModalRef.current.getMemberData().memberId;
    // var price = StartModalRef.current.getPrice();
    var card = StartModalRef.current.getCard();
    var cash = StartModalRef.current.getCash();
    var approvalDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    var startDate = StartModalRef.current.getStartDate() === '' ? '' : StartModalRef.current.getStartDate().format('YYYY-MM-DD') + 'T' + approvalDate.split('T')[1];
    var endDate = StartModalRef.current.getEndDate() === '' ? '' : StartModalRef.current.getEndDate().format('YYYY-MM-DD') + 'T23:59:59';

    // if (price - (cardPrice + cashPrice) < 0) {
    //   alert('결제금액보다 큰 금액을 입력할 수 없습니다.');
    //   return;
    // }
    if (startDate === '') {
      // alert('시작일을 선택해 주세요');
      setText('시작일을 선택해 주세요');
      setIsViewCustomAlert(true);
      return;
    }
    else if (endDate === '') {
      // alert('종료일을 선택해 주세요');
      setText('종료일을 선택해 주세요');
      setIsViewCustomAlert(true);
      return;
    }
    else if (memberId === 0 || memberId === undefined) {
      // alert('사용할 회원을 선택해주세요');
      setText('사용할 회원을 선택해주세요');
      setIsViewCustomAlert(true);
      return;
    }

    var data = {
      storeId: storeId,
      lockerId: lockerId,
      memberId: memberId,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate,
      cardPrice: card === 0 ? card : card.replaceAll(',', ''),
      cashPrice: cash === 0 ? cash : cash.replaceAll(',', '')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('locker/start', 'POST', data);

        if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedStart(false);
          setIndex(-1);
          clickMoreButton();
        }
        else if (responseData.status === 204) {
          if (responseData.error === 'Locker Already Using') {
            // alert("이미 사용중인 락커입니다.");
            setText('이미 사용중인 락커입니다.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.error === 'Invalid Using') {
            // alert("사용이 불가능한 락커입니다.");
            setText('사용이 불가능한 락커입니다.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.error === 'Invalid User Data') {
            // alert("올바르지 않은 회원 정보입니다.");
            setText('올바르지 않은 회원 정보입니다.');
            setIsViewCustomAlert(true);
          }
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  function stopModalSaveClick() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/stop/' + totalLockers[index].lockers[lockerIndex].id, 'POST');

        if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedStop(false);
          setIndex(-1);
          clickMoreButton();
        }
        else if (responseData.status === 204) { }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  // 문자발송 버튼 클릭시
  function onClickSendMessageButton(index) {

    let text = lockerData[index].userName + '님, 지금까지 이용하셨던 락커 ' + lockerData[index].lockerName + '이(가) 만료되었어요';

    let requestData = {
      userId: lockerData[index].linkCoachId,
      data: text,
      location: 'Reservation',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);

        if (responseData.status === 200) {
          // alert("푸시알림을 전송하였습니다!");
          setText('푸시알림을 전송하였습니다!');
          setIsViewCustomAlert(true);
          LockerRef.current[index].setClickedMore(false);
        }
        else if (responseData.status === 204) {
          // alert("Link Coach를 탈퇴한 회원입니다!");
          setText('Link Coach에 가입되어있지 않은 회원입니다!');
          setIsViewCustomAlert(true);
          LockerRef.current[index].setClickedMore(false);
        }
      }
    )();
  }

  // 사용 연장버튼 클릭시 //
  function onClickExtendsButton(index, lockerIndex) {
    // setLockerInfo(lockerData[index]);

    setIndex(index);
    setLockerIndex(lockerIndex);
    setIsClickedExtend(true);
  }

  // 사용 내역 연장 모달 내에서 저장하기 버튼 클릭시 //
  function onClickSaveExtends(endDate, cardPrice, cashPrice) {
    // console.log(lockerInfo);
    // console.log(endDate.clone().format('YYYY-MM-DDTHH:mm:ss'));

    if (moment().clone().format('YYYY-MM-DDTHH:mm:ss') > endDate.clone().format('YYYY-MM-DDTHH:mm:ss')) {
      alert('사용 날짜 변경은 금일 보다 이전일 수 없습니다.');
      return;
    }

    // let requestData = {
    //   lockerId: lockerInfo.id,
    //   usageId: lockerInfo.usageId,
    //   endDate: endDate.clone().format('YYYY-MM-DDT23:59:59'),
    //   merchantUid: lockerInfo.merchantUid,
    //   cardPrice: parseInt(cardPrice.toString().replaceAll(',', '')),
    //   cashPrice: parseInt(cashPrice.toString().replaceAll(',', ''))
    // }

    let requestData = {
      lockerId: totalLockers[index].lockers[lockerIndex].id,
      usageId: totalLockers[index].lockers[lockerIndex].usageId,
      endDate: endDate.clone().format('YYYY-MM-DDT23:59:59'),
      merchantUid: totalLockers[index].lockers[lockerIndex].merchantUid,
      cardPrice: parseInt(cardPrice.toString().replaceAll(',', '')),
      cashPrice: parseInt(cashPrice.toString().replaceAll(',', ''))
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/extend', 'POST', requestData);

        if (responseData.status === 200) {
          alert('변경이 완료되었습니다.');
          setRendering(!rendering);
          setIsClickedExtend(false);
        }
      }
    )();
  }

  // 락커 추가 버튼 클릭시 //
  function onClickLockerDivisionAddButton() {
    if (isClickedAddLockerDivision) {
      if (newLockerName === '') {
        alert('추가할 락커구분 이름을 입력해주세요');
        return;
      }
      else if (isInProgressLockerDivision) {
        alert('추가를 진행중입니다');
        return;
      }

      setIsInProgressLockerDivision(true);

      let requestData = {
        name: newLockerName
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('locker/division/save', 'POST', requestData);

          if (responseData.status === 200) {
            let divisions = lockerDivisions.slice();

            divisions.push(responseData.data);
            setLockerDivisions(divisions);
            setIsInProgressLockerDivision(false);
            setIsClickedAddLockerDivision(false);
          }
        }
      )();
    }
    else setIsClickedAddLockerDivision(true);
  }

  // 락커 구분 삭제 버튼 클릭시 //
  function onClickLockerDivisionDeleteButton() {
    if (isInProgressDeleteLocker) {
      alert('락커 구분 삭제를 진행중입니다');
      return;
    }

    setIsInProgressDeleteLocker(true);

    let requestData = {
      lockerDivisionId: isClickedLockerDivisionId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('locker/division/delete', 'POST', requestData);

        if (responseData.status === 200) {
          alert('삭제가 완료되었습니다');
          setRendering(!rendering);
          setIsClickedLockerDivisionId(0);
          setIsInProgressDeleteLocker(false);
        }
        else if (responseData.status === 201) {
          alert('락커가 존재합니다\n락커를 모두 삭제 후 다시시도해주세요');
          setIsInProgressDeleteLocker(false);
        }
        else {
          alert('락커 구분 삭제가 실패하였습니다\n잠시 후 다시시도해주세요');
          setIsInProgressDeleteLocker(false);
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {isViewCustomAlert && <CustomAlert text={text} closeFn={() => setIsViewCustomAlert(false)} />}
      {
        isClickedAdd &&
        <LockerAddModal
          ref={AddModalRef}
          lockerDivisions={lockerDivisions}
          selectedAddLockerDivision={selectedAddLockerDivision}
          setSelectedAddLockerDivision={setSelectedAddLockerDivision}
          exitClick={modalExitClick}
          saveClick={addModalSaveClick} />
      }
      {isClickedChangeName && <LockerChangeNameModal ref={NameModalRef} data={totalLockers} index={index} lockerIndex={lockerIndex} exitClick={() => setIsClickedChangeName(false)} saveClick={changeModalSaveClick} />}
      {isClickedStop && <LockerUsingStopModal data={totalLockers[index].lockers[lockerIndex]} exitClick={() => setIsClickedStop(false)} saveClick={stopModalSaveClick} />}
      {isClickedStart && <LockerStartModal ref={StartModalRef} data={totalLockers[index].lockers[lockerIndex]} exitClick={() => setIsClickedStart(false)} saveClick={startModalSaveClick} />}
      {isClickedHistory && <LockerHistoryModal data={totalLockers[index].lockers[lockerIndex]} exitClick={() => setIsClickedHistory(false)} />}
      {isClickedExtend && <LockerExtendsModal data={totalLockers[index].lockers[lockerIndex]} saveFn={onClickSaveExtends} closeFn={() => setIsClickedExtend(false)} />}
      <TopComponentWrapper>
        <TitleWrapper>
          <Title>락커</Title>
          <Explanation>센터의 락커 관리와 이용 현황을 알 수 있어요</Explanation>
        </TitleWrapper>
        <AddButton onClick={() => setIsClickedAdd(true)}>락커추가</AddButton>
      </TopComponentWrapper>
      <LockerDivisionButtonWrapper>
        {
          lockerDivisions.map((data, index) => (
            <LockerDivisionButton
              key={'LockerDivision-' + index}
              isClicked={isClickedLockerDivisionId === data.id}
              onClick={() => setIsClickedLockerDivisionId(data.id)}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={isClickedLockerDivisionId === data.id ? '#FFFFFF' : '#CBCBCB'} hover='pointer'>{data.name}</Text>
            </LockerDivisionButton>
          ))
        }
        { isClickedAddLockerDivision && <LockerDivisionInput value={newLockerName} setValue={setNewLockerName} /> }
        <LockerAdditionButton isClicked={isClickedAddLockerDivision} onClick={onClickLockerDivisionAddButton}>
          {
            isClickedAddLockerDivision ?
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={'#FFFFFF'} hover='pointer'>추가</Text>
              :
              <LockerPlusWrapper>
                <Stick width={12} height={2} />
                <Stick width={2} height={12} />
              </LockerPlusWrapper>
          }
        </LockerAdditionButton>
      </LockerDivisionButtonWrapper>
      <TotalViewBoxWrapper>
        <DivisionBoxWrapper>
          <DivisionBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{decideDivisionText()}</Text>
          </DivisionBox>
          {
            isClickedLockerDivisionId !== 0 &&
            <DivisionDeleteBox onClick={onClickLockerDivisionDeleteButton}>
              <DivisionDeleteIcon src={WhiteTrashIcon}/>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>삭제하기</Text>
            </DivisionDeleteBox>
          }
        </DivisionBoxWrapper>
        <ViewBox>
          {/* <InfoBox>
            {
              lockerData.map((data, index) => (
                <LockerInfo
                  key={index}
                  data={data}
                  index={index}
                  ref={element => (LockerRef.current[index] = element)}
                  clickUsageHistoryFn={clickUsageHistory}
                  clickFunctionFn={clickUsingStartOrEndOrChangeValid}
                  clickChangeStateFn={clickChangeState}
                  clickDeleteLockerFn={clickDeleteLocker} 
                  clickChangeNameFn={clickChangeName}
                  clickSendMessageFn={onClickSendMessageButton}
                  clickExtendFn={onClickExtendsButton}/>
              ))
            }
          </InfoBox> */}
          {
            <DivisionInfoBox>
              {
                totalLockers.map((data, index) => (
                  (isClickedLockerDivisionId === 0 || data.id === isClickedLockerDivisionId) &&
                  <InfoBoxWrapper>
                    {
                      (isClickedLockerDivisionId === 0 && index !== 0) &&
                      <DivisionNameBoxWrapper>
                        <DivisionNameBox>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='default'>{data.name}</Text>
                        </DivisionNameBox>
                      </DivisionNameBoxWrapper>
                    }
                    <InfoBox>
                      {
                        data.lockers.map((locker, lockerIndex) => (
                          <LockerInfo
                            key={lockerIndex}
                            data={locker}
                            index={index}
                            lockerIndex={lockerIndex}
                            // ref={element => (LockerRef.current[(index) * (lockerIndex)] = element)}
                            ref={element => {
                              // 해당 row 배열이 없으면 초기화
                              if (!LockerRef.current[index]) {
                                LockerRef.current[index] = [];
                              }
                              // rowIndex, colIndex에 해당하는 위치에 element 할당
                              LockerRef.current[index][lockerIndex] = element;
                            }}
                            clickUsageHistoryFn={clickUsageHistory}
                            clickFunctionFn={clickUsingStartOrEndOrChangeValid}
                            clickChangeStateFn={clickChangeState}
                            clickDeleteLockerFn={clickDeleteLocker}
                            clickChangeNameFn={clickChangeName}
                            clickSendMessageFn={onClickSendMessageButton}
                            clickExtendFn={onClickExtendsButton} />
                        ))
                      }
                    </InfoBox>
                    {(isClickedLockerDivisionId === 0 && totalLockers.length - 1 !== index) && <DivisionLine />}
                  </InfoBoxWrapper>
                ))
              }
            </DivisionInfoBox>
          }
        </ViewBox>
      </TotalViewBoxWrapper>
    </TopLevelWrapper>
  )
}

export default LockerView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 14px;
`;

const TopComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 674px;
  margin-left: 28px;
`;


const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 38px;

  border: 1px solid #E9E9E9;
  border-radius: 10px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const LockerDivisionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 24px;

  width: 100%;

  gap: 10px;
`;

const LockerDivisionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 5px 20px;

  border: 1px solid #DFDFDF;
  border-radius: 10px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const LockerAdditionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 49px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 10px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#969696'};

  :hover {
    cursor: pointer;
  }
    
  transition: all 0.3s ease-in-out;
`;

const LockerPlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
`;

const Stick = styled.div`
  position: absolute;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 4px;
  background-color: #FFFFFF;
`;

const TotalViewBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;

  margin-top: 38px;
  margin-bottom: 96px;
  margin-left: 28px;
`;

const DivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  height: 25px;
  
  border-radius: 5px 5px 0px 0px;
  background-color: #969696;
`;

const DivisionDeleteBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 25px;
  
  gap: 10px;

  border-radius: 5px 5px 0px 0px;
  background-color: #FF3131;

  :hover {
    cursor: pointer;
  }
`;

const DivisionDeleteIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 1020px;

  // margin-top: 38px;
  // margin-bottom: 96px;
  // margin-left: 28px;

  padding: 28px 0px;

  width: 100%;

  border-radius: 0px 0px 20px 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  // width: 962px;
  width: 100%;

  gap: 18px;

  // margin-top: 28px;
  // margin-bottom: 28px;
`;

const DivisionInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 100%;
  width: 962px;
  // width: 964px;
`;

const DivisionNameBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 16px;

  width: 100%;
`;

const DivisionNameBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 21px;

  border-radius: 5px;
  background-color: #969696;
`;

const DivisionLine = styled.div`
  margin: 35px 0px;

  width: 100%;
  height: 1px;

  border-radius: 2px;
  background-color: #D9D9D9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;