import { React, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import RegistrationTopView from './RegistrationTopView.js';
import MemberDefaultInfoView from './MemberDefaultInfoView.js';
import MemberShipInfoView from './MemberShipInfoView.js';

import PaymentModal from './PaymentModal.js';

import FetchModule from '../../Share/Network/FetchModule.js';
import SessionStorage from '../../Share/Network/SessionStorage.js';
import moment from 'moment';
import CustomAlert from '../../Share/Normal/CustomAlert.js';
import AddLockerView from './AddLockerView.js';
import MemberRegistAllPaymentModal from './MemberReigstAllPaymentModal.js';

const Registration = () => {
  const history = useHistory();
  
  const [isClicked, setIsClicked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productCash, setProductCash] = useState(0);
  const [productDiscountPrice, setProductDiscountPrice] = useState(0);
  const [productNotReceivedPrice, setProductNotReceivedPrice] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');

  const [totalRegistData, setTotalRegistData] = useState(undefined);

  const [isFindUserId, setIsFindUserId] = useState(false);
  const [isAgreementEmphasize, setIsAgreementEmphasize] = useState(false);
  const [isTermsEmphasize, setIsTermsEmphasize] = useState(false);
  const [isClickedAddLocker, setIsClickedAddLocker] = useState(false);
  const [isUsePaymentSystem, setIsUsePaymentSystem] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);


  const [terms, setTerms] = useState({
    id: 0,
    registrationDate: '2000-01-01 00:00:00',
    termsUrl: ''
  })
  
  const MemberDefaultInfoRef = useRef();
  const MemberShipInfoRef = useRef();
  const LockerInfoRef = useRef();

  // 약관 데이터 받아오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getIndividualData('center_terms/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) setTerms(responseData.data[0]);
			}
		)();
  }, [])

  // 링크페이 사용여부 확인하기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getIndividualData('store/check/linkpay', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) setIsUsePaymentSystem(responseData.data[0]);
			}
		)();
  }, [])

  // 회원 데이터 세팅 확인 //
  function checkMemberShipInfo() {
    let startDate = MemberShipInfoRef.current.getStartDate();
    let endDate = MemberShipInfoRef.current.getEndDate();
    let approvalDate = MemberShipInfoRef.current.getApprovalDate();
    let memberShipData = MemberShipInfoRef.current.getMemberShipData();

    if (startDate === '') {
      alert('회원권 시작날짜를 설정해주세요');
      return false;
    }

    if (endDate === '') {
      alert('회원권 종료날짜를 설정해주세요');
      return false;
    }

    if (approvalDate === '') {
      alert('회원권 결제 날짜를 설정해주세요');
      return false;
    }

    if (memberShipData === '') {
      alert('회원권을 선택해주세요');
      return false;
    }

    return true;
  }

  // 회원 등록 //
  function onRegist() {
    if (!MemberDefaultInfoRef.current.getAgreement()) {
      setIsAgreementEmphasize(true);
      alert("개인정보 처리방침에 동의해주세요");
      return;
    }

    if (terms.id !== 0 && !MemberShipInfoRef.current.getAgreeCenterTerms()) {
      setIsTermsEmphasize(true);
      alert("센터 가입약관에 동의해주세요");
      return;
    }

    if (MemberDefaultInfoRef.current.getName() === '') {
      alert("회원의 이름을 입력해주세요");
      return;
    }

    if (MemberDefaultInfoRef.current.getBirth() === '' || MemberDefaultInfoRef.current.getBirth().length !== 8) {
      alert("생년월일을 정확히 입력해주세요");
      return;
    }

    if ((MemberDefaultInfoRef.current.getPhone() === '' || MemberDefaultInfoRef.current.getPhone().length !== 11) && !MemberDefaultInfoRef.current.getNoHavePhone()) {
      alert("휴대전화 번호를 정확히 입력해주세요");
      return;
    }

    if (!MemberDefaultInfoRef.current.getIsFindUserId()) {
      alert('전화번호 입력후 확인 버튼을 눌러주세요');
      return;
    }

    let startDate = moment(MemberShipInfoRef.current.getStartDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss'));
    let endDate = moment(MemberShipInfoRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59');

    if (endDate.isBefore(startDate)) {
      alert('종료일자를 올바르게 입력해주세요');
      return;
    }

    let checkMemberShipId = MemberShipInfoRef.current.getMemberShipId();

    if (checkMemberShipId === 0) {
      alert('회원권을 선택해 주세요');
      return;
    }

    let lockerInfo = LockerInfoRef.current.getValue();

    if (isClickedAddLocker && lockerInfo.lockerId === 0) {
      alert('사용할 락커를 선택해주세요');
      return;
    }
    
    if (checkMemberShipInfo()) {
      // 회원권 데이터 //
      let memberShipData = MemberShipInfoRef.current.getMemberShipData();
      let memberShipCardPrice = MemberShipInfoRef.current.getCardPrice();
      let memberShipCashPrice = MemberShipInfoRef.current.getCashPrice();
      let memberShipDiscountPrice = MemberShipInfoRef.current.getDiscountPrice();
      let memberShipNotReceivedPrice = MemberShipInfoRef.current.getNotReceivePrice();
      // 회원권 데이터 //

      let noHavePhone = MemberDefaultInfoRef.current.getNoHavePhone();
      let id = MemberDefaultInfoRef.current.getId();
      let phone = MemberDefaultInfoRef.current.getPhone();
      let userId = '';

      // 락커 데이터 //
      let lockerName = '';
      let lockerCardPrice = 0;
      let lockerCashPrice = 0;
      let lockerDiscountPrice = 0;

      if (isClickedAddLocker) {
        lockerName = lockerInfo.lockerName;
        lockerCardPrice = lockerInfo.lockerCardPrice;
        lockerCashPrice = lockerInfo.lockerCashPrice;
        lockerDiscountPrice = lockerInfo.lockerDiscountPrice;
      }
      // 락커 데이터 //

      // 휴대전화를 가지고있지 않으면 임시 번호 발급 //
      if (noHavePhone) {
        userId = 'None-' + localStorage.getItem('StoreId') + '-' + moment().format('YYMMDDHHmmssss');
        window.localStorage.setItem('TempUserId', userId);
      }
      else if (id === '') {
        userId = 'None-' + phone;
      }
      else {
        userId = id;
      }

      let cardPrice = memberShipCardPrice + lockerCardPrice;
      let cashPrice = memberShipCashPrice + lockerCashPrice;
      let discountPrice = memberShipDiscountPrice + lockerDiscountPrice;
      let notReceivedPrice = memberShipNotReceivedPrice;

      let requestData = {
        itemName: isClickedAddLocker ? memberShipData.name + ', ' + lockerName + ' 락커 이용권' : memberShipData.name,
        cashPrice: cashPrice,
        cardPrice: cardPrice,
        discountPrice: discountPrice,
        notReceivedPrice: notReceivedPrice,

        storeId: window.localStorage.getItem('StoreId'),
        status: 0,
        userId: userId
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/merchant', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setProductName(responseData.data[0].itemName);
            setProductCoach('');
            setProductValidity(memberShipData.validity);
            setProductPrice(responseData.data[0].cardPrice);
            setProductCash(responseData.data[0].cashPrice);
            setProductMerchantUid(responseData.data[0].merchantUid);
            setProductDiscountPrice(responseData.data[0].discountPrice);
            setProductNotReceivedPrice(responseData.data[0].notReceivedPrice);


            let name = MemberDefaultInfoRef.current.getName();
            let birthDay = MemberDefaultInfoRef.current.getBirth();
            let gender = MemberDefaultInfoRef.current.getGender();
            let phone = MemberDefaultInfoRef.current.getPhone();
            let id = MemberDefaultInfoRef.current.getId();
            let memberShipName = MemberShipInfoRef.current.getMemberShipName();
            let profile = MemberDefaultInfoRef.current.getProfile();
            let noHavePhone = MemberDefaultInfoRef.current.getNoHavePhone();
            let lockerInfo = LockerInfoRef.current.getValue();
            let userId = '';
        
            // 휴대전화를 가지고있지 않으면 임시 번호 발급 //
            if (noHavePhone) userId = window.localStorage.getItem('TempUserId');
            else if (id === '') userId = 'None-' + phone; 
            else  userId = id; 
        
            let data = {
              storeId: window.localStorage.getItem('StoreId'),
              birthDay: birthDay,
              linkCoachId: userId,
              name: name,
              phone: phone,
              gender: gender,
              profile: profile === undefined ? '' : profile,
              memberDetail: MemberDefaultInfoRef.current.getMemberDetail(),
              approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
              memberShipId: MemberShipInfoRef.current.getMemberShipId(),
              memberShipName: memberShipName,
              courseTakeCount: 0,
              merchantUid: responseData.data[0].merchantUid,
              totalMax: MemberShipInfoRef.current.getTotalMax(),
              startDate: MemberShipInfoRef.current.getStartDate().replaceAll('.', '-') + 'T00:00:00',
              endDate: MemberShipInfoRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59',
              cashPrice: MemberShipInfoRef.current.getCashPrice(),
              cardPrice: MemberShipInfoRef.current.getCardPrice(),
        
              // 미수금 넣기 //
              notReceivePrice: MemberShipInfoRef.current.getNotReceivePrice(),
              approvalDate: MemberShipInfoRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss'),
              // 미수금 넣기 //
        
              // 락커 데이터 //
              isAddLocker: isClickedAddLocker,
              lockerId: isClickedAddLocker ? lockerInfo.lockerId : 0,
              lockerCardPrice: isClickedAddLocker ? lockerInfo.lockerCardPrice : 0,
              lockerCashPrice: isClickedAddLocker ? lockerInfo.lockerCashPrice : 0,
              lockerDiscountPrice: isClickedAddLocker ? lockerInfo.lockerDiscountPrice : 0,
              lockerStartDate: isClickedAddLocker ? lockerInfo.lockerStartDate.replaceAll('.', '-') + 'T00:00:00' : null,
              lockerEndDate: isClickedAddLocker ? lockerInfo.lockerEndDate.replaceAll('.', '-') + 'T23:59:59' : null
              // 락커 데이터 //
            }

            setTotalRegistData(data);
            setIsClicked(true);
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
        }
      )();
    }
  }

  // 현장 결제 후 처리 //
  function cancelFunction(merchant_uid) {
    if (isInProgress) {
      alert('저장이 진행중입니다.');
      return;
    }

    setIsInProgress(true);

    let name = MemberDefaultInfoRef.current.getName();
    let birthDay = MemberDefaultInfoRef.current.getBirth();
    let gender = MemberDefaultInfoRef.current.getGender();
    let phone = MemberDefaultInfoRef.current.getPhone();
    let id = MemberDefaultInfoRef.current.getId();
    let memberShipName = MemberShipInfoRef.current.getMemberShipName();
    let profile = MemberDefaultInfoRef.current.getProfile();
    let noHavePhone = MemberDefaultInfoRef.current.getNoHavePhone();
    let lockerInfo = LockerInfoRef.current.getValue();
    let userId = '';

    // 휴대전화를 가지고있지 않으면 임시 번호 발급 //
    if (noHavePhone) userId = window.localStorage.getItem('TempUserId');
    else if (id === '') userId = 'None-' + phone; 
    else  userId = id; 

    let data = {
      storeId: window.localStorage.getItem('StoreId'),
      birthDay: birthDay,
      linkCoachId: userId,
      name: name,
      phone: phone,
      gender: gender,
      profile: profile === undefined ? '' : profile,
      memberDetail: MemberDefaultInfoRef.current.getMemberDetail(),
      approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
      memberShipId: MemberShipInfoRef.current.getMemberShipId(),
      memberShipName: memberShipName,
      courseTakeCount: 0,
      merchantUid: merchant_uid,
      totalMax: MemberShipInfoRef.current.getTotalMax(),
      startDate: MemberShipInfoRef.current.getStartDate().replaceAll('.', '-') + 'T00:00:00',
      endDate: MemberShipInfoRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59',
      cashPrice: MemberShipInfoRef.current.getCashPrice(),
      cardPrice: MemberShipInfoRef.current.getCardPrice(),

      // 미수금 넣기 //
      notReceivePrice: MemberShipInfoRef.current.getNotReceivePrice(),
      approvalDate: MemberShipInfoRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss'),
      // 미수금 넣기 //

      // 락커 데이터 //
      isAddLocker: isClickedAddLocker,
      lockerId: isClickedAddLocker ? lockerInfo.lockerId : 0,
      lockerCardPrice: isClickedAddLocker ? lockerInfo.lockerCardPrice : 0,
      lockerCashPrice: isClickedAddLocker ? lockerInfo.lockerCashPrice : 0,
      lockerDiscountPrice: isClickedAddLocker ? lockerInfo.lockerDiscountPrice : 0,
      lockerStartDate: isClickedAddLocker ? lockerInfo.lockerStartDate.replaceAll('.', '-') + 'T00:00:00' : null,
      lockerEndDate: isClickedAddLocker ? lockerInfo.lockerEndDate.replaceAll('.', '-') + 'T23:59:59' : null
      // 락커 데이터 //
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('member/registration/all', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          setIsSuccess(true);
          setIsInProgress(false);
        }
        else if (responseData.status === 201) {
          alert("이미 존재하는 회원입니다.");
          setIsInProgress(false);
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용해주세요.");
          history.push('/');
          setIsInProgress(false);
        }
        else {
          alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
          setIsInProgress(false);
        }
      }
    )();

    setIsClicked(false);
  }

  function saveRegistData(merchantUid) {
    var name = MemberDefaultInfoRef.current.getName();
    var birthDay = MemberDefaultInfoRef.current.getBirth();
    var gender = MemberDefaultInfoRef.current.getGender();
    var phone = MemberDefaultInfoRef.current.getPhone();
    var id = MemberDefaultInfoRef.current.getId() === '' ? 'None-' + phone : MemberDefaultInfoRef.current.getId();
    var memberShipName = MemberShipInfoRef.current.getMemberShipName();
    var profile = MemberDefaultInfoRef.current.getProfile();

    var data = {
      storeId: window.localStorage.getItem('StoreId'),
      birthDay: birthDay,
      linkCoachId: id,
      name: name,
      phone: phone,
      gender: gender,
      profile: profile,
      memberDetail: MemberDefaultInfoRef.current.getMemberDetail(),
      approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
      memberShipId: MemberShipInfoRef.current.getMemberShipId(),
      memberShipName: memberShipName,
      courseTakeCount: 0,
      totalMax: MemberShipInfoRef.current.getTotalMax(),
      merchantUid: merchantUid,
      startDate: MemberShipInfoRef.current.getStartDate().replaceAll('.', '-') + 'T00:00:00',
      endDate: MemberShipInfoRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59',
      cashPrice: MemberShipInfoRef.current.getCashPrice(),
      cardPrice: MemberShipInfoRef.current.getCardPrice(),
      notReceivePrice: MemberShipInfoRef.current.getNotReceivePrice(),
      approvalDate: MemberShipInfoRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss')
    }

    window.sessionStorage.setItem("RegistData", JSON.stringify(data));
    window.sessionStorage.setItem("UsageData", 'None');
  }

  function onClickCloseCustomAlert() {
    setIsSuccess(false);
    history.push('/main');
  }

  function getApprovalDate() {
    return moment(MemberShipInfoRef.current.getApprovalDate()).format('YYYY-MM-DDTHH:mm:ss');
  }

  // 링크페이 결제요청 완료시 //
  function onCompleteExpectedLinkpay() {
    setIsSuccess(true);
    setIsClicked(false);
  }

  return (
    <TopLevelWrapper>
      { isSuccess && <CustomAlert text="등록이 완료되었습니다" closeFn={onClickCloseCustomAlert}/> }
      {
        isClicked &&
        <MemberRegistAllPaymentModal
          isUsePaymentSystem={isUsePaymentSystem}
          name={productName}
          coach={productCoach}
          validity={productValidity}
          cashPrice={productCash}
          price={productPrice}
          discount={productDiscountPrice}
          notReceived={productNotReceivedPrice}
          merchantUid={productMerchantUid}
          totalRegistData={totalRegistData}
          onRegist={cancelFunction}
          cancel={() => setIsClicked(false)}
          onCompleteExpectedLinkpay={onCompleteExpectedLinkpay}
          saveRegistData={saveRegistData} 
          getApprovalDate={getApprovalDate}/>
      }
      <RegistrationTopView registerClick={onRegist} />
      <MemberDefaultInfoView 
        ref={MemberDefaultInfoRef} 
        isFindUserId={isFindUserId}
        setIsFindUserId={setIsFindUserId}
        isAgreementEmphasize={isAgreementEmphasize}
        setIsAgreementEmphasize={setIsAgreementEmphasize}/>
      <MemberShipInfoView
        ref={MemberShipInfoRef}
        terms={terms}
        isFindUserId={isFindUserId}
        registerClick={onRegist} 
        isTermsEmphasize={isTermsEmphasize}
        setIsTermsEmphasize={setIsTermsEmphasize}
        isClickedAddLocker={isClickedAddLocker}
        setIsClickedAddLocker={setIsClickedAddLocker}/>
      <AddLockerView
        ref={LockerInfoRef}
        isClickedAddLocker={isClickedAddLocker}
        setIsClickedAddLocker={setIsClickedAddLocker}/>
      <RegistButtonWrapper>
        <RegisterButton onClick={onRegist}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>회원 등록하기</Text>
        </RegisterButton>
      </RegistButtonWrapper>
    </TopLevelWrapper>
  )
}

export default Registration;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 42px;
  
  transition: all 0.3s ease-in-out;
`;

const RegistButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  width: 1020px;
`;

const RegisterButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: 60px;
  
  width: 119px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.cursor};
  }
`;