import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

export default function LockerDivisionInput(props) {
	const { value, setValue } = props;
  const inputRef = useRef(null);

	// ✅ 입력값 변경 시, 너비 자동 조정
	useEffect(() => {
    if (inputRef.current) {
      const length = value.length > 0 ? value.length : 1;
      const newWidth = Math.min(300, Math.max(40, length * 12)); // ✅ 최소 40px, 최대 300px
      inputRef.current.style.width = `${newWidth}px`;
    }
	}, [value]);

	function handleChange(e) {
    setValue(e.target.value);
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={inputRef}
        value={value}
        onChange={handleChange}
      />
    </TopLevelWrapper>
  ) 
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 5px;
`;

const Input = styled.input`
  text-align: center; // ✅ 텍스트 가운데 정렬
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
	padding: 5px 12px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
	color: #CBCBCB;

  min-width: 40px; // ✅ 최소 너비
  max-width: 300px; // ✅ 최대 너비
  width: auto; // ✅ 동적 크기 적용 (JS에서 설정됨)

  // transition: width 0.3s ease-in-out; // ✅ 부드러운 크기 변경 효과

	::placeholder {
		color: #CBCBCB;
	}
`;
