import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import MemberList from './MemberList';

import LeaveMemberImagePath from '../../../image/LeaveMember.png';
import ReRegistrationImagePath from '../../../image/ReRegistration.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import moment from 'moment';

const MemberMovementHistoryBox = (props) => {
  const {
    salesData,
    startDate,
    endDate,
    calcDateText,
    allMembers,
    selectedPartnerGym,
    isOpenPartnerGymSales,
    isLoadingAllMember,
    isReregistLoading,
    setIsReregistLoading,
    isExpiredLoading,
    setIsExpiredLoading
  } = props;

  const [reRegistMembers, setReRegistMembers] = useState([]);
  const [aliveMembers, setAliveMembers] = useState([]);
  const [leaveMembers, setLeaveMembers] = useState([]);
  const [reregistPrice, setReregistPrice] = useState(0);

  // 이탈 회원, 재등록 회원 구분 //
  useEffect(() => {
    if (isLoadingAllMember) return;

    let newReregistMembers = [];
    let newAliveMembers = [];
    let newLeaveMembers = [];

    for (let i = 0; i < allMembers.length; i++) {
      let isReregist = false;
      let isAlive = false;
      let isLeave = false;

      // for (let j = 0; j < allMembers[i].usages.length; j++) {
      //   if ((startDate.isBefore(allMembers[i].usages[j].startDate) || startDate.isSame(allMembers[i].usages[j].startDate))
      //     && (endDate.isAfter(allMembers[i].usages[j].startDate) || endDate.isSame(allMembers[i].usages[j].startDate))
      //     && allMembers[i].usages.length > 1) {
      //       allMembers[i].startDate = allMembers[i].usages[j].startDate;
      //       isReregist = true;
      //     }
      //     // else isReregist = false;

      //   if (endDate.isBefore(allMembers[i].usages[j].endDate)) isAlive = true;
      //   // else isAlive = false;

      //   if (startDate.isBefore(allMembers[i].usages[j].endDate) && endDate.isAfter(allMembers[i].usages[j].endDate)) {
      //     allMembers[i].endDate = allMembers[i].usages[j].endDate;
      //     isLeave = true;
      //   }
      //   // else isLeave = false;
      // }
      for (let j = 0; j < allMembers[i].usages.length; j++) {
        if ((startDate.isBefore(allMembers[i].usages[j].startDate) || startDate.isSame(allMembers[i].usages[j].startDate))
          && (endDate.isAfter(allMembers[i].usages[j].startDate) || endDate.isSame(allMembers[i].usages[j].startDate))
          && allMembers[i].usages.length > 1) {
          allMembers[i].startDate = allMembers[i].usages[j].startDate;
          isReregist = true;
          break;
        }
      }

      for (let j = 0; j < allMembers[i].usages.length; j++) {
        if (endDate.isBefore(allMembers[i].usages[j].endDate)) {
          isAlive = true;
          break;
        }
      }

      for (let j = 0; j < allMembers[i].usages.length; j++) {
        if (j === allMembers[i].usages.length - 1
          && startDate.isBefore(allMembers[i].usages[j].endDate)
          && endDate.isAfter(allMembers[i].usages[j].endDate)) {
          allMembers[i].endDate = allMembers[i].usages[j].endDate;
          isLeave = true;
          break;
        }
      }

      if (isReregist) newReregistMembers.push(allMembers[i]);
      if (isAlive) newAliveMembers.push(allMembers[i]);
      if (isLeave) newLeaveMembers.push(allMembers[i]);
    }

    newReregistMembers.sort(function (a, b) {
      let x = a.startDate;
      let y = b.startDate;

      if (x < y) return 1;
      if (x > y) return -1;
    })

    newLeaveMembers.sort(function (a, b) {
      let x = a.endDate;
      let y = b.endDate;

      if (x < y) return 1;
      if (x > y) return -1;
    })

    setReRegistMembers(newReregistMembers);
    setAliveMembers(newAliveMembers);
    setLeaveMembers(newLeaveMembers);

    setTimeout(() => {
      setIsReregistLoading(false);
      setIsExpiredLoading(false);
    }, 1000)
  }, [startDate, endDate, allMembers, isLoadingAllMember])

  useEffect(() => {
    if (salesData.length === 0 || allMembers.length === 0) return;

    let reRegistMembers = [];
    let newReregistPrice = 0;

    for (let i = 0; i < allMembers.length; i++) {
      for (let j = 0; j < allMembers[i].usages.length; j++) {
        if ((startDate.isBefore(allMembers[i].usages[j].approvalDate) || startDate.isSame(allMembers[i].usages[j].approvalDate))
          && (endDate.isAfter(allMembers[i].usages[j].approvalDate) || endDate.isSame(allMembers[i].usages[j].approvalDate))
          && allMembers[i].usages.length > 1) {
          reRegistMembers.push(allMembers[i]);
          break;
        }
      }
    }

    for (let i = 0; i < salesData.length; i++) {
      let isExist = false;

      for (let j = 0; j < reRegistMembers.length; j++) {
        if (salesData[i].memberId === reRegistMembers[j].memberId) {
          isExist = true;
          // console.log(salesData[i].itemName + " " + salesData[i].cardPrice + salesData[i].cashPrice + " " + reRegistMembers[j].name);
          // newReregistPrice += (salesData[i].cardPrice + salesData[i].cashPrice);
          break;
        }
      }

      if (isExist) {
        newReregistPrice += (salesData[i].cardPrice + salesData[i].cashPrice);
      }

    }

    setReregistPrice(newReregistPrice);
  }, [salesData, allMembers])

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>{calcDateText() + ' 이탈 회원'}</Title>
        {
          isExpiredLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <TopUIWrapper>
                      <LeaveIcon src={LeaveMemberImagePath} />
                      <LeavePersent>
                        <PersentText>회원 이탈율</PersentText>
                        <PersentNumber>{Math.round(leaveMembers.length / (aliveMembers.length) * 100)}%</PersentNumber>
                      </LeavePersent>
                      <DivisionLine />
                      <ResultBox>
                        <ResultText style={{ marginRight: `3px` }}>총</ResultText>
                        <ResultNumber style={{ marginRight: `3px` }}>{leaveMembers.length}명</ResultNumber>
                        <ResultText>이탈</ResultText>
                      </ResultBox>
                    </TopUIWrapper>
                    <ListTitleWrapper>
                      <ListTitle>이탈 회원 목록</ListTitle>
                    </ListTitleWrapper>
                    <MemberList column='이탈 날짜' data={leaveMembers} selectedPartnerGym={selectedPartnerGym}/>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>

      <BoxDivisionLine />

      <InfoBox>
        <Title>{calcDateText() + ' 신규등록 회원'}</Title>
        {
          isReregistLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <TopUIWrapper>
                      <LeaveIcon src={ReRegistrationImagePath} />
                      <ReRegistrationPersent>
                        <PersentText>회원 재등록율</PersentText>
                        <PersentNumber>{Math.round((reRegistMembers.length / aliveMembers.length) * 100)}%</PersentNumber>
                      </ReRegistrationPersent>
                      <DivisionLine />
                      <ResultBox>
                        <ResultText style={{ marginRight: `3px` }}>총</ResultText>
                        <ResultNumber style={{ marginRight: `3px` }}>{reRegistMembers.length}명</ResultNumber>
                        <ResultText>재등록</ResultText>
                      </ResultBox>
                    </TopUIWrapper>
                    <ListTitleWrapper>
                      <ListTitle>재등록 회원 목록</ListTitle>
                      <PriceTextWrapper>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BABABA' cursor='default'>재등록 회원 매출</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' cursor='default'>{reregistPrice.toLocaleString('ko-KR')}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BABABA' cursor='default'>원</Text>
                      </PriceTextWrapper>
                    </ListTitleWrapper>
                    <MemberList column='등록 날짜' data={reRegistMembers} selectedPartnerGym={selectedPartnerGym}/>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default MemberMovementHistoryBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 448px;

  gap: 28px;
  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const BoxDivisionLine = styled.div`
  diplay: flex;
  flex-direction: row;

  width: 1px;
  height: 392px;

  background-color: #ECECEC;
  // border: 1px solid #ECECEC;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 44.5%;
  // height: 77.67%;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // width: 454px;
  // width: 44.5%;
  height: 364px;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  // height: 100%;
  height: 364px;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TopUIWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

const LeaveIcon = styled.img`
  width: 48px;
  height: 48px;

  margin-right: 8px;
`;

const LeavePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  height: 28px;

  border-radius: 8px;
  background-color: #FF8B48;
`;

const ReRegistrationPersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  height: 28px;

  border-radius: 8px;
  background-color: #33C8FF;
`;

const PersentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 3px 0 0;
`;

const PersentNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  width: 120px;
  height: 1px;

  margin: 0 16px 0 16px;
  border: 1px solid #ECECEC;
`;

const ResultBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 113px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const ResultText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ResultNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ListTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const ListTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  // margin: 24px 0 0 0;
  margin: 0 0 0 0;
`;

const PriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  gap: 4px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;