import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

// Function //
import { makeUpWorkout } from "../../Exercise/Transaction/Transaction.js";
import FetchModule from "../../Model/Share/Network/FetchModule";
// Function //

// Component //
import CalendarBoxButton from "../Component/CalendarBoxButton";
import WorkoutSelectBox from "../Component/WorkoutSelectBox";
import MainPageWodViewBox from "../Model/WodViewBox.js";
import MainPageRankingView from "../Model/MainPageRankingView.js";
// Component //

// Image //
import LeftArrow from '../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../image/Right.png'//우측 화살표
import WhiteCrownImage from '../../image/WhiteCrown.png';
import EmptySign from '../../image/EmptySign.png';
import MoniterIcon from '../../image/MoniterIcon.png';
import HoverMoniterIcon from '../../image/GrayHoverMoniterIcon.png';
// Image //

export default function BoardPage() {

  const [date, setDate] = useState(moment());
  const [wods, setWods] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedWod, setSelectedWod] = useState(undefined);
  const [isClickedRanking, setIsClickedRanking] = useState(false);

  const [isHoverBlackboardButton, setIsHoverBlackboardButton] = useState(false);

  const [nowSection, setNowSection] = useState(0);
  const [nowScaleSection, setNowScaleSection] = useState(undefined);

  // 금일 와드 정보 불러오기 //
  useEffect(() => {
    let requestData = {
      date: date.format('YYYY-MM-DDT00:00:00')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('workout/daily_wod', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          if (responseData.data.workouts.length > 0) {
            let newWorkouts = makeUpWorkout(responseData.data.workouts.slice());
            let newSelectOptions = [];

            for (let i = 0; i < newWorkouts.length; i++) {
              let selectOption = {
                workoutId: newWorkouts[i].workoutId,
                title: newWorkouts[i].title,
                lessonName: newWorkouts[i].lessonName,
                color: newWorkouts[i].lessonColor
              }

              newSelectOptions.push(selectOption);

              setWods(newWorkouts);
              setSelectOptions(newSelectOptions);
            }
          }
          else {
            setWods([]);
            setSelectOptions([]);
          }
        }
      }
    )();
  }, [date])

  // 선택된 정보에 맞게 와드 데이터 세팅 //
  useEffect(() => {
    if (wods.length === 0 || selectedOption === undefined) {
      setSelectedWod(undefined);
      return;
    }

    let wodIndex = wods.findIndex(obj => obj.workoutId === selectedOption.workoutId);

    setSelectedWod(wods[wodIndex]);
  }, [wods, selectedOption])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // Section Title 설정
  function decideSectionTitle(data) {
    if (data === undefined) return '';
    else if (data.tag === 'ForTime') return data.round + ' Round For Time';
    else if (data.tag === 'AMRAP') {
      let min = parseInt(data.exerciseTime / 60);
      let sec = parseInt(data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec AMRAP';
      else if (min > 0) return min + 'min AMRAP';
      else if (sec > 0) return sec + 'sec AMRAP';
      else return 'AMRAP';
    }
    else if (data.tag === 'EMOM') {
      let min = parseInt(data.round * data.exerciseTime / 60);
      let sec = parseInt(data.round * data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec EMOM';
      else if (min > 0) return min + ' min EMOM';
      else if (sec > 0) return sec + ' sec EMOM';
      else return 'EMOM';
    }
    else if (data.tag === 'TABATA') {
      let onMin = parseInt(data.exerciseTime / 60);
      let onSec = parseInt(data.exerciseTime % 60);
      let offMin = parseInt(data.restTime / 60);
      let offSec = parseInt(data.restTime % 60);

      return 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off:' + offMin + ':' + numberPad(offSec, 2) + ' ' + data.round + 'R TABATA';
    }
    else if (data.tag === 'SINGLE') {
      if (data.mainExerciseList.length <= 0) return 'SINGLE';
      else if (data.mainExerciseList.length === 1) return data.mainExerciseList[0].movementName;
      else return 'Multiple Single';
    }
    else if (data.tag === 'Custom') {
      if (data.customTitle !== '') return data.customTitle;
      else if (data.scoreKeyword === '점수 없음'
        || data.scoreKeyword === '피크와트'
        || data.scoreKeyword === '최고 속도') return 'Custom : ' + data.scoreKeyword;
      else return 'Custom : ' + data.scoreKeyword + ' ' + data.scorePriority;
    }
  }

  // 보여줄 섹션 결정 //
  function decideViewSection() {
    if (nowScaleSection === undefined) return selectedWod.sectionList[nowSection];
    else return selectedWod.sectionList[nowSection].scaleSections[nowScaleSection];
  }

  // 칠판모드 버튼 호버링 시작 //
  function onMouseOverBlackboardButton() {
    setIsHoverBlackboardButton(true);
  }

  // 칠판모드 버튼 호버링 끝 //
  function onMouseLeaveBlackboardButton() {
    setIsHoverBlackboardButton(false);
  }

  // 메인 세션 클릭시 //
  function onClickNowSection(index) {
    setNowSection(index);
    setNowScaleSection(undefined);
  }

  // 스케일 세션 클릭시 //
  function onClickScaleSectionButton(index) {
    setNowScaleSection(index);
  }

  // 순위표 보기 클릭시 //
  function onClickRanking() {
    setIsClickedRanking(!isClickedRanking);
  }

  // 링크 클릭시 //
  function onClickURL(url) {
    window.open(url, "_blank", "noreferrer");
  }

  // 날짜 이전 버튼 클릭시 //
  function onClickPrevDate() {
    setDate(date.clone().subtract(1, 'days'));
  }

  // 날짜 다음 버튼 클릭시 //
  function onClickNextDate() {
    setDate(date.clone().add(1, 'days'));
  }

  // 칠판 모드 클릭시 //
  function onClickBlackboardModeButton() {
    // window.open('https://link-zone.io/full/board/' + window.localStorage.getItem('Token'), '', '_self',)
    let token = window.localStorage.getItem('Token').replaceAll('Bearer ', '');

    window.open('https://link-zone.io/full/board/' + token, '', '_self',);
    // window.open('http://localhost:3000/full/board/' + token, '', '_self',);
  }

  return (
    <Container>
      <TitleWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>보드</Text>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>수업중 전자 칠판으로 이용하실수 있습니다</Text>
      </TitleWrapper>
      <TopLineWrapper>
        <TopLineButtonLeftWrapper>
          <TopLineButtonWrapper>
            <DateButtonWrapper>
              <ArrowWrapper onClick={onClickPrevDate} >
                <ArrowImage src={LeftArrow} />
              </ArrowWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>{date.clone().format('YYYY년 MM월 DD일')}</Text>
              <ArrowWrapper onClick={onClickNextDate}>
                <ArrowImage src={RightArrow} />
              </ArrowWrapper>
            </DateButtonWrapper>
            <CalendarBoxButton
              width={38}
              height={38}
              value={date}
              setValue={setDate} />
          </TopLineButtonWrapper>
          <DivisionLine width='2px' height='22px' radius={2} backgroundColor='#D9D9D9' />
          <SelectBoxWrapper>
            <WorkoutSelectBox options={selectOptions} value={selectedOption} setValue={setSelectedOption} />
          </SelectBoxWrapper>
        </TopLineButtonLeftWrapper>
        <BlackBoardButton
          onMouseOver={onMouseOverBlackboardButton}
          onMouseLeave={onMouseLeaveBlackboardButton}
          onClick={onClickBlackboardModeButton}>
          <MoniterImageWrapper width={20} height={20}>
            <Image style={isHoverBlackboardButton ? { position: 'absolute', opacity: '0' } : { position: 'absolute', opacity: '1' }} width={20} height={20} src={MoniterIcon} />
            <Image style={isHoverBlackboardButton ? { position: 'absolute', opacity: '1' } : { position: 'absolute', opacity: '0' }} width={20} height={20} src={HoverMoniterIcon} />
          </MoniterImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={isHoverBlackboardButton ? '#777777' : '#C0C0C0'} cursor='pointer'>칠판 모드</Text>
        </BlackBoardButton>
      </TopLineWrapper>

      <DivisionLine style={{ margin: '16px 0px 28px 0px' }} width='100%' height='2px' radius={2} backgroundColor='#D9D9D9' />
      <InfoWrapper>
        <InfoInnerWrapper>
          {
            selectedWod === undefined ?
              <TotalWodBox>
                <NoneWodBox>
                  <Image width={148} height={62} src={EmptySign} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#CBCBCB' cursor='default'>프로그램이 존재하지 않습니다</Text>
                </NoneWodBox>
              </TotalWodBox>
              :
              <TotalWodBox>
                <WodBoxWrapper isClickedRanking={isClickedRanking}>
                  <TotalWodSectionButtonWrapper>
                    <WodSectionButtonWrapper>
                      {
                        selectedWod.sectionList.map((data, index) => (
                          <WodSectionButton key={'MainBoardSection-' + index} isClicked={nowSection === index} onClick={() => onClickNowSection(index)}>
                            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65)}</Text>
                          </WodSectionButton>
                        ))
                      }
                    </WodSectionButtonWrapper>
                    <RankingButton isClickedRanking={isClickedRanking} onClick={onClickRanking}>
                      <Image width={18} height={20} src={WhiteCrownImage} />
                    </RankingButton>
                  </TotalWodSectionButtonWrapper>
                  <ScaleSectionBoxWrapper>
                    <WodSectionButtonWrapper>
                      {
                        selectedWod.sectionList[nowSection].scaleSections.map((data, index) => (
                          <ScaleWodSectionButton isClicked={nowScaleSection === index} onClick={() => onClickScaleSectionButton(index)}>
                            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + '-' + (index + 2)}</Text>
                          </ScaleWodSectionButton>
                        ))
                      }
                    </WodSectionButtonWrapper>
                  </ScaleSectionBoxWrapper>
                  <SectionTitleWrapper>
                    <SectionBox isClickedScale={nowScaleSection !== undefined}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{nowScaleSection === undefined ? String.fromCharCode(nowSection + 65) : String.fromCharCode(nowSection + 65) + '-' + (nowScaleSection + 2)}</Text>
                    </SectionBox>
                    <SectionTitleBox isClickedScale={nowScaleSection !== undefined}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='pointer'>{decideSectionTitle(decideViewSection())}</Text>
                    </SectionTitleBox>
                  </SectionTitleWrapper>
                  <MainPageWodViewBox
                    sectionData={decideViewSection()}
                    onClickURL={onClickURL}
                    isClickedRanking={isClickedRanking}
                    isMainPage={false} />
                </WodBoxWrapper>
                <RankingBoxWrapper isClickedRanking={isClickedRanking}>
                  <MainPageRankingView
                    nowSection={nowSection}
                    setNowSection={setNowSection}

                    selectedWod={selectedWod}
                    isClickedRanking={isClickedRanking} 
                    onClickGoBack={onClickRanking}/>
                </RankingBoxWrapper>
              </TotalWodBox>
          }
        </InfoInnerWrapper>
      </InfoWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  padding-bottom: 180px;
  margin-left: 14px;

  // width: 100%;
  width: 1060px;
  min-height: 660px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const TopLineButtonLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 20px;
`;

const TopLineButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

const BlackBoardButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 36px;

  border: 1px solid #E9E9E9;
  border-radius: 10px;
  background-color: #FFFFFF;

  gap: 10px;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
  }

  transition: all 0.3s ease-in-out;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 187px;
  height: 36px;

  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 10px;
  background-color: #FFFFFF;

`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;

  // background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 220px;
  height: 38px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 94.59%;
`;

const TotalWodBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const WodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? '55%' : '100%'};
  
  transition: all 0.3s ease-in-out;
`;

const RankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? '43%' : '0%'};

  transition: all 0.3s ease-in-out;
`;

const TotalWodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  padding: 20px 0px 6px 0px;

  width: 100%;
`;

const WodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-item: center;

  gap: 8px;
`;

const ScaleSectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;
  
  width: 100%;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedScale ? `45px` : `34px`};
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClickedScale ? `#4D92EF` : `#6DD49E`};

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 15px;

  width: ${props => props.isClickedScale ? `calc(100% - 81px)` : `calc(100% - 70px)`};
  height: 34px;

  border-radius: 6px;
  background-color: #F9F9F9;

  transition: all 0.3s ease-in-out;
`;

const WodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleWodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 43px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#4D92EF' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 63px;
  height: 32px;

  border-radius: 5px;
  background-color: ${props => props.isClickedRanking ? `#FF8B48` : `#FFB800`};

  :hover {
    cursor: pointer;
  }
`;

const NoneWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 60px 0px;

  width: 100%;

  gap: 8px;
`;

const MoniterImageWrapper = styled.div`
  position: relative;  
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
   
  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;