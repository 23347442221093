import { React, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';
import ClassInfoBox from '../../../Component/MainComponent/ClassInfoBox.js';

import FetchModule from '../../Share/Network/FetchModule.js';

import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import MainCalenderBox from '../../../Component/MainComponent/MainCalenderBox.js';

export default function TodayClass(props) {
  const { today, setToday } = props;

  const history = useHistory();

  // const [today, setToday] = useState(moment());
  const [scheduleData, setScheduleData] = useState([]);
  const [breakTimeData, setBreakTimeData] = useState([]);
  const [useScheduleData, setUseScheduleData] = useState([]);
  const [reserveData, setReserveData] = useState([]);
  const [reserveRendering, setReserveRendering] = useState(false);
  const [allReserveLock, setAllReserveLock] = useState(false);

  const [calenderHeight, setCalenderHeight] = useState(0);
  const [isClickedDate, setIsClickedDate] = useState(false);

  const [isClickedReserve, setIsClickedReserve] = useState(false);

  const DateButtonRef = useRef();

  // Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getData('schedule/', 'GET');
        const responseData = await fetchModule.postData('schedule/', 'POST');
        // console.log(responseData.data);
        if (responseData.status === 200) setScheduleData(responseData.data);
        // else if (responseData.status === 401) history.push('/');
        // else if (responseData.status !== 204) alert("회원권 정보를 가져오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }, [history])

  // BreakTime Data API호출
  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getData('breaktime/', 'GET');

    //     if (responseData.status === 200) setBreakTimeData(responseData.data);
    //     // else if (responseData.status === 401) history.push('/');
    //   }
    // )();

    let requestData = {
      classDate: today.clone().format('YYYY-MM-DDT00:00:00')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('breaktime/', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) setBreakTimeData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, today])

  // Schedule Data를 사용 가능한 Data로 가공
  useLayoutEffect(() => {
    let tmp = scheduleData.slice();
    let newScheduleData = [];

    for (let i = 0; i < tmp.length; i++) {

      let endPoint = moment(tmp[i].classDate.split('T')[0]);

      // while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
      while (endPoint.isBefore(moment(tmp[i].endDate))) {
        // console.log("endPoint : " + endPoint.format('YYYY-MM-DD'));
        // console.log("today : " + date.format("YYYY-MM-DD"));
        // console.log(endPoint.diff(date, 'days') >= 30);

        if (endPoint.diff(today, 'days') >= 30) break;

        if (tmp[i].repeatWeek.includes(getWeekNumber(endPoint.format('YYYY-MM-DD')).toString()) || tmp[i].repeatWeek.includes('0')) {
          if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
            // var colSpan = 1;
            // var rowSpan = 1;

            // // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
            // colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

            let newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              profile: tmp[i].profile,
              applyWeek: tmp[i].applyWeek,
              // rowSpan: rowSpan,
              // colSpan: colSpan,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction
            }
            newScheduleData.push(newData);
          }
          // endPoint = endPoint.clone().add(7 * tmp[i].term, 'day');
        }
        endPoint = endPoint.clone().add(1, 'day');
      }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    let newData = [];

    for (let i = 0; i < newScheduleData.length; i++) {

      if (newScheduleData[i].classDate === today.clone().format('YYYY.MM.DD')) {
        newData.push(newScheduleData[i]);
      }
    }

    // breaktime 데이터 가공
    for (let z = 0; z < breakTimeData.length; z++) {
      if (moment(breakTimeData[z].classDate).isBefore(moment(today.clone().format('YYYY-MM-DDT') + breakTimeData[z].startTime)) && moment(breakTimeData[z].endDate).isAfter(today.clone())) {
        if (breakTimeData[z].repeatWeek.includes(getWeekNumber(today.clone().format('YYYY-MM-DD'))) || breakTimeData[z].repeatWeek === '0') {
          if (breakTimeData[z].applyWeek.includes(today.clone().format('ddd')) || breakTimeData[z].applyWeek === 'All') {

            var breakData = {
              classId: -1,
              lessonId: -1,
              classDate: today.clone().format('YYYY.MM.DD'),
              color: '#ECECEC',
              classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
              className: '브레이크 타임',
              // className: 'Open Gym',
              applyWeek: breakTimeData[z].applyWeek
            }

            newData.push(breakData);
          }
        }
      }
    }

    newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (let i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      var splitTmp = tmp[0].split(':');
      var Time = '';

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      // tmp = newScheduleData[i].endTime.split('T');
      // splitTmp = tmp[1].split(':');
      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
      }

      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      // if (i === 0) {
      //   newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
      //   newData[i] = Object.assign(newData[i], { viewTime: Time })
      // }
      // if (i !== 0 && newData[i - 1].startTime === newData[i].startTime) {
      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }

    let newReserve = [];

    for (let h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }

    // for (let i = 0; i < finalStartTime.length; i++) {
    //   let newDataList = [];

    //   for (let j = 0; j < newData.length; j++) {

    //     // 시작날짜가 같을경우 //
    //     if (finalStartTime[i].startTime === newData[j].startTime) {
    //       // 중복검사 //
    //       let isExist = false;

    //       for (let h = 0; h < newTotalData.length; h++) {
    //         let isInnerExist = false;

    //         for (let z = 0; z < newTotalData[h].length; z++) {
    //           if (newTotalData[h][z].classDate === newData[j].classDate
    //             && newTotalData[h][z].classTime === newData[j].classTime
    //             && newTotalData[h][z].className === newData[j].className) {
    //               isExist = true;
    //               isInnerExist = true;
    //               break;
    //             }
    //         }

    //         if (isInnerExist) break;
    //       }

    //       if (!isExist) {
    //         newDataList.push(newData[j]);
    //       }
    //     }
    //     // 끝나는 시간이 같은 경우 //
    //     else if (finalStartTime[i].endTime === newData[j].endTime) {

    //       let isExist = false;

    //       for (let h = 0; h < newDataList.length; h++) {
    //         if (newDataList[h].classDate === newData[j].classDate
    //           && newDataList[h].classTime === newData[j].classTime
    //           && newDataList[h].className === newData[j].className) {
    //             isExist = true;
    //             break;
    //         }

    //       }

    //       if (!isExist) {
    //         let totalExist = false;

    //         for (let h = 0; h < newTotalData.length; h++) {
    //           let isInnerExist = false;
    //           for (let z = 0; z < newTotalData[h].length; z++) {
    //             if (newTotalData[h][z].classDate === newData[j].classDate
    //               && newTotalData[h][z].classTime === newData[j].classTime
    //               && newTotalData[h][z].className === newData[j].className) {
    //                 totalExist = true;
    //                 isInnerExist = true;
    //                 break;
    //               }
    //           }

    //           if (isInnerExist) break;
    //         }

    //         if (!totalExist) {
    //           newDataList.push(newData[j]);
    //         }
    //       }
    //     }
    //   }

    //   if (newDataList.length !== 0) newTotalData.push(newDataList);
    // }

    let newTotalData = [];
    let newDataList = [];

    for (let i = 0; i < newData.length; i++) {
        // 중복검사 //
        let isExist = false;

        for (let j = 0; j < newTotalData.length; j++) {
          let isInnerExist = false;

          for (let z = 0; z < newTotalData[j].length; z++) {
            if (newTotalData[j][z].classDate === newData[i].classDate
              && newTotalData[j][z].classTime === newData[i].classTime
              && newTotalData[j][z].className === newData[i].className) {
              isExist = true;
              isInnerExist = true;
              break;
            }
          }

          if (isInnerExist) break;
        }

        if (!isExist) {
          newDataList.push(newData[i]);
        }
      }
      // // 끝나는 시간이 같은 경우 //
      // else if (finalStartTime[i].endTime === newData[j].endTime) {

      //   let isExist = false;

      //   for (let h = 0; h < newDataList.length; h++) {
      //     if (newDataList[h].classDate === newData[j].classDate
      //       && newDataList[h].classTime === newData[j].classTime
      //       && newDataList[h].className === newData[j].className) {
      //       isExist = true;
      //       break;
      //     }

      //   }

      //   if (!isExist) {
      //     let totalExist = false;

      //     for (let h = 0; h < newTotalData.length; h++) {
      //       let isInnerExist = false;
      //       for (let z = 0; z < newTotalData[h].length; z++) {
      //         if (newTotalData[h][z].classDate === newData[j].classDate
      //           && newTotalData[h][z].classTime === newData[j].classTime
      //           && newTotalData[h][z].className === newData[j].className) {
      //           totalExist = true;
      //           isInnerExist = true;
      //           break;
      //         }
      //       }

      //       if (isInnerExist) break;
      //     }

      //     if (!totalExist) {
      //       newDataList.push(newData[j]);
      //     }
      //   }
      // }

    //   if (newDataList.length !== 0) newTotalData.push(newDataList);
    // }
    
    if (newDataList.length !== 0) newTotalData.push(newDataList);
    // console.log(newTotalData);


    setReserveData(newReserve);
    setUseScheduleData(newData);

    // let schedules = newData.slice();
    // let scheduleIds = [];

    // for (let i = 0; i < schedules.length; i++) {
    //   let data = {
    //     scheduleId: schedules[i].scheduleId,
    //     count: 0
    //   }

    //   scheduleIds.push(data);
    // }

    // setReserveData(scheduleIds);

  }, [scheduleData, breakTimeData, today])

  // 날짜 클릭시 달력 생성 //
  useEffect(() => {
    if (isClickedDate) setCalenderHeight(654);
    else setCalenderHeight(0);
  }, [isClickedDate])

  // ReservationData
  useLayoutEffect(() => {
    let schedules = useScheduleData.slice();
    let scheduleIds = [];

    for (let i = 0; i < schedules.length; i++) {
      scheduleIds.push(schedules[i].scheduleId === undefined ? 0 : schedules[i].scheduleId);
    }

    // 조회할 데이터가 있을경우 //
    if (scheduleIds.length > 0) {
      let requestData = {
        scheduleIds: scheduleIds,
        classDate: schedules[0].classDate.replaceAll('.', '-') + 'T00:00:00'
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/status/check', 'POST', requestData);
          // console.log(responseData.data.isAllLock);
          if (responseData.status === 200) {
            setAllReserveLock(!responseData.data.isAllLock);
            setReserveData(responseData.data.status);
          }
          // else if (responseData.status === 201) setAllReserveLock(true);
          // else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 조회할 데이터가 없을경우 //
    else setAllReserveLock(false);
  }, [useScheduleData, reserveRendering])


  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  // 다음 날짜 버튼 클릭시 //
  function onClickNextDate() {
    // if (moment(today.clone().add(1, 'days').format('YYYY.MM.DD')).isAfter(moment(moment().format('YYYY.MM.DD')))) return;
    setToday(today.clone().add(1, 'days'));
  }

  // 이전 날짜 버튼 클릭시 //
  function onClickPrevDate() {
    setToday(today.clone().subtract(1, 'days'));
  }

  // 날짜 클릭시 달력 띄우기 //
  function onClickMainDate() {
    setIsClickedDate(!isClickedDate);
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <TitleTopLine>
          <Title>수업 일정</Title>
          <DateMoveWrapper>
            <DateMoveButton onClick={onClickPrevDate} img={CalenderLeftArrowImagePath} />
            <DateText ref={DateButtonRef} fontFamily='NotoSansKR-Bold' fontSize={14} color='#232323' cursor='pointer' onClick={onClickMainDate}>{today.clone().format('YYYY.MM.DD')}</DateText>
            <DateMoveButton onClick={onClickNextDate} img={CalenderRightArrowImagePath} />
          </DateMoveWrapper>
        </TitleTopLine>
        <ExplanationWrapper>
          <Explanation>{today.clone().format('YYYY.MM.DD')} 수업 일정을 확인하세요</Explanation>
          <Go onClick={() => history.push('/class')}>자세히 보기</Go>
        </ExplanationWrapper>
        <ViewWrapper>
          {
            useScheduleData.length === 0 ?
              <EmptyBoxWrapper>
                <EmptyBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>금일 예정된 수업이 없습니다</Text>
                </EmptyBox>
              </EmptyBoxWrapper>
              :
              <ViewBox>
                <TimeDivisionLineWrapper>
                  {
                    useScheduleData.map((data, index) => (
                      <TimeDivisionLine key={index} scheduleData={data} isMain={true} />
                    ))
                  }
                </TimeDivisionLineWrapper>
                <ClassInfoBoxWrapper>
                  {
                    useScheduleData.map((data, index) => (
                      <ClassInfoBox
                        key={index}
                        data={data}
                        // reserveData={reserveData[index]}
                        reserveData={reserveData}
                        reserveRendering={reserveRendering}
                        setReserveRendering={setReserveRendering} />
                    ))
                  }
                </ClassInfoBoxWrapper>
              </ViewBox>
          }
          <CalenderBox height={calenderHeight}>
            <MainCalenderBox value={today} setValue={setToday} setIsClicked={setIsClickedDate} DateButtonRef={DateButtonRef} />
          </CalenderBox>
        </ViewWrapper>
      </TitleWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 612px;
  // height: 592px;
  height: 767px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleTopLine = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;
  color: #232323;

  margin: 0 0 0 0;
`;

const DateMoveWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const DateMoveButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 314px;
  margin-top: 4px;
  margin-bottom: 24px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: 12px;

  color: #FF8B48;
  margin: 0 0 0 0;
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;

  overflow-y: scroll;

  // width: 556px;
  // height: 463px;

  width: 556px;
  // height: 461px;
  height: 630px;

  // gap: 20px;
  padding-top: 24px;
  
  border-top: 2px solid #ECECEC;
  // border-bottom: 2px solid #ECECEC;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CalenderBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 556px;
  // height: 630px;
  // height: 654px;
  height: ${props => props.height}px;

  overflow: hidden;
  
  border-top: 2px solid #ECECEC;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const EmptyBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding-top: 24px;
  
  width: 556px;
  // height: 461px;
  height: 630px;

  border-top: 2px solid #ECECEC;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const TimeDivisionLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClassInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;