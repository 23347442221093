import { React, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import LockerCountInput from '../../../Component/LockerComponent/LockerCountInput';
import LockerDivisionSelectBox from '../../../Component/LockerComponent/LockerDivisionSelectBox';

const LockerAddModal = forwardRef((props, ref) => {
  const { lockerDivisions, selectedAddLockerDivision, setSelectedAddLockerDivision, exitClick, saveClick } = props;

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
      return InputRef.current.getValue();
    }
	}));

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
        <Title>락커 추가</Title>
        <SelectBoxWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>락커 구분</Text>
          <LockerDivisionSelectBox 
            options={lockerDivisions} 
            defaultVal={selectedAddLockerDivision}
            setDefaultVal={setSelectedAddLockerDivision}/>
        </SelectBoxWrapper>
        <LockerCountInput ref={InputRef}/>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={saveClick}>저장하기</SaveButton>
        </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 266px;
  // height: 228px;
  height: 278px;

  border-radius: 10px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 16px;

	width: 210px;

  gap: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 36px;

  width: 100%;
  
  gap: 16px;
  // margin-left: 20px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #3E4148;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;