import { React, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import NewTitleInput from '../../../Component/NoticeComponent/NewTitleInput';
import NewTextArea from '../../../Component/NoticeComponent/NewTextArea';

import NoticePictureIcon from '../../../image/NoticePictureIcon.png';
import WhiteExitIcon from '../../../image/WhiteExitIcon.png';
import URLIcon from '../../../image/URLIcon.png';
// import KakaoIcon from '../../../image/KakaoTalkIcon.png';
import KakaoTalkSpeechBubbleIcon from '../../../image/KakaoTalkSpeechBubbleIcon.png';
import FetchModule from '../../Share/Network/FetchModule';
import NotRegistrationCardModal from '../../Share/Normal/NotRegistrationCardModal';

import BlackFixingPin from '../../../image/BlackFixingPin.png';
import WhiteFixingPin from '../../../image/WhiteFixingPin.png';
import CameraIcon from '../../../image/CameraIcon.png';
import WhitePencelIcon from '../../../image/WhitePencelIcon.png';
import ImageEditModal from './ImageEditModal';


const NewNoticeCreateBox = forwardRef((props, ref) => {

  const [value, setValue] = useState('');
  const [picture, setPicture] = useState(undefined);
  const [countData, setCountData] = useState({ isRegistrationCard: false, validCount: 0, invalidCount: 0 });
  const [storeLogo, setStoreLogo] = useState('');

  const [isClickedEditPhoto, setIsClickedEditPhoto] = useState(false);
  // const [imgBase64, setImgBase64] = useState();
  // const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  const [pictureIndex, setPictureIndex] = useState(0);
  const [pictures, setPictures] = useState([]);
  const [pictureWidth, setPictureWidth] = useState(0);
  const [pictureHeight, setPictureHeight] = useState(0);

  const [isClickedKakaoBiz, setIsClickedKakaoBiz] = useState(false);
  const [isClickedValid, setIsClickedValid] = useState(false);
  const [isClickedInvalid, setIsClickedInvalid] = useState(false);

  const [isViewRegistrationCard, setIsViewRegistrationCard] = useState(false);

  const [urls, setUrls] = useState([]);

  const [isNeedCheckURL, setIsNeedCheckURL] = useState(false);
  const [isClickedFixed, setIsClickedFixed] = useState(false);

  const TitleRef = useRef();
  const CanvasRef = useRef();
  const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();

  useImperativeHandle(ref, () => ({
    getTitle: () => {
      return TitleRef.current.getValue();
    },
    getValue: () => {
      let fianlURL = '';

      for (let i = 0; i < urls.length; i++) {
        fianlURL += (urls[i] + ' ');
      }

      return value + '\n' + fianlURL;
    },
    getPicture: () => {
      // return picture;
      let pictureList = [];

      for (let i = 0; i < pictures.length; i++) {

        // if (!pictures[i].edit.startsWith('https://')) {
        // }
        // else pictureList.push(pictures[i].original);
        pictureList.push(pictures[i].edit);
      }

      return pictureList;
    },
    getIsClickedKakaoBiz: () => {
      return isClickedKakaoBiz;
    },
    getIsClickedValid: () => {
      return isClickedValid;
    },
    getIsClickedInvalid: () => {
      return isClickedInvalid;
    },
    getIsFixed: () => {
      return isClickedFixed;
    }
  }))

  useEffect(() => {
    if (!isNeedCheckURL) return;

    let newContent = value;
    let newUrls = urls.slice();

    while (true) {
      let url = collectURL(newContent);

      if (url === '') break;
      else {
        newContent = newContent.replace(url, '');
        newUrls.push(url.replaceAll(',', ''));
      }
    }

    setUrls(newUrls);
    setValue(newContent);
    setIsNeedCheckURL(false);
  }, [value, urls, isNeedCheckURL])

  // 회원수 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/count', 'GET');

        if (responseData.status === 200) {
          setStoreLogo(responseData.data.storeLogo);
          setCountData(responseData.data);
        }
      }
    )();
  }, [])

  const handleClick = e => {
    hiddenFileInput.current.click();
  };

  // const handleChange = e => {

  //   var pathpoint = e.target.value.lastIndexOf('.');
  //   var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
  //   var filetype = filepoint.toLowerCase();

  //   if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

  //     // 정상적인 이미지 확장자 파일인 경우
  //     const reader = new FileReader();
  //     // setImgLocalPath(e.target.value);
  //     var tmp = e.target.value.split('\\');
  //     setImgLocalPath(tmp[tmp.length - 1]);
  //     reader.onloadend = () => {
  //       const base64 = reader.result;
  //       const naturalImage = new Image();

  //       naturalImage.src = reader.result.toString();

  //       // if (base64){
  //       //   setStoreLogo(base64.toString());

  //       // }
  //       naturalImage.onload = function (e) {

  //         let naturalWidth = e.target.naturalWidth;
  //         let naturalHeight = e.target.naturalHeight;

  //         let resizeWidth = 0;
  //         let resizeHeight = 0;

  //         if (naturalWidth < 600) {
  //           resizeWidth = naturalWidth;
  //           resizeHeight = naturalHeight;
  //         }
  //         else {
  //           resizeWidth = 600;
  //           resizeHeight = (600 * naturalHeight) / naturalWidth;
  //         }


  //         let finalHeight = (resizeHeight * 368) / resizeWidth;

  //         setPictureWidth(368);
  //         setPictureHeight(finalHeight);

  //         const image = new Image();
  //         const canvas = CanvasRef.current;
  //         const ctx = canvas?.getContext('2d');

  //         if (ctx) {
  //           if (naturalWidth > 600) {
  //             canvas.width = resizeWidth;
  //             canvas.height = resizeHeight;
  //             // canvas.backgroundColor = '#FFFFFF';

  //             image.src = base64.toString();

  //             // 사진 크기 조정후 Canvas에 그리기 //
  //             image.onload = function () {
  //               ctx.clearRect(0, 0, canvas.width, canvas.height);
  //               ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
  //               // ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
  //             }

  //             // 크기 조절된 사진 데이터 정리하기 //
  //             canvas.toBlob(function (blob) {
  //               const reader = new FileReader();
  //               // Canvas에 다 그려졌을때 Event //
  //               reader.onload = function (e) {
  //                 // setStoreLogo(canvas.toDataURL());
  //                 // console.log(canvas.toDataURL())
  //                 setPicture(canvas.toDataURL());
  //                 setPictures([...pictures, canvas.toDataURL()]);
  //                 setPictureIndex(pictures.length);
  //               }
  //               reader.readAsDataURL(blob);
  //             })
  //           }
  //           else {
  //             setPicture(base64.toString());
  //             setPictures([...pictures, base64.toString()]);
  //             setPictureIndex(pictures.length);
  //           }
  //         }
  //         else {
  //           // throw new Error('Could not get context');
  //           alert('사용할 수 없는 파일입니다');
  //           console.log("Could not get context");
  //         }
  //       }
  //     };
  //     if (e.target.files[0]) {
  //       reader.readAsDataURL(e.target.files[0]);
  //       // setImgFile(e.target.files[0]);
  //       // setStoreLogo(e.target.files[0]);
  //     }
  //   }
  //   else {
  //     alert('.jpeg .jpg .png 파일만 가능합니다!');
  //   }
  // };

  const handleChange = e => {

    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        // if (base64){
        //   setStoreLogo(base64.toString());

        // }
        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < 1080) {
            resizeWidth = naturalWidth;
            resizeHeight = naturalHeight;
          }
          else {
            resizeWidth = 1080;
            resizeHeight = (1080 * naturalHeight) / naturalWidth;
          }


          let finalHeight = (resizeHeight * 368) / resizeWidth;

          setPictureWidth(368);
          setPictureHeight(finalHeight);

          // setPicture({ original: base64.toString(), edit: base64.toString(), division: 'Original' });
          // setPictures([...pictures, { original: base64.toString(), edit: base64.toString(), division: 'Original' }]);
          // setPictureIndex(pictures.length);

          const image = new Image();
          const canvas = CanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            if (naturalWidth > 1080) {
              canvas.width = resizeWidth;
              canvas.height = resizeHeight;
              // canvas.backgroundColor = '#FFFFFF';

              image.src = base64.toString();

              // 사진 크기 조정후 Canvas에 그리기 //
              image.onload = function () {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
                // ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
              }

              // 크기 조절된 사진 데이터 정리하기 //
              canvas.toBlob(function (blob) {
                const reader = new FileReader();
                // Canvas에 다 그려졌을때 Event //
                reader.onload = function (e) {
                  // setStoreLogo(canvas.toDataURL());
                  console.log(canvas.toDataURL())
                  // setPicture({ original: canvas.toDataURL(), edit: base64.toString(), division: 'Original' });
                  // setPictures([...pictures, { original: canvas.toDataURL(), edit: base64.toString(), division: 'Original' }]);
                  setPicture({ original: base64.toString(), edit: canvas.toDataURL(), division: 'Original' });
                  setPictures([...pictures, { original: base64.toString(), edit: canvas.toDataURL(), division: 'Original' }]);
                  setPictureIndex(pictures.length);
                }
                reader.readAsDataURL(blob);
              })
            }
            else {
              setPicture({ original: base64.toString(), edit: base64.toString(), division: 'Original' });
              setPictures([...pictures, { original: base64.toString(), edit: base64.toString(), division: 'Original' }]);
              setPictureIndex(pictures.length);
            }
          }
          else {
            // throw new Error('Could not get context');
            alert('사용할 수 없는 파일입니다');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        // setImgFile(e.target.files[0]);
        // setStoreLogo(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };

  // 사진 삭제 버튼 클릭시 //
  function onClickDeletePicture() {
    // setPicture('');
    if (pictureIndex !== 0) {
      let img = document.createElement('img');
      img.src = pictures[pictureIndex - 1].edit;

      img.onload = () => {
        let finalWidth = 368;
        let finalHeight = (img.height * finalWidth) / img.width;

        setPictureWidth(finalWidth);
        setPictureHeight(finalHeight);
      }

      setPicture(pictures[pictureIndex - 1]);
      setPictureIndex(pictureIndex - 1);

    }

    let tmpPictures = [...pictures];

    tmpPictures.splice(pictureIndex, 1);

    setPictures(tmpPictures);

    if (tmpPictures.length === 0) setIsClickedEditPhoto(false);
  }

  // 붙여넣기나 스페이스바, 엔터키 감지시 URL 체크 //
  function onCheckURL() {
    setTimeout(() => {
      setIsNeedCheckURL(true);
    }, 100);
  }

  // 공지사항내에 링크 추출 //
  function collectURL(text) {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let url = text.match(urlRegex) === null ? '' : text.match(urlRegex)[1];

    return url;
  }

  // 링크 클릭시 //
  function onClickURL(url) {
    window.open(url, "_blank", "noreferrer");
  }

  // URL 삭제 클릭시 //
  function onClickDeleteURL(index) {
    let newURLs = urls.slice();

    newURLs.splice(index, 1);
    setUrls(newURLs);
  }

  // 유튜브링크인지 확인 //
  function decideYoutubeLink(url) {
    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      return false;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      return false;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      return false;
    }
    else return true;
  }

  // 유튜브링크인지 확인후 Id 만 추출 //
  function decideYoutubeLinkVideoId(url) {
    if (url.includes('https://youtu.be/')) return url.replace('https://youtu.be/', '');
    else if (url.includes('https://www.youtube.com/watch?v=')) return url.replace('https://www.youtube.com/watch?v=', '')
  }

  // 카카오 알림톡 발송 회원수 결정 //
  function decideMemberCount() {
    if (isClickedValid && isClickedInvalid) return (countData.validCount + countData.invalidCount).toLocaleString('ko-KR');
    else if (isClickedValid) return countData.validCount.toLocaleString('ko-KR');
    else if (isClickedInvalid) return countData.invalidCount.toLocaleString('ko-KR');
    else return 0;
  }

  // 카카오 알림톡 발송 회원수에 따른 발송 금액 계산 //
  function decideCalcKakaoBizPrice() {
    if (isClickedValid && isClickedInvalid) return ((countData.validCount + countData.invalidCount) * 10).toLocaleString('ko-KR');
    else if (isClickedValid) return (countData.validCount * 10).toLocaleString('ko-KR');
    else if (isClickedInvalid) return (countData.invalidCount * 10).toLocaleString('ko-KR');
    else return 0;
  }

  // 카카오 알림톡 버튼 클릭시 //
  function onClickKakaoBiz() {
    // 카드번호 입력이 안되어있는 경우 //
    if (!countData.isRegistrationCard) {
      // 모달 표출 //
      setIsViewRegistrationCard(true);
    }
    else {
      setIsClickedKakaoBiz(!isClickedKakaoBiz);
    }
  }

  // 입력하기 버튼 클릭시 //
  function onClickRegistrationCard() {
    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'AUTH'
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_CARD_VER = '01';
    obj.PCD_PAYER_AUTHTYPE = 'sms';
    // obj.PCD_RST_URL = process.env.REACT_APP_TEST_HOSTNAME + '/subscribe/success';
    // obj.PCD_RST_URL = 'https://link-soft.io/';

    // obj.PCD_PAY_GOODS = 'Link-Zone 정기 구독권 (1개월)';
    // obj.PCD_PAY_TOTAL = 100;
    // obj.PCD_PAY_OID = responseData.data[0].nextMerchantUid;


    obj.callbackFunction = getResult;
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';

    window.PaypleCpayAuthCheck(obj);
  }

  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
      let requestData = {
        billingKey: res.PCD_PAYER_ID,
        cardName: res.PCD_PAY_CARDNAME,
        cardNum: res.PCD_PAY_CARDNUM
      }
      // console.log(requestData);
      // console.log(res);
      // console.log(res.PCD_PAYER_ID);
      // console.log(res.PCD_PAYER_NO);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('billing/registry', 'POST', requestData);

          if (responseData.status === 200) {
            alert('카드번호 등록이 완료되었습니다!');
            setCountData({ isRegistrationCard: true, validCount: countData.validCount, invalidCount: countData.invalidCount });
            setIsViewRegistrationCard(false);
          }
          else if (responseData.status === 201) {
            alert('이미 등록된 정보입니다\n새로고침 후 다시시도해 주세요');
            setIsViewRegistrationCard(false);
          }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }

  // 사진 이전 버튼 클릭시 //
  function onClickPrevPicture() {
    if (pictureIndex > 0) {
      let img = document.createElement('img');
      img.src = pictures[pictureIndex - 1].edit;
      
      img.onload = () => {
        let finalWidth = 368;
        let finalHeight = (img.height * finalWidth) / img.width;

        setPictureWidth(finalWidth);
        setPictureHeight(finalHeight);
      }

      setPicture(pictures[pictureIndex - 1]);
      setPictureIndex(pictureIndex - 1);
    }
  }

  // 사진 다음 버튼 클릭시 //
  function onClickNextPicture() {
    if (pictureIndex < pictures.length - 1) {
      let img = document.createElement('img');
      img.src = pictures[pictureIndex + 1].edit;

      img.onload = () => {
        let finalWidth = 368;
        let finalHeight = (img.height * finalWidth) / img.width;

        setPictureWidth(finalWidth);
        setPictureHeight(finalHeight);
      }

      setPicture(pictures[pictureIndex + 1]);
      setPictureIndex(pictureIndex + 1);
    }
  }

  return (
    <TopLevelWrapper>
      { isViewRegistrationCard && <NotRegistrationCardModal division='Notice' setIsViewModal={setIsViewRegistrationCard} onClickRegistCard={onClickRegistrationCard} />}
      { 
        isClickedEditPhoto && 
        <ImageEditModal
          picture={picture}
          setPicture={setPicture}
          pictures={pictures}
          setPictures={setPictures}
          pictureIndex={pictureIndex}
          setPictureIndex={setPictureIndex}
          setNoEditPictureWidth={setPictureWidth}
          setNoEditPictureHeight={setPictureHeight}
          onClickExit={() => setIsClickedEditPhoto(false)}
          onClickPrevPicture={onClickPrevPicture}
          onClickNextPicture={onClickNextPicture}
          onClickDeletePicture={onClickDeletePicture}/> 
      }
      <ViewWrapper>
        <ComponentWrapper>
          {/* <Title>제목</Title>
          <NewTitleInput ref={TitleRef} placeholder="50자 이내의 제목을 입력해주세요" /> */}
          <DateTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='default'>{moment().format('YYYY년 M월 D일 공지')}</Text>
            <FixingButton isClicked={isClickedFixed} onClick={() => setIsClickedFixed(!isClickedFixed)}>
              <FixingWrapper opacity={isClickedFixed ? 0 : 1}>
                <FixingImage src={BlackFixingPin} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>상단 고정</Text>
              </FixingWrapper>
              <FixingWrapper opacity={isClickedFixed ? 1 : 0}>
                <FixingImage src={WhiteFixingPin} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>상단 고정</Text>
              </FixingWrapper>
            </FixingButton>
          </DateTitleWrapper>
          <NewTitleInput ref={TitleRef} storeLogo={storeLogo} placeholder="50자 이내의 제목을 입력해주세요" />
        </ComponentWrapper>
        <ComponentWrapper style={{ marginTop: `24px` }}>
          <Title style={{ marginBottom: '8px' }}>내용</Title>
          <NewTextArea value={value} setValue={setValue} checkURL={onCheckURL} />
        </ComponentWrapper>
        <URLWrapper>
          {
            urls.map((data, index) => (
              decideYoutubeLink(data) ?
                <VideoViewWrapper key={index}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={(decideYoutubeLink(data)) ? `https://www.youtube.com/embed/` + decideYoutubeLinkVideoId(data) : ''}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube" />
                  <VideoDeleteButtonWrapper onClick={() => onClickDeleteURL(index)} >
                    <DeleteButton margin='10px 10px 0px 0px' width={24} height={24} radius={24}>
                      <Icon src={WhiteExitIcon} width={18} height={18} />
                    </DeleteButton>
                  </VideoDeleteButtonWrapper>
                </VideoViewWrapper>
                :
                <URLTextBox key={index}>
                  <DeleteButton onClick={() => onClickDeleteURL(index)} margin='0px 0px 0px 0px' width={15} height={15} radius={15}>
                    <Icon src={WhiteExitIcon} width={13} height={13} />
                  </DeleteButton>
                  <InnerURLTextBox onClick={() => onClickURL(data)}>
                    <Icon src={URLIcon} />
                    <TextWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='pointer'>{data}</Text>
                    </TextWrapper>
                  </InnerURLTextBox>
                </URLTextBox>
            ))
          }
        </URLWrapper>
        <TotalKakaoSendWrapper>
          <KakaoSendWrapper isClicked={isClickedKakaoBiz}>
            <TotalSendInfoWrapper>
              <SendInfoWrapper gap={16}>
                <CheckBoxWrapper onClick={() => setIsClickedValid(!isClickedValid)}>
                  <SendCheckBox isClicked={isClickedValid} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666' cursor='pointer'>유효 회원</Text>
                </CheckBoxWrapper>
                <CheckBoxWrapper onClick={() => setIsClickedInvalid(!isClickedInvalid)}>
                  <SendCheckBox isClicked={isClickedInvalid} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666' cursor='pointer'>만료 회원</Text>
                </CheckBoxWrapper>
              </SendInfoWrapper>
              <SendInfoWrapper gap={16}>
                <TotalTextWrapper>
                  <SendTextWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666' cursor='default'>{`${decideMemberCount()} 명 회원에게 카카오톡 메시지 발송시 `}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='default'>{`${decideCalcKakaoBizPrice()} 원`}</Text>
                  </SendTextWrapper>
                </TotalTextWrapper>
              </SendInfoWrapper>
            </TotalSendInfoWrapper>
            <KakaoSendButton isClicked={isClickedKakaoBiz} onClick={onClickKakaoBiz}>
              <Icon src={KakaoTalkSpeechBubbleIcon} width={26} height={24} style={{ margin: `-1px 0px 0px -3px` }} />
            </KakaoSendButton>
          </KakaoSendWrapper>
        </TotalKakaoSendWrapper>
      </ViewWrapper>
      <canvas ref={CanvasRef} style={{ display: 'none' }} />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }} />
      {/* {
        picture === '' ?
          <PictureLoadigButton ref={FileInputRef} onClick={handleClick}>
            <PictureIcon src={NoticePictureIcon} />
          </PictureLoadigButton>
          :
          <ImageViewWrapper width={pictureWidth} height={pictureHeight}>
            <ExitButtonWrapper>
              <ExitButton onClick={onClickDeletePicture}>
                <ExitIcon src={WhiteExitIcon} />
              </ExitButton>
            </ExitButtonWrapper>
            <ImageView src={picture} />
          </ImageViewWrapper>
      } */}
      {
        pictures.length === 0 &&
        <PictureLoadigButton ref={FileInputRef} onClick={handleClick}>
          <PictureIcon src={NoticePictureIcon} />
        </PictureLoadigButton>
      }
      {
        pictures.length > 0 &&
        <TotalImageWrapper width={pictureWidth} >
          <TotalImageInnerWrapper width={pictureWidth} >
            <ImageWrapper width={pictureWidth} height={pictureHeight}>
              <PictureImage width={pictureWidth} height={pictureHeight} src={picture.edit} />
            </ImageWrapper>

            <ExitButtonWrapper width={pictureWidth} height={pictureHeight}>
              <PictureEditButton onClick={() => setIsClickedEditPhoto(true)}>
                <PictureImage width={18} height={18} src={WhitePencelIcon} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>수정</Text>
              </PictureEditButton>
              <ExitButton onClick={onClickDeletePicture}>
                <ExitIcon src={WhiteExitIcon} />
              </ExitButton>
            </ExitButtonWrapper>

            <MovePictureButtonWrapper>
              <MoveButton isView={pictureIndex > 0} onClick={onClickPrevPicture}>
                <LeftIcon />
              </MoveButton>
              <MoveButton isView={pictureIndex < pictures.length - 1} onClick={onClickNextPicture}>
                <RightIcon />
              </MoveButton>
            </MovePictureButtonWrapper>
          </TotalImageInnerWrapper>
          {
            pictures.length < 10 &&
            <AddPictureButtonWrapper ref={FileInputRef} onClick={handleClick}>
              <AddPictureButton>
                <PictureImage width={20} height={20} src={CameraIcon} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='pointer'>사진 추가</Text>
              </AddPictureButton>
            </AddPictureButtonWrapper>
          }
        </TotalImageWrapper>
      }
    </TopLevelWrapper>
  )
})

export default NewNoticeCreateBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 1020px;
  // height: 535px;

  gap: 20px;

  margin-top: 38px;
  margin-bottom: 96px;
  padding-bottom: 68px;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  diplay: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding: 28px 28px 28px 28px;
  // padding-top: 28px;
  padding-top: 12px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin: 0px 28px 0px 28px;
`;

const DateTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const FixingButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 88px;
  height: 28px;

  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#FBFAF9`}; 
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const FixingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const FixingImage = styled.img`
  width: 16px;
  height: 16px;

  object-fit: contain;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #232323;
`;

const PictureLoadigButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 55px;
  height: 55px;

  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const PictureIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const TotalImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
`;

const TotalImageInnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const MovePictureButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px;

  width: calc(100% - 24px);
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: #808080;

  opacity: ${props => props.isView ? 1 : 0};

  :hover {
    cursor: ${props => props.isView ? `pointer` : `default`};
  }
    
  transition: all 0.3s ease-in-out;
`;

const LeftIcon = styled.div`
  margin-left: -4px;

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 9px 6px 0px;
  border-color: transparent #FFFFFF transparent transparent;
`;

const RightIcon = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0px 6px 9px;
  border-color: transparent transparent transparent #FFFFFF;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  
  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const PictureImage = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ImageViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;

  overflow: hidden;
  // background-color: red;
`;

const PictureEditButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 54px;
  height: 24px;

  gap: 2px;

  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.60);
`;

const AddPictureButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
`;

const AddPictureButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 6px 12px;

  gap: 4px;

  border-radius: 5px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const ImageView = styled.img`
  width: 100%;
  height: 100%;
  // height: auto;
`;

const ExitButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 8px;

  padding: 0px 8px;

  width: calc(368px - 8px);
  height: calc(100% - 8px);
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);

  :hover {
    cursor: pointer;
  }
`;

const ExitIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const URLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-top: 16px;
  margin: 16px 28px 0px 28px;

  gap: 4px;

  width: 582px;
`;

const URLTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 24px;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const InnerURLTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 39px);
  height: 24px;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 26px);
  height: 22px;

  overflow: hidden;
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  margin: 10px 0px 10px 0px;
  width: 100%;
  // height: 100%;
  // width: 547px;
  // height: 294px;
  height: 330px;
  
  overflow: hidden;

  border-radius: 8px;
  
  transition: all 0.3s ease-in-out;
`;

const VideoDeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: ${props => props.margin};

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: rgba(0, 0, 0, 0.6);

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const TotalKakaoSendWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 16px;

  width: 100%;
  height: 41px;

  overflow: hidden;
`;

const KakaoSendWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: ${props => props.isClicked ? `100%` : `51px`};
  height: 100%;

  overflow: hidden;

  border-radius: ${props => props.isClicked ? `0px 0px 30px 30px` : `8px 0px 30px 0px`};
  background-color: #FFF9C3;

  transition: all 0.3s ease-in-out;
`;

const TotalSendInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin: 0px 16px 0px 28px;

  width: 92.85%;
`;

const SendInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.gap}px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  white-space: nowrap;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const TotalTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const SendTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  white-space: nowrap;

  gap: 4px;
`;

const SendCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  border-radius: 16px;
  border: 1px solid #DFDFDF;
  background-color: ${props => props.isClicked ? `#F9E000` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;
`;

const KakaoSendButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 51px;
  height: 41px;
  min-width: 51px;
  min-height: 41px;
  max-width: 51px;
  max-height: 51px;

  border-radius: 8px 0px 30px 0px;
  background-color: ${props => props.isClicked ? `#F9E000` : `#FFF48D`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ProfileBoxWrapper = styled.div`
	position: absolute;
  width: 48px;
  height: 48px;

  border-radius: 48px;
  
  overflow: hidden;

  :hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #FF8B48);
  }

  transition: all 0.3s ease-in-out;

	background-color: #FBFAF9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;