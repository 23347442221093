import { React, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import LockerChangeNameInput from '../../../Component/LockerComponent/LockerChangeNameInput';

const LockerChangeNameModal = forwardRef((props, ref) => {
  const { exitClick, saveClick, data, index, lockerIndex } = props;

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
      return InputRef.current.getValue();
    }
	}));

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    if (index === -1 || lockerIndex === -1) return;

    InputRef.current.setValue(data[index].lockers[lockerIndex].lockerName);
  }, [data, index, lockerIndex])

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <TitleWrapper>
            <Title>이름 변경</Title>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>원하는 이름을 입력해주세요</Text>
          </TitleWrapper>
          <LockerChangeNameInput ref={InputRef}/>
          <ButtonWrapper>
            <ExitButton onClick={exitClick}>취소</ExitButton>
            <SaveButton onClick={() => saveClick(InputRef.current.getValue())}>저장하기</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerChangeNameModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 328px;
  height: 228px;

  border-radius: 10px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 30px;

  width: 100%;

  gap: 10px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #3E4148;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;