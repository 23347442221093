import { React, useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MorePath from '../../image/More.png';
import HoverMorePath from '../../image/HoverMore.png';
import ExpireMorePath from '../../image/ExpireMore.png';
import CalcDate from '../../Model/Share/Normal/CalcDate';
import moment from 'moment';

const LockerInfo = forwardRef((props, ref) => {
  const { 
    index,
    lockerIndex,
    data, 
    clickFunctionFn, 
    clickUsageHistoryFn, 
    clickChangeStateFn, 
    clickDeleteLockerFn, 
    clickChangeNameFn,
    clickSendMessageFn,
    clickExtendFn
  } = props;

  // const [data, setData] = useState(data);
  const [clickedMore, setClickedMore] = useState(false);
  const [individualData, setIndividualData] = useState();

  const MoreButtonRef = useRef();
  const MoreBoxRef = useRef();
  const HistoryRef = useRef();
  const TextRef = useRef();
  const LockRef = useRef();
  const DeleteRef = useRef();
  const ChangeRef = useRef();
  const MessageRef = useRef();
  const ExtendRef = useRef();

  const history = useHistory();

  useImperativeHandle(ref, () => ({
		setClickedMore: (val) => {
      setClickedMore(val);
    },
    getMoreButtonRef: () => {
      return MoreButtonRef.current;
    },
    getMoreBoxRef: () => {
      return MoreBoxRef.current;
    }
	}));

  useEffect(() => {
    
    if (data.state === 'Empty') {
      data.isAvailable = true;
      data.isUsed = false;
      data.state = '비어있음';
      data.days = '';
      data.btnText = '사용 시작';
      data.border = '1px solid #777777';
      
      data.width = 120;
      data.height = 120;
    }
    else if (data.state === 'Using') {
      data.isAvailable = true;
      data.isUsed = true;
      data.state = '사용중'
      data.days = CalcDate.getDday(moment(data.endDate).format('YYYY-MM-DDT00:00:00'));
      data.btnText = '사용 종료';
      data.border = (moment(data.endDate).isAfter(moment()) || moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD')) ? '2px solid #5BD98A' : `2px solid #FF3131`;
      
      data.width = 118;
      data.height = 118;
    }
    else if (data.state === 'Invalid') {
      data.isAvailable = false;
      data.isUsed = false;
      data.state = '사용불가'
      data.days = '';
      data.btnText = '잠금 해제';
      data.border = '1px solid #DEDEDE';

      data.width = 120;
      data.height = 120;
    }
    else if (data.state === 'Expected') {
      data.isAvailable = false;
      data.isUsed = false;
      data.state = '사용 예정'
      data.days = '-';
      data.btnText = '잠금 해제';
      data.border = '1px solid #DEDEDE';

      data.width = 120;
      data.height = 120;
    }

    setIndividualData(data);
  }, [data])

  useEffect(() => {
    if (clickedMore) {
      MoreBoxRef.current.style.border = `1px solid #FF8B48`;
      if (data.state === '비어있음') MoreBoxRef.current.style.height = `180px`;
      else if (data.state === '사용중') {
        MoreBoxRef.current.style.height = `144px`;
        // MoreBoxRef.current.style.height = `180px`;
      }
      else MoreBoxRef.current.style.height = `108px`;
    }
    else {
      MoreBoxRef.current.style.border = `0px solid #FF8B48`;
      MoreBoxRef.current.style.height = `0px`;
    }
  }, [clickedMore, data])

  // 마우스 클릭 감지 리스너 //
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 마우스 커서가 락커가 아닌곳을 클릭할 경우 체크 //
  function onClickOutSide(e) {
    if (MoreBoxRef.current !== e.target 
      && MoreButtonRef.current !== e.target
      && HistoryRef.current !== e.target
      && TextRef.current !== e.target
      && LockRef.current !== e.target
      && DeleteRef.current !== e.target
      && ChangeRef.current !== e.target
      && MessageRef.current !== e.target
      && ExtendRef.current !== e.target) {
      setClickedMore(false);
    }
  }

  // 더보기 버튼 클릭시 //
  function MoreButtonClick() {
    if (data.state === '사용 예정') {
      alert('결제 완료 후 변경 가능합니다');
      return;
    }

    if (clickedMore) setClickedMore(false);
    else setClickedMore(true);
  }

  // 사용자 이름 및 D-day클릭시 //
  function onClickMidLineWrapper() {
    if (data.memberId === 0) return;

    history.push('/member/valid/detail/' + data.memberId + '/locker');
  }

  // D-Day 검사 //
  function checkDday() {
    // data.days === '' ? '' : moment(data.endDate).isAfter(moment()) ? 'D-' + data.days : moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') ? 'D-Day' : 'D+' + data.days
  //  if (data.userName === '룸링크') {
  //   console.log(data);
  //   console.log('moment(data.endDate).format(\'YYYY.MM.DD\')');
  //   console.log(moment(data.endDate).format('YYYY.MM.DD'));
  //   console.log('moment().format(\'YYYY.MM.DD\')');
  //   console.log(moment().format('YYYY.MM.DD'));
  //   console.log(moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day'))
  //  }

    // if (data.days === '') return '';
    // else if (moment(data.endDate).isAfter(moment())) return 'D-' + data.days;
    // else if (moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD')) return 'D-Day' 
    // else return 'D+' + data.days;

    if (data.days === '') return '';
    else if (data.days === '-') return 'Expected';
    else if (moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day') > 0) return 'D-' + moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day');
    else if (moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD')) return 'D-Day' 
    // else return 'D+' + data.days;
    else return 'D+' + moment(moment().format('YYYY-MM-DD')).diff(moment(moment(data.endDate).format('YYYY-MM-DD')), 'day')
  }

  return (
    <TopLevelWrapper>
      <InfoBox border={data.border} width={data.width} height={data.height}>
        <TopLineWrapper>
          <Number>{data.lockerName}</Number>
          <More ref={MoreButtonRef} isAvailable={data.isAvailable} onClick={() => MoreButtonClick()}/>
        </TopLineWrapper>
        <MidLineWrapper onClick={onClickMidLineWrapper}>
          <Name>{data.userName}</Name>
          <Dday>{checkDday()}</Dday>
          {/* <Dday>{data.days === '' ? '' : moment(data.endDate).isAfter(moment()) ? 'D-' + data.days : moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') ? 'D-Day' : 'D+' + data.days}</Dday> */}
        </MidLineWrapper>
        <BottomLineWrapper days={data.days}>
          <State isAvailable={data.isAvailable} isUsed={data.isUsed}>{data.state}</State>
        </BottomLineWrapper>
      </InfoBox>
      <MoreBox ref={MoreBoxRef}>
        <MoreButton ref={HistoryRef} onClick={() => clickUsageHistoryFn(index, lockerIndex)}>이용 내역</MoreButton>
        <MoreButton ref={TextRef} onClick={() => clickFunctionFn(index, lockerIndex)}>{data.btnText}</MoreButton>
        { data.state === '비어있음' && <MoreButton ref={LockRef} onClick={() => clickChangeStateFn(index, lockerIndex)}>락커 잠금</MoreButton> }
        { data.state === '비어있음' && <MoreButton ref={DeleteRef} onClick={() => clickDeleteLockerFn(index, lockerIndex)}>락커 삭제</MoreButton> }
        <MoreButton ref={ChangeRef} onClick={() => clickChangeNameFn(index, lockerIndex)}>이름 변경</MoreButton>
        {/* { data.state === '사용중' && <MoreButton ref={MessageRef} onClick={() => clickSendMessageFn(index)}>문자 발송</MoreButton> } */}
        { data.state === '사용중' && <MoreButton ref={ExtendRef} onClick={() => clickExtendFn(index, lockerIndex)}>사용 연장</MoreButton> }
      </MoreBox>
    </TopLevelWrapper>
  )
});

export default LockerInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 120px;
  // height: 120px;

  // width: ${props => props.reduce ? `118px` : `120px`};
  // height: ${props => props.reduce ? `118px` : `120px`};
  width: ${props => props.height}px;
  height: ${props => props.height}px;

  // padding-left: 9px;

  // border: 1px solid #FF8B48;
  // border: ${props => props.isAvailable ? props.isUsed ? `1px solid #FF8B48` : `1px solid #777777` : `1px solid #DEDEDE`};
  border: ${props => props.border};
  border-radius: 12px;
  
  transition: all 0.3s ease-in-out;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
  // gap: 80px;
  margin-left: 8px;

  width: 90%;
  height: 25px;

  overflow: hidden;
`;

const Number = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const More = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 32px;

  background: ${props => props.isAvailable ? `url(${MorePath}) no-repeat center center` : `url(${ExpireMorePath}) no-repeat center center`};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverMorePath}) no-repeat center center;
  }
`;

const MidLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
  
  width: 100%;

  margin-top: 4px;
  margin-left: 9px;

  :hover {
    cursor: pointer;
  }
`;

const Name = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Dday = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;
  
  color: #A6A6A6;

  margin: 0 0 0 0;
`;

const BottomLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  // margin-top: ${props => props.days === '' ? '34px' : '15px'};
  margin-top: ${props => props.days === '' ? '67px' : '24px'};
  margin-right: 10px;
`;


const State = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: ${props => props.isAvailable ? props.isUsed ? `#FF8B48` : `#A6A6A6` : `#FF3131`};
  
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const MoreBox = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  justify-content: center;
  align-items: center;

  width: 78px;
  height: 0px;

  margin-top: 35px;
  margin-left: 60px;

  // border: 1px solid #FF8B48;
  border-radius: 8px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const MoreButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 78px;
  height: 36px;

  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    color: #FFFFFF;
  }
`;