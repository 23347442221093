import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

export default function NewDataBar(props) {
  const { data, index, thisYear, maximum, thisYearMaxPrice, totalMaxPrice } = props;

  const [cardWidth, setCardWidth] = useState(0);
  const [cashWidth, setCashWidth] = useState(0);
  const [discountWidth, setDiscountWidth] = useState(0);
  const [notReceiveWidth, setNotReceiveWidth] = useState(0);

  const [totalWidth, setTotalWidth] = useState(0);
  const [noneWidth, setNoneWidth] = useState(0);

  const HoverRef = useRef();

  // useEffect(() => {
  //   if (maximum === 0) {
  //     setCardWidth(0);
  //     setCashWidth(0);
  //     setNotReceiveWidth(0);
  //     setNoneWidth(0);

  //     return;
  //   }

  //   // const maxWidth = 450;
  //   const maxWidth = 286;
  //   let totalPrice = (data.cardPrice + data.cashPrice)

  //   if (data.cardPrice !== 0) setCardWidth((data.cardPrice * maxWidth) / maximum);
  //   if (data.cashPrice !== 0) setCashWidth((data.cashPrice * maxWidth) / maximum);
  //   if (data.discountPrice !== 0) setDiscountWidth((data.discountPrice * maxWidth) / maximum);
  //   if (data.notReceived !== 0) setNotReceiveWidth((data.notReceived * maxWidth) / maximum);
  //   if ((data.cardPrice + data.cashPrice + data.discountPrice + data.notReceived) === 0) setNoneWidth(((maximum / 2) * maxWidth) / maximum);
  //   // console.log(noneWidth);
  // }, [data, maximum])

  // 데이터바 크기 결정 //
  useEffect(() => {
    if (maximum === 0) {
      setTotalWidth(0);
      return;
    }

    const maxWidth = 286;

    let totalPrice = (data.cardPrice + data.cashPrice);

    if ((data.cardPrice + data.cashPrice + data.discountPrice + data.notReceived) === 0) setTotalWidth(((maximum / 2) * maxWidth) / maximum);
    else setTotalWidth((totalPrice * maxWidth) / maximum);
  }, [data, maximum])

  // 마우스 호버링시 //
  function mouseOver(e) {
    if (data.totalPrice === 0) return;
    
    HoverRef.current.style.zIndex = 5;
    HoverRef.current.style.opacity = 1;
  }

  // 마우스 호버링시 //
  function mouseLeave() {
    if (data.totalPrice === 0) return;

    HoverRef.current.style.zIndex = -5;
    HoverRef.current.style.opacity = 0;
  }

  // 월간 매출 증감률 계산 //
  function decideMonthlyRevenueChangeRate() {
    if (thisYear.length === 0) return '0%';
    else if (index === 0) {
      // let thisMonth = thisYear[index].totalPrice === 0 ? 1 : thisYear[index].totalPrice;
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[11].prevTotalPrice === 0 ? 1 : thisYear[11].prevTotalPrice;

      // console.log('------------');
      // console.log('index : ' + index);
      // console.log('this1 : ' + thisMonth);
      // console.log('prev1 : ' + prevMonth);

      if (thisMonth === 0) return '0%';
      else return parseInt(((thisMonth - prevMonth) / prevMonth) * 100) + '%';
    }
    else {
      // let thisMonth = thisYear[index].totalPrice === 0 ? 1 : thisYear[index].totalPrice;
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[index - 1].totalPrice === 0 ? 1 : thisYear[index - 1].totalPrice;

      // console.log('------------');
      // console.log('index : ' + index);
      // console.log('this : ' + thisMonth);
      // console.log('prev : ' + prevMonth);

      if (thisMonth === 0) return '0%';
      else return parseInt(((thisMonth - prevMonth) / prevMonth) * 100) + '%';
    }
  }

  // 월간 매출 증감률에 따른 박스 색상 결정 //
  function decideMonthlyRevenueChangeRateColorBox() {
    if (thisYear.length === 0) return '#C6C6C6';
    else if (index === 0) {
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[11].prevTotalPrice === 0 ? 1 : thisYear[11].prevTotalPrice;

      if (thisMonth === 0) return '#C6C6C6';
      else if (parseInt(((thisMonth - prevMonth) / prevMonth) * 100) > 0) return '#D92828';
      else return '#2F80ED';
    }
    else {
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[index - 1].totalPrice === 0 ? 1 : thisYear[index - 1].totalPrice;

      if (thisMonth === 0) return '#C6C6C6';
      else if (parseInt(((thisMonth - prevMonth) / prevMonth) * 100) > 0) return '#D92828';
      else return '#2F80ED';
    }
  }

  // 년간 매출 증감률 계산 //
  function decideYearRevenueChangeRate() {
    if (thisYear.length === 0) return '0%';
    else {
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[index].prevTotalPrice === 0 ? 1 : thisYear[index].prevTotalPrice;

      // console.log('------------');
      // console.log('index : ' + index);
      // console.log('this : ' + thisMonth);
      // console.log('prev : ' + prevMonth);

      if (thisMonth === 0) return '0%';
      else return parseInt(((thisMonth - prevMonth) / prevMonth) * 100) + '%';
    }
  }

  // 연간 매출 증감률에 따른 박스 색상 결정 //
  function decideYearRevenueChangeRateColorBox() {
    if (thisYear.length === 0) return '#C6C6C6';
    else if (index === 0) {
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[11].prevTotalPrice === 0 ? 1 : thisYear[11].prevTotalPrice;

      if (thisMonth === 0) return '#C6C6C6';
      else if (parseInt(((thisMonth - prevMonth) / prevMonth) * 100) > 0) return '#D92828';
      else return '#2F80ED';
    }
    else {
      let thisMonth = thisYear[index].totalPrice;
      let prevMonth = thisYear[index - 1].totalPrice === 0 ? 1 : thisYear[index - 1].totalPrice;

      if (thisMonth === 0) return '#C6C6C6';
      else if (parseInt(((thisMonth - prevMonth) / prevMonth) * 100) > 0) return '#D92828';
      else return '#2F80ED';
    }
  }

  // 데이터 바 색상 결정 //
  function decideDataBarBackgroundColor() {
    // console.log(thisYearMaxPrice);
    /*if (totalMaxPrice < thisYear[index].totalPrice) return '#FFB800';
    else */if (thisYearMaxPrice <= thisYear[index].totalPrice) return '#FF8B48';
    else return '#C6C6C6';
  }

  return (
    <TopLevelWrapper onMouseLeave={mouseLeave} onMouseOver={mouseOver}>
       {/* {
        cardWidth > 60 ?
          <DataShape width={cardWidth} color='#F7D17F'>
            <DataText>카드</DataText>
            <DataPercent>({Math.round((data.cardPrice / (data.cardPrice + data.cashPrice + data.notReceived)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={cardWidth} color='#F7D17F' />
      }
      {
        cashWidth > 60 ?
          <DataShape width={cashWidth} color='#53B38F'>
            <DataText>현금</DataText>
            <DataPercent>({Math.round((data.cashPrice / (data.cardPrice + data.cashPrice + data.notReceived)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={cashWidth} color='#53B38F' />
      }
      {
        // notReceiveWidth > 57 ?
        notReceiveWidth > 60 ?
          <DataShape width={notReceiveWidth} color='#FF8B48'>
            <DataText>미수금</DataText>
            <DataPercent>({Math.round((data.notReceived / (data.cardPrice + data.cashPrice + data.notReceived)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={notReceiveWidth} color='#FF8B48' />
      }
      {
        data.cardPrice + data.cashPrice + data.notReceived === 0 ?
          <DataShape width={noneWidth} color='#ECECEC'/>
          :
          null
      } */}
      <DataShape width={totalWidth} color={decideDataBarBackgroundColor()} /*color={(data.cardPrice + data.cashPrice + data.notReceived === 0 ? '#ECECEC' : '#FF8B48')}*/>
        <Text style={{ marginRight: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF' cursor='default'>{data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' 원'}</Text>
      </DataShape>
      <SalesComparisonBoxWrapper>
        <SalesComparisonBox>
          <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#777777' cursor='default'>MOM</Text>
          <SalesComparisonPersentBox backgroundColor={decideMonthlyRevenueChangeRateColorBox()}>
            <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF' cursor='default'>{decideMonthlyRevenueChangeRate()}</Text>
          </SalesComparisonPersentBox>
        </SalesComparisonBox>
        <SalesComparisonBox>
          <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#777777' cursor='default'>YOY</Text>
          <SalesComparisonPersentBox backgroundColor={decideYearRevenueChangeRateColorBox()}>
            <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF' cursor='default'>{decideYearRevenueChangeRate()}</Text>
          </SalesComparisonPersentBox>
        </SalesComparisonBox>
      </SalesComparisonBoxWrapper>
      <HoverBox ref={HoverRef}>
        <InfoBox>
          <ColumnWrapper>
            <Column>총 매출</Column>
            <Column>현금</Column>
            <Column>카드</Column>
            <Column>미수금</Column>
          </ColumnWrapper>
          <ColumnWrapper>
            <Content>{data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content>{data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content>{data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content>{data.notReceived.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
          </ColumnWrapper>
          <ColumnWrapper>
            <Unit>원</Unit>
            <Unit>원</Unit>
            <Unit>원</Unit>
            <Unit>원</Unit>
          </ColumnWrapper>
        </InfoBox>
      </HoverBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 28px;

  overflow: hidden;
  border-radius: 0px 2px 2px 0px;

  // background-color: red;
  
  transition: all 0.3s ease-in-out;
`;

const DataShape = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: ${props => props.width}px;
  height: 20px;

  border-radius: 0px 5px 5px 0px;
  background-color: ${props => props.color};

  font-family: NotoSansKR-Bold;
  font-size: 10px;

  overflow: hidden;
  color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DataText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 10px;

  color: #FFFFFF;
  
  margin: 0 0 0 8px;

  :hover {
    cursor: pointer;
  }
`;

const DataPercent = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #FFFFFF;

  margin: 0 0 0 3px;
`;

const HoverBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 182px;
  height: 128px;

  z-index: -5;
  opacity: 0;

  margin-top: 161px;
  margin-left: 161px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const SalesComparisonBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const SalesComparisonBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 2px;
`;

const SalesComparisonPersentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 2px;

  height: 16px;

  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Content = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Unit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #BABABA;

  margin: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
  
  transition: all 0.3s ease-in-out;
`;