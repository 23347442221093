import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import MyStoreSettingTopModel from '../Component/MyStoreSettingTopModel.js';

import FetchModule from '../../Model/Share/Network/FetchModule.js';
import RefundModal from '../../Model/Individual/MySettingModel/RefundModal.js';
import SubscribeModal from '../../Model/Individual/MySettingModel/SubscribeModal.js';

import MemberListExportModal from '../../Model/Individual/MySettingModel/MemberListExportModal.js';
import BillingInfoModal from '../../Model/Individual/MySettingModel/BillingInfoModal.js';
import MyAccountBox from '../Component/MyAccountBox.js';
import MyStoreIntroductionBox from '../Component/MyStoreInstroductionBox.js';
import ProgramFollowBox from '../Component/ProgramFollowBox.js';
import PartnerGymFollowBox from '../Component/PartnerGymFollowBox.js';
import TermContract from '../Component/TermContract.js';
import TermsEditModal from '../Component/TermsEditModal.js';
import ProvisionEditModal from '../Component/ProvisionEditModal.js';
import TermsDeleteModal from '../Component/TermsDeleteModal.js';
import ProvisionDeleteModal from '../Component/ProvisionDeleteModal.js';
import TermsAlertModal from '../Component/TermsAlertModal.js';
import moment from 'moment';
import TermsViewModal from '../Component/TermsViewModal.js';
import ZoneSubscribeBox from '../Component/ZoneSubscribeBox.js';


export default function MyStoreSettingPage() {

  const history = useHistory();

  const [isClickedSubscribe, setIsClickedSubscribe] = useState(false);
  const [isViewBillingInfoModal, setIsViewBillingInfoModal] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);

  const [billingInfo, setBillingInfo] = useState(undefined);

  const [isClickedModifyAccount, setIsClickedModifyAccount] = useState(false);
  const [isClickedModifyGymInfo, setIsClickedModifyGymInfo] = useState(false);
  const [isClickedModifyTerms, setIsClickedModifyTerms] = useState(false);
  const [isClickedRefund, setIsClickedRefund] = useState(false);
  const [refundData, setRefundData] = useState();

  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');

  const [data, setData] = useState(undefined);
  const [salesList, setSalesList] = useState(new Array);
  const [subscribeList, setSubscribeList] = useState(new Array);
  const [rendering, setRendering] = useState(false);

  const [exerciseFollows, setExerciseFollows] = useState([]);
  const [exerciseFollowers, setExerciseFollowers] = useState([]);

  const [partnerGymFollows, setPartnerGymFollows] = useState([]);
  const [partnerGymFollowers, setPartnerGymFollowers] = useState([]);

  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [phone, setPhone] = useState('');
  const [storeLogo, setStoreLogo] = useState('');
  const [storeLogoThumbnail, setStoreLogoThumbnail] = useState('');
  const [storeName, setStoreName] = useState('');
  const [tel, setTel] = useState('');
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [openingHour, setOpeningHour] = useState('');
  const [storeIntro, setStoreIntro] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [profileThumbnails, setProfileThumbnails] = useState([]);
  const [isUsePaymentSystem, setIsUsePaymentSystem] = useState('No');

  const [alertTitle, setAlertTitle] = useState('규정 삭제하기');
  const [alertText, setAlertText] = useState('"기본 규정"은 삭제가 불가능합니다');

  const [isClickedExportExcelModal, setIsClickedExportExcelModal] = useState(false);

  const [isClickedViewTerms, setIsClickedViewTerms] = useState(false);
  const [isClickedEditTerms, setIsClickedEditTerms] = useState(false);
  const [isClickedEditProvision, setIsClickedEditProvision] = useState(false);
  const [isClickedDeleteTerms, setIsClickedDeleteTerms] = useState(false);
  const [isClickedDeleteProvision, setIsClickedDeleteProvision] = useState(false);
  const [isViewAlertModal, setIsViewAlerModal] = useState(false);
  // const data = {
  //   storeName: '테스트',
  //   address: '충주시 지현동 1771번지',
  //   tel: '02-311-3233',
  //   openingHour: '09:00 - 21:00',
  //   storeType: '1:1PT'
  // }

  const [isClickedTerms, setIsClickedTerms] = useState(false);

  const [isSavingTerms, setIsSavingTerms] = useState(false);
  const [isSavingProvision, setIsSavingProvision] = useState(false);

  const [clickedTerms, setClickedTerms] = useState(undefined);
  const [clickedProvision, setClickedProvision] = useState(undefined);

  const [terms, setTerms] = useState([]);

  const [defaultProvisions, setDefaultProvisions] = useState([
    {
      id: 0,
      isDefault: true,
      title: '개인정보 이용 동의서\n[필수 동의]',
      text: '• [센터 이름]에 가입하고 시설을 이용하기 위해 아래와 같이 개인정보를 수집, 이용 및 제3자에게 위탁하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정해 주십시오.\n• 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.\n    • 개인정보 수집, 이용 내역\n        • 항목 : 성명, 성별, 연락처, 생년월일, 주소, 유입경로 (+추가항목은 자동 추가)\n        • 수집, 이용 목적 : 회원가입, 상담, 상품 구매, 이용정보 및 공지사항 전달 관련 정보 제공을 위해 사용합니다.\n        • 개인정보의 보유 및 이용기간 : 수집,이용 동의일로부터 개인정보의 수집, 이용 목적을 달성할 때까지\n    • 개인정보 취급 업무 위탁 내역(고지사항)\n        • 위탁받는 자 : 링코 (주)하이비스\n        • 업무내용 : 회원 정보 관리\n• 귀하께서는 본 안내에 따른 개인정보 수집, 이용에 대하여 동의를 거부하실 권리가 있습니다. 다만, 귀하가 개인정보의 수집/이용에 동의를 거부하시는 경우에 회원가입이 불가함을 알려드립니다.'
    },
    {
      id: 0,
      isDefault: true,
      title: '광고성 정보 이용 동의서\n[선택 동의]',
      text: '• 제공하신 정보(이름, 이메일 주소, 휴대폰 번호)는 [시설이름]의 마케팅 활용을 위해 사용합니다.\n    • 개인정보 제공자가 동의한 내용을 다른 목적으로 활용하지 않습니다.\n    • 귀하는 이에 대한 동의를 거부할 수 있습니다.\n    • 본 계약 만료 후 1년까지 보관합니다.'
    }
  ])

  const [provisions, setProvisions] = useState([]);

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('account/find', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');

        const subscribeResponseData = await fetchModule.getIndividualData('zone_subscribe/list');
        // console.log(subscribeResponseData.data)
        if (subscribeResponseData.status === 200) {

          let subscribeList = subscribeResponseData.data.slice();

          subscribeList.sort(function (a, b) {
            let x = a.approvalDate;
            let y = b.approvalDate;

            if (x < y) return 1;
            if (x > y) return -1;
          })

          let finalSubscribes = [];
          let subscribeCount = 0;

          for (let i = 0; i < subscribeList.length; i++) {
            if ((subscribeList[i].itemName === 'Link-Zone 정기 구독권' 
              || subscribeList[i].itemName === 'Link-Zone 정기 구독권 (1개월)')
              && subscribeCount < 2) {
              finalSubscribes.push(subscribeList[i]);
              subscribeCount += 1;
            }
            else if (subscribeList[i].itemName !== 'Link-Zone 정기 구독권'
              && subscribeList[i].itemName !== 'Link-Zone 정기 구독권 (1개월)'
              && !subscribeList[i].itemName.includes('카카오 알림톡 사용')
            ) finalSubscribes.push(subscribeList[i]);
          }

          setSubscribeList(finalSubscribes);
        }
      }
    )();
  }, [history, rendering])

  // 센터 약관 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('terms/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setTerms(responseData.data.terms);
          setProvisions(responseData.data.provisions);
        }
      }
    )();
  }, [])

  // 운동 프로그램 팔로우 데이터 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExerciseVersion2('share/', 'GET');
        
        if (responseData.status === 200) {
          setExerciseFollows(responseData.data.follows);
          setExerciseFollowers(responseData.data.followers);
        }
      }
    )();
  }, [])

  // 파트너 체육관 데이터 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('partner_gym/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setPartnerGymFollows(responseData.data.follows);
          setPartnerGymFollowers(responseData.data.followers);
        }
      }
    )();
  }, [])

  // Data Setting //
  useEffect(() => {
    if (data === undefined) return;

    setId(data.loginId);
    setEmail(data.email);
    setOwnerName(data.ownerName);
    setPhone(data.phone);
    setStoreLogo(data.storeLogo);
    // console.log(data);
    setStoreLogoThumbnail(data.storeLogoThumbnail);
    setStoreName(data.storeName);
    setAddress(data.storeAddress);
    setTel(data.storeTel);
    setType(data.storeType);
    setOpeningHour(data.openingHour);
    setStoreIntro(data.introduce);
    setIsUsePaymentSystem(data.isUsePaymentSystem);
    // setProfiles(data.profiles.split(','));

    let dates = data.profiles.split(',');
    let thumbnailDates = data.profiles.split(',');
    let newData = [];
    let newThumbnailData = [];

    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === '') break;
      newData.push(dates[i]);
    }

    for (let i = 0; i < thumbnailDates.length; i++) {
      if (thumbnailDates[i] === '') break;
      newThumbnailData.push(thumbnailDates[i]);
    }

    setProfiles(newData);
    setProfileThumbnails(newThumbnailData);
  }, [data])

  // useEffect(() => {
  //   console.log(isClickedExportExcelModal);
  // }, [isClickedExportExcelModal])

  function onClickModify() {
    if (isClickedModify) {

      var newData = '';

      for (var i = 0; i < profiles.length; i++) {
        if (i === 0) newData = profiles[i];
        else newData = newData + '<-@->' + profiles[i];
      }

      var requestData = {
        email: data.email,
        loginId: data.loginId,
        openingHour: openingHour,
        ownerName: ownerName,
        ownerSocialSecurityNul: '',
        phone: phone,
        introduce: storeIntro,
        profiles: newData,
        storeAddress: address,
        storeLogo: storeLogo,
        storeName: storeName,
        storeTel: tel,
        storeType: type,
        isUsePaymentSystem: isUsePaymentSystem,
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('account/modify', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) setData(responseData.data[0]);
          else if (responseData.status === 401) history.push('/');
        }
      )();
      setIsClickedModify(false);
    }
    else {
      setIsClickedModify(true);
    }
  }

  function onClickRefundButton(data) {

    // console.log(data);
    if (data.status === 0) {
      alert("아직 승인되지 않은 결제 내역입니다.");
      return;
    }
    else if (data.status === 2) {
      alert("옳바르지 않은 결제 내역입니다.");
      return;
    }
    else if (data.status === 3) {
      alert("이미 환불이 진행된 결제 내역입니다.");
      return;
    }

    setRefundData(data);
    setIsClickedRefund(true);
  }

  // 구독하기 클릭시 //
  function onClickSubscribeButton() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('zone_subscribe/check', 'GET');
        
        if (responseData.status === 200) setIsClickedSubscribe(true);
        else if (responseData.status === 201) {
          setBillingInfo(responseData.data[0]);
          setIsViewBillingInfoModal(true);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 계정정보 수정하기, 저장하기 클릭시 //
  function onClickModifyAccount() {
    if (isClickedModifyAccount) {
      // API Call //

      if (ownerName === '') {
        alert('관리자 성함을 정확하게 입력해주세요');
        return;
      }
      else if (phone === '' || phone.length !== 11) {
        alert('전화번호를 정확하게 입력해주세요');
        return;
      }

      var requestData = {
        // email: email,
        ownerName: ownerName,
        phone: phone,
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('account/info/modify', 'POST', requestData);
          
          if (responseData.status === 200) setData(responseData.data);
          else if (responseData.status === 401) history.push('/');
        }
      )();
      setIsClickedModifyAccount(false);
    }
    else {
      if (autoSave()) setIsClickedModifyAccount(true);
    }
  }

  // 센터 정보 저장하기 클릭시 //
  function onClickGymIntroSave() {
    // API Call //

    if (storeName === '') {
      alert('관리자 성함을 정확하게 입력해주세요');
      return;
    }
    else if (tel === '') {
      alert('전화번호를 정확하게 입력해주세요');
      return;
    }
    else if (type === '') {
      alert('센터 타입을 선택해주세요');
      return;
    }

    var requestData = {
      centerName: storeName,
      tel: tel,
      type: type,
      address: address,
      openingHour: openingHour,
      isUsePaymentSystem: isUsePaymentSystem,
      introduce: storeIntro,
      logo: storeLogo,
      logoThumbnail: storeLogoThumbnail 
    }
    // console.log("????");
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('account/center_info/modify', 'POST', requestData);

        if (responseData.status === 200) {
          setData(responseData.data);
          setIsClickedModifyGymInfo(false);
        }
        else if (responseData.status === 201) {
          alert('결제 시스템 사용은 승인된 업체만 가능합니다.\n사용을 원하시면 관리자에게 문의 부탁드립니다.');
          setIsUsePaymentSystem('No');
          return;
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 데이터 수정중 다른 컴포넌트 수정하기 버튼 클릭시 자동 저장 //
  function autoSave() {
    // 계정 정보 수정중 다른 컴포넌트 수정 클릭시 //
    if (isClickedModifyAccount) {
      if (ownerName === '') {
        alert('관리자 성함을 정확하게 입력해주세요');
        return false;
      }
      else if (phone === '' || phone.length !== 11) {
        alert('전화번호를 정확하게 입력해주세요');
        return false;
      }

      var requestData = {
        // email: email,
        ownerName: ownerName,
        phone: phone,
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('account/info/modify', 'POST', requestData);
          
          if (responseData.status === 200) setData(responseData.data);
          else if (responseData.status === 401) history.push('/');
        }
      )();

      setIsClickedModifyAccount(false);

      return true;
    }
    // 센터 정보 수정중 다른 컴포넌트 수정 클릭시 //
    else if (isClickedModifyGymInfo) {

      if (storeName === '') {
        alert('관리자 성함을 정확하게 입력해주세요');
        return false;
      }
      else if (tel === '') {
        alert('전화번호를 정확하게 입력해주세요');
        return false;
      }
      else if (type === '') {
        alert('센터 타입을 선택해주세요');
        return false;
      }
  
      var requestData = {
        centerName: storeName,
        tel: tel,
        type: type,
        address: address,
        openingHour: openingHour,
        isUsePaymentSystem: isUsePaymentSystem,
        introduce: storeIntro,
        logo: storeLogo,
        logoThumbnail: storeLogoThumbnail
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('account/center_info/modify', 'POST', requestData);
          
          if (responseData.status === 200) {
            setData(responseData.data);
            setIsClickedModifyGymInfo(false);
          }
          else if (responseData.status === 201) {
            alert('결제 시스템 사용은 승인된 업체만 가능합니다.\n사용을 원하시면 관리자에게 문의 부탁드립니다.');
            setIsUsePaymentSystem('No');
            return;
          }
          else if (responseData.status === 401) history.push('/');
          else {
            setIsClickedModifyGymInfo(false);
          }
        }
      )();

      return true;
    }
    else return true;
  }

  // 센터 정보 수정하기 클릭시 //
  function onClickModifyCenterInfo() {
    // if (autoSave()) setIsClickedModifyGymInfo(true);
    if (!isClickedModifyGymInfo) {
      if (autoSave()) setIsClickedModifyGymInfo(true);
    }
    else {
      onClickGymIntroSave();
    }
  }

  // 운동 프로그램 팔로우 신청 클릭시 //
  function onClickExerciseFollowApplicateButton(data, isFollow) {
    // 팔로우 창에서 실행된 경우 //
    if (isFollow) {
      if (data.status === undefined) {
        let requestData = {
          followStoreId: data.storeId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/application', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청이 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          shareId: data.shareId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청취소가 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
    // 팔로워 창에서 실행된 경우 //
    else {
      if (!data.status) {
        let requestData = {
          shareId: data.shareId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/apply', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 승인이 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          shareId: data.shareId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 취소가 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
  }

  // 파트너 체육관 팔로우 신청 클릭시 //
  function onClickPartnerGymFollowApplicateButton(data, isFollow) {
    // 팔로우 창에서 실행된 경우 //
    if (isFollow) {
      if (data.status === undefined) {
        let requestData = {
          followStoreId: data.storeId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/application', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청이 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          partnerId: data.partnerId
        }
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청취소가 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
    // 팔로워 창에서 실행된 경우 //
    else {
      if (!data.status) {
        let requestData = {
          partnerId: data.partnerId
        }
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/apply', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 승인이 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          partnerId: data.partnerId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 취소가 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
  }

  // 계약서 수정하기 클릭시 //
  function onClickModifyTerms() {
    if (!isClickedModifyTerms) setIsClickedModifyTerms(true);
    else {
      let tempTerms = terms.slice();
      let statuses = [];

      tempTerms.map((data) => {
        statuses.push({id: data.id, status: data.status});
      });

      let statusCount = 0;

      statuses.map((data) => {
        if (data.status) statusCount += 1;
      })

      // 2개 이상 켜져있으면 저장 X //
      if (statusCount > 1) {
        setAlertTitle('약관 활성화');
        setAlertText('약관 활성화는 1개만 가능합니다');
        setIsViewAlerModal(true);
        setIsClickedDeleteProvision(false);
  
        return;
      }

      // setIsSavingProvision(true);

      let requestData = {
        statuses: statuses
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('terms/state', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsClickedModifyTerms(false);
            // setIsSavingProvision(true);
          }
          else {
            setAlertTitle('약관 내용 저장');
            setAlertText('약관 내용저장에 실패했습니다. 잠시 후 다시시도해주세요');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            // setIsSavingProvision(false);
          }
        }
      )();
    }
  }

  // 규정 수정하기 클릭시 //
  function onClickModifyProvision(data) {
    setClickedProvision(data);
    setIsClickedEditProvision(true);
  }

  // 규정 저장하기 클릭시 //
  function onClickSaveProvision(data) {
    if (data.title === '') {
      alert('저장할 규정의 제목을 입력해주세요');
      return;
    }
    else if (data.text === '') {
      alert('규정의 내용을 입력해주세요');
      return;
    }
    else if (isSavingProvision) {
      alert('규정의 저장을 진행중입니다');
      return;
    } 

    setIsSavingProvision(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('terms/provision/save', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          setIsClickedEditProvision(false);
          setTerms(responseData.data.terms);
          setProvisions(responseData.data.provisions);
          setIsSavingProvision(false);
          setClickedProvision(undefined);
        }
        else {
          alert('규정 저장에 실패하였습니다\n잠시 후 다시시도해주세요');
          setIsSavingProvision(false);
        }
      }
    )();
  }
  
  // 규정 닫기 클릭시 //
  function onClickExitProvision() {
    setClickedProvision(undefined);
    setIsClickedEditProvision(false);
  }

  // 규정 삭제 클릭시 //
  function onClickDeleteProvision(data) {
    setClickedProvision(data);
    setIsClickedDeleteProvision(true);
  }
  
  // 규정 삭제 닫기 클릭시 //
  function onClickExitDeleteProvision(data) {
    setClickedProvision(undefined);
    setIsClickedDeleteProvision(false);
  }

  // 삭제하기 클릭시 //
  function onClickExcuteDeleteProvision() {
    if (clickedProvision.isDefault) {
      setAlertTitle('규정 삭제하기');
      setAlertText('"기본 규정"은 삭제가 불가능합니다');
      setIsViewAlerModal(true);
      setIsClickedDeleteProvision(false);

      return;
    }
    else if (isSavingProvision) {
      alert('삭제를 진행중입니다');
      return;
    }
    else {
      setIsSavingProvision(true);

      let requestData = {
        id: clickedProvision.id
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('terms/provision/delete', 'POST', requestData);
          
          if (responseData.status === 200) {
            setTerms(responseData.data.terms);
            setProvisions(responseData.data.provisions);
            setIsSavingProvision(false);
            setClickedProvision(undefined);
            setIsClickedDeleteProvision(false);
            setIsSavingProvision(false);
          }
          else if (responseData.status === 201) {
            setAlertTitle('규정 삭제하기');
            setAlertText('이미 삭제된 규정입니다');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            setIsSavingProvision(false);
          }
          else if (responseData.status === 203) {
            setAlertTitle('규정 삭제하기');
            setAlertText('"기본 규정"은 삭제가 불가능합니다');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            setIsSavingProvision(false);
          }
          else {
            setAlertTitle('규정 삭제하기');
            setAlertText('규정 삭제에 실패하였습니다');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            setIsSavingProvision(false);
          }
        }
      )();
    }
  }

  // 약관 보기 클릭시 //
  function onClickViewTerms(data) {
    setClickedTerms(data);
    setIsClickedViewTerms(true);
  }

  // 약관 닫기 클릭시 //
  function onClickExitViewTerms() {
    setClickedTerms(undefined);
    setIsClickedViewTerms(false);
  }

  // 약관 추가하기 클릭시 //
  function onClickEditTerms(data) {
    setClickedTerms(data);
    setIsClickedEditTerms(true);
  }

  // 약관 닫기 클릭시 //
  function onClickExitEditTerms() {
    setClickedTerms(undefined);
    setIsClickedEditTerms(false);
  }

  // 약관 저장하기 클릭시 //
  function onClickSaveTerms(data) {
    if (data.title === '') {
      alert('계약서 템플릿 이름을 입력해주세요');
      return;
    }
    else if (data.provisionKeys.length === 0) {
      alert('약관의 규정을 설정해주세요');
      return;
    }
    else if (isSavingTerms) {
      alert('약관의 저장을 진행중입니다');
      return;
    } 

    setIsSavingTerms(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('terms/save', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          setTerms(responseData.data.terms);
          setProvisions(responseData.data.provisions);
          setIsSavingTerms(false);
          setClickedTerms(undefined);
          setIsClickedEditTerms(false);
        }
        else {
          alert('약관 저장에 실패하였습니다\n잠시 후 다시시도해주세요');
          setIsSavingTerms(false);
        }
      }
    )();
  }
  
  // 약관 삭제하기 클릭시 //
  function onClickDeleteTerms(data) {
    setClickedTerms(data);
    setIsClickedDeleteTerms(true);
  }
  
  // 약관 삭제 닫기 클릭시 //
  function onClickExitDeleteTerms() {
    setClickedTerms(undefined);
    setIsClickedDeleteTerms(false);
  }

  // 약관 삭제하기 클릭시 //
  function onClickExcuteDeleteTerms() {
    if (isSavingTerms) {
      alert('삭제를 진행중입니다');
      return;
    }
    else {
      setIsSavingTerms(true);

      let requestData = {
        id: clickedTerms.id
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('terms/delete', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setTerms(responseData.data.terms);
            setProvisions(responseData.data.provisions);
            setIsSavingTerms(false);
            setClickedTerms(undefined);
            setIsClickedDeleteTerms(false);
            setIsSavingProvision(false);
          }
          else if (responseData.status === 201) {
            setAlertTitle('약관 삭제하기');
            setAlertText('이미 삭제된 약관입니다');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            setIsSavingTerms(false);
          }
          else if (responseData.status === 202) {
            setAlertTitle('약관 삭제하기');
            setAlertText('잛못된 권한의 접근입니다');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            setIsSavingTerms(false);
          }
          else {
            setAlertTitle('약관 삭제하기');
            setAlertText('약관 삭제에 실패하였습니다');
            setIsViewAlerModal(true);
            setIsClickedDeleteProvision(false);
            setIsSavingTerms(false);
          }
        }
      )();
    }
  }

  // 약관 활성화 버튼 클릭시 //
  function onClickTermsStatus(index) {
    let newTerms = terms.slice();

    newTerms[index].status = !newTerms[index].status;

    setTerms(newTerms);
  }

  return (
    <Container>
      {isClickedSubscribe && <SubscribeModal
        name={productName}
        validity={productValidity}
        price={productPrice}
        merchantUid={productMerchantUid}
        setIsClicked={setIsClickedSubscribe} />}
      { isViewBillingInfoModal && <BillingInfoModal data={billingInfo} onClickExit={() => setIsViewBillingInfoModal(false)}/> }
      { isClickedRefund && <RefundModal refundData={refundData} rendering={() => setRendering(!rendering)} exitClick={() => setIsClickedRefund(false)} /> }
      { isClickedExportExcelModal && <MemberListExportModal onClickExit={() => setIsClickedExportExcelModal(false)} /> }
      {
        isClickedViewTerms && 
        <TermsViewModal 
          storeName={storeName}
          clickedTerms={clickedTerms}
          defaultProvisions={defaultProvisions} 
          provisions={provisions}
          onClickExit={onClickExitViewTerms} />
      }
      { 
        isClickedEditTerms &&
        <TermsEditModal
          storeName={storeName}
          clickedTerms={clickedTerms}
          defaultProvisions={defaultProvisions} 
          provisions={provisions}
          onClickExit={onClickExitEditTerms}
          onClickSave={onClickSaveTerms} /> 
      }
      { 
        isClickedEditProvision && 
        <ProvisionEditModal 
          data={clickedProvision} 
          onClickSave={onClickSaveProvision}
          onClickExit={onClickExitProvision}/> 
      }
      { 
        isClickedDeleteProvision && 
        <ProvisionDeleteModal
          onClickExit={onClickExitDeleteProvision}
          onClickExcuteDeleteProvision={onClickExcuteDeleteProvision}/> 
      }
      {
        isClickedDeleteTerms &&
        <TermsDeleteModal
          onClickExit={onClickExitDeleteTerms}
          onClickExcuteDeleteTerms={onClickExcuteDeleteTerms}/>
      }
      {
        isViewAlertModal && <TermsAlertModal title={alertTitle} text={alertText} onClickExit={() => setIsViewAlerModal(false)}/>
      }
      <MyStoreSettingTopModel onClickDownloadMemberList={setIsClickedExportExcelModal} onClickSubscribe={onClickSubscribeButton} />
      <MyAccountBox
        onClickModify={onClickModifyAccount}
        isClicked={isClickedModifyAccount}
        id={id} 
        setData={setData} 
        email={email} 
        owenrName={ownerName} 
        setOwnerName={setOwnerName} 
        phone={phone} 
        setPhone={setPhone}/>
      <MyStoreIntroductionBox
        isClicked={isClickedModifyGymInfo}
        onClickModifyCenterInfo={onClickModifyCenterInfo}
        
        data={data}
        storeLogo={storeLogo}
        setStoreLogo={setStoreLogo}
        storeLogoThumbnail={storeLogoThumbnail}
        setStoreLogoThumbnail={setStoreLogoThumbnail}
        storeName={storeName}
        setStoreName={setStoreName}
        tel={tel}
        setTel={setTel}
        type={type}
        setType={setType}
        address={address}
        setAddress={setAddress}
        openingHour={openingHour}
        setOpeningHour={setOpeningHour}
        isUsePaymentSystem={isUsePaymentSystem}
        setIsUsePaymentSystem={setIsUsePaymentSystem}
        storeIntro={storeIntro}
        setStoreIntro={setStoreIntro}
        onClickSave={onClickGymIntroSave} 
        
        profiles={profiles} 
        profileThumbnails={profileThumbnails}
        // setProfiles={setProfiles} 
        setData={setData} 
        autoSave={autoSave}/>
        
      {/* <CenterSubscriptionTermsEdit terms={terms} setTerms={setTerms}/> */}
      <TermContract 
        isClicked={isClickedModifyTerms}
        terms={terms} 
        onClickModifyTerms={onClickModifyTerms}
        provisions={provisions}
        defaultProvisions={defaultProvisions}
        
        onClickTermsStatus={onClickTermsStatus}

        onClickViewTerms={onClickViewTerms}
        onClickEditTerms={onClickEditTerms}
        onClickDeleteTerms={onClickDeleteTerms}
        
        // onClickMakeProvision={onClickMakeProvision}
        onClickModifyProvision={onClickModifyProvision}
        onClickDeleteProvision={onClickDeleteProvision}/>

      <ProgramFollowBox
        follows={exerciseFollows}
        followers={exerciseFollowers}
        onClickFollow={onClickExerciseFollowApplicateButton}/>

      <PartnerGymFollowBox
        follows={partnerGymFollows}
        followers={partnerGymFollowers} 
        onClickFollow={onClickPartnerGymFollowApplicateButton}/>

      <ZoneSubscribeBox subscribeList={subscribeList}/>

      {/* <CenterFollowView follows={exerciseFollows} onClickFollow={onClickExerciseFollowApplicateButton}/>
      <CenterFollowerView followers={exerciseFollowers} onClickFollow={onClickExerciseFollowApplicateButton}/>
      <PartnerGymFollowView follows={partnerGymFollows} onClickFollow={onClickPartnerGymFollowApplicateButton}/>
      <PartnerGymFollowerView followers={partnerGymFollowers} onClickFollow={onClickPartnerGymFollowApplicateButton}/> */}
      {/* <MyZoneSubscribeInfo subscribeList={subscribeList} /> */}
      {/* <MyZoneMemberPaymentView salesList={salesList} onClickRefundButton={onClickRefundButton} /> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
  width: calc(100% - 28px);
`;