import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

export default function LockerDivisionSelectBox(props) {
  const { options, defaultVal, setDefaultVal } = props;

	const [isClicked, setIsClicked] = useState(false);

	const TopLevelRef = useRef();
	const SelectRef = useRef()

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
				SelectRef.current.style.border = '0px solid #CBCBCB';
				SelectRef.current.style.height = `0px`;
				SelectRef.current.style.overflow = 'hidden';
        setIsClicked(false);
    }
  }

	function onClick() {
    if (!options[0]) return;
		
		if (isClicked) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `${32 * (options.length)}px`;
			SelectRef.current.style.maxHeight = '130px';
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
      <ViewBox
        isClicked={isClicked}
        buttonPath={isClicked ? SelectOnPath : SelectOffPath}
        onClick={onClick}>
          <ViewText>{defaultVal.name}</ViewText>
      </ViewBox>
      <SelectBox
        ref={SelectRef}
        isClicked={isClicked}
        buttonPath={isClicked ? SelectOnPath : SelectOffPath}
        onClick={onClick}>
        {
          isClicked ?
            options.map((data, index) =>
              <OptionWrapper key={index} onClick={() => optionalClick(index)}>
                <Option>{data.name}</Option>
              </OptionWrapper>
            )
            :
            <OptionWrapper>
              <Option>
                {
                  defaultVal.name
                }
              </Option>
            </OptionWrapper>
        }
			</SelectBox>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 208px;
	height: 32px;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

	width: 208px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 99% ${props => props.isClicked ? `3` : `55`}%;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

	:hover {
		cursor: pointer;
	}

  transition: all 0.3s ease-in-out;
`;

const ViewText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
  margin: 0 0 0 8px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  margin-top: 38px;

	width: 208px;
	height: 0px;

	border-radius: 8px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	margin: 6px 0 6px 8px;
`;