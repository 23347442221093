import { React } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

import FetchModule from '../Network/FetchModule';

function BottomInfo() {
  const history = useHistory();
  const nowPage = window.location.href;
  // const defaultURL = 'http://localhost:3000';
  const defaultURL = 'https://link-zone.io';
  
  if (nowPage === defaultURL
  || nowPage.includes(defaultURL + '/certification')
  || nowPage.includes(defaultURL + '/identifier')
  || nowPage.includes(defaultURL + '/permute')
  || nowPage.includes(defaultURL + '/full/board/')
  || nowPage.includes(defaultURL + '/full/board-test/')
  || nowPage.includes(defaultURL + '/payment')
  || nowPage.includes(defaultURL + '/find-id')
  || nowPage.includes(defaultURL + '/keypad')
  || nowPage.includes('/note')
  || nowPage.includes('/app')) return null;

  // 로그인 정보 변경 버튼 클릭시 //
  function onClickChangeAuthorizationButton() {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     var responseData = await fetchModule.postDataOnlyLoginChange('account/asp/login/change', 'POST');
    //     console.log(responseData.data[0])
    //     if (responseData.data[0].status === 200) {
		// 			window.localStorage.setItem('Token', 'Bearer ' + responseData.data[0].token);
		// 			window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.data[0].refreshToken);
		// 			window.localStorage.setItem('StoreId', responseData.data[0].storeId);
    //       window.location.reload();
    //     }
    //     // else if (responseData.status === 401) history.push('/');
    //   }
    // )();
  }

  return (
    <TopLevelWrapper>
      <InfoWrapper>
        <ButtonWrapper>
          <Button onClick={() => history.push('/rule/service')}>이용약관</Button>
          <Button onClick={() => history.push('/rule/location')}>위치정보</Button>
          <Button onClick={() => history.push('/rule/privacy')}>개인정보</Button>
          <Button onClick={() => history.push('/rule/promotion')}>프로모션</Button>
          <Button onClick={() => history.push('/rule/refund')}>환불규정</Button>
        </ButtonWrapper>
        <TextWrapper>
          <Text>회사명 : 주식회사 하이비스</Text>
          <Text>대표 : Brantley Lee</Text>
          <Text>사업자등록번호 : 237-81-03404</Text>
          <Text onClick={onClickChangeAuthorizationButton}>개인정보책임자 : 홍서윤</Text>
          <Text>통신판매업신고 : 제 2022-충북충주-0029 호</Text>
        </TextWrapper>
        <TextWrapper>
          <Text>EMAIL : help@link-soft.io</Text>
          <Text>TEL : 070-8028-3943</Text>
          <Text>주소 : 충청북도 충주시 연수동산로 38</Text>
        </TextWrapper>
        <ExplanationWrapper>
          <Explanation>ⓒ 2021. Link Zone Co. all rights reserved.</Explanation>
        </ExplanationWrapper>
      </InfoWrapper>
    </TopLevelWrapper>
  )
}

export default BottomInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 296px;

  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 44px;

  margin-bottom: 40px;
`;

const Button = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 16px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;

  margin-bottom: 16px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: #969696;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;

  margin-top: 20px;
`;

const Explanation = styled.p`
  font-family: Poppins-Regular;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;