import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import CalenderModal from '../NoteModel/CalenderModal';

import ClassAddModal from './ClassManageModal/ClassAddModal';
import BreakTimeSettingModal from './ClassManageModal/BreakTimeSettingModal';
import ReservationLockModal from './ClassManageModal/ReservationLockModal';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderHover from '../../../image/calendarH.png'
import TodayButton from '../../../image/TodayButton.png'

import DailyClass from './DailyClass';
import WeeklyClass from './WeeklyClass';
import MonthlyClass from './MonthlyClass';

import FetchModule from '../../Share/Network/FetchModule';
import LessonAllLockModal from './LessonAllLockModal';
import NewLessonLockAllModal from './ClassManageModal/NewLessonLockAllModal';
import WeeklyClassBox from './WeeklyClassBox';
import ClassDeleteModal from './ClassManageModal/ClassDeleteModal';
import BreakTimeDeleteModal from './ClassManageModal/BreakTimeDeleteModal';
import NewClassDeleteModal from './ClassManageModal/NewClassDeleteModal';

function ClassManageView() {
	const history = useHistory();

  const [date, setDate] = useState(moment());
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [rendering, setRendering] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [calenderClick, setCalenderClick] = useState(false);
  const [selectFormat, setSelectFormat] = useState('month');
  const [reserveClick, setReserveClick] = useState(false);
  const [useScheduleIndex, setUseScheduleIndex] = useState('-1');
  const [breakClick, setBreakClick] = useState(false);
  const [addClick, setAddClick] = useState(false);
  const [allReserveLockClick, setAllReserveLockClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allReserveLock, setAllReserveLock] = useState(false);
  const [allReserveLockText, setAllReserveLockText] = useState('예약 전체 잠금');
	const [scheduleData, setScheduleData] = useState([]);
	const [useScheduleData, setUseScheduleData] = useState([]);
  const [monthlyScheduleData, setMonthlyScheduleData] = useState([]);
  const [breakTimeData, setBreakTimeData] = useState([]);
  const [reserveData, setReserveData] = useState([]);
  const [isClickedDelete, setIsClickedDelete] = useState(false);
  const [isClickedBreakTimeDelete, setIsClickedBreakTimeDelete] = useState(false);
  const [deletedData, setDeletedData] = useState(undefined);
  const [deletedBreakTimeData, setDeletedBreakTimeData] = useState(undefined);
  const [isClickedDeletedInDetailModal, setIsClickedDeletedInDetailModal] = useState(false);

  const [lessonOptions, setLessonOptions] = useState([{ id : -1, name: '선택', time: 0, maxBookedPeople: 0 }]);

  // 스케줄 수정 및 삭제를 위한 변수
  const [scheduleId, setScheduleId] = useState(-1);

  // 수업 추가 모달 기본 세팅 변수 ( 수업추가 버튼이 아닌 빈 컬럼 눌렀을 경우 사용 ) //
  const [defaultClassTime, setDefaultClassTime] = useState('');
  const [defaultClassDate, setDefaultClassDate] = useState('');
  const [defaultApplyWeek, setDefaultApplyWeek] = useState('');

  const [standardDate, setStandardDate] = useState(moment(moment().format('YYYY.MM.01')));

  const [reserveDataReLoad, setReserveDataReLoad] = useState(false);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const CalenderButtonRef = useRef();
  const CalenderRef = useRef();
  const DailyRef = useRef();
  const WeeklyRef = useRef();
  const MonthlyRef = useRef();

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    
    setStandardDate(moment(date.format('YYYY.MM.01')));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 

	// Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getData('schedule/', 'GET');
        const responseData = await fetchModule.postData('schedule/', 'POST');
        // console.log(responseData);
        if (responseData.status === 200) setScheduleData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, rendering])

  // BreakTime Data API호출
  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getData('breaktime/', 'GET');
    //     console.log(responseData.data);
    //     if (responseData.status === 200) setBreakTimeData(responseData.data);
    //     else if (responseData.status === 401) history.push('/');
    //   }
    // )();

    let requestData = {
      classDate: date.clone().format('YYYY-MM-DDT00:00:00')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('breaktime/', 'POST', requestData);
        
        if (responseData.status === 200) setBreakTimeData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, rendering])

  // 수업 데이터 받아오기 //
  useEffect(() => {
    setLessonOptions([]);

    const fetchModule = new FetchModule();
    (
      async () => {
        const data = await fetchModule.getDataVersion2('lesson/zone/light', 'GET');
        
        if (data.status === 200) {
					let newOptions = [];

					newOptions.push({ id : -1, name: '선택' });
					newOptions = newOptions.concat(data.data);
          
					setLessonOptions(newOptions);
				}
        else if (data.status === 401) history.push('/');
      }
    )();
  }, [history])

  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    // setIsLoading(true);

    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {
        var endPoint = moment(tmp[i].classDate.split('T')[0]);

        // if (moment(tmp[i].endDate).isAfter(date.clone())) {
        //   endPoint = date.clone().subtract(30, 'days');
        //   console.log(endPoint.format('YYYY-MM-DD'));
        // }
        // while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
        while (endPoint.isBefore(moment(tmp[i].endDate))) {
          // console.log("-------------------------------------------");
          // console.log("endPoint : " + endPoint.format('YYYY-MM-DD'));
          // console.log("today : " + date.format("YYYY-MM-DD"));
          // console.log("weekDay : " + getWeekNumber(endPoint.format('YYYY-MM-DD')));
          // console.log(tmp[i]);
          // console.log(endPoint.diff(date, 'days') >= 30);

          if (endPoint.diff(date, 'days') >= 30) break;

          if (tmp[i].repeatWeek.includes(getWeekNumber(endPoint.format('YYYY-MM-DD')).toString()) || tmp[i].repeatWeek.includes('0')) {

            if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
              var colSpan = 1;
              var rowSpan = 1;

              // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
              colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

              var newData = {
                classId: tmp[i].classId,
                classDate: endPoint.format('YYYY.MM.DD'),
                className: tmp[i].className,
                classTime: tmp[i].classTime,
                color: tmp[i].color,
                endDate: tmp[i].endDate,
                lessonId: tmp[i].lessonId,
                scheduleId: tmp[i].scheduleId,
                teacherName: tmp[i].teacherName,
                applyWeek: tmp[i].applyWeek,
                repeatWeek: tmp[i].repeatWeek,
                rowSpan: rowSpan,
                colSpan: colSpan,
                maxBookedPeople: tmp[i].maxBookedPeople,
                deduction: tmp[i].deduction
              }
              newScheduleData.push(newData);
            }
          }

          endPoint = endPoint.clone().add(1, 'day');
        }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })
    
    setMonthlyScheduleData(newScheduleData);

    // for (let i = 0; i < newScheduleData.length; i++) {
    //   if (newScheduleData[i].scheduleId === 665) console.log(newScheduleData[i]);
    // }

    var newData = [];

    for (i = 0; i < newScheduleData.length; i++) {

      if (newScheduleData[i].classDate === date.clone().format('YYYY.MM.DD')) {
        newData.push(newScheduleData[i]);
      }
    }
    
    
    // breaktime 데이터 가공
    for (var z = 0; z < breakTimeData.length; z++) {
      if (moment(breakTimeData[z].classDate).isBefore(date.clone()) && moment(breakTimeData[z].endDate).isAfter(date.clone())) {
        if (breakTimeData[z].repeatWeek.includes(getWeekNumber(date.clone().format('YYYY-MM-DD')).toString()) || breakTimeData[z].repeatWeek.includes('0')) {
          //   console.log(breakTimeData[z].repeatWeek);
          //   console.log(date)
            if (breakTimeData[z].applyWeek.includes(date.clone().format('ddd')) || breakTimeData[z].applyWeek === 'All') {
    
              var breakData = {
                breakTimeId: breakTimeData[z].breakTimeId,
                classId: -1,
                lessonId: -1,
                classDate: date.clone().format('YYYY.MM.DD'),
                color: '#ECECEC',
                classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
                className: '브레이크 타임',
                // className: 'Open Gym',
                applyWeek: breakTimeData[z].applyWeek
              }
    
              newData.push(breakData);
            }
          }
      }
    }
    
    newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      var splitTmp = tmp[0].split(':');
      var Time = '';

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
      }

      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }
    
    var newReserve = [];

    for (var h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }
    
    // console.log(newData);
    setReserveData(newReserve);
    setUseScheduleData(newData);
  }, [scheduleData, breakTimeData, date])
  
  // ReservationData
  useEffect(() => {
    // if (useScheduleData.length === 0) return;
    // var dataList = [];

    // for (var i = 0; i < useScheduleData.length; i++) {
    //   var data = {
    //     lessonId: useScheduleData[i].lessonId,
    //     classDate: useScheduleData[i].classDate.replaceAll('.', '-') + 'T00:00:00',
    //     classTime: useScheduleData[i].classTime
    //   }
    //   dataList.push(data);
    // }
    
    // // console.log(dataList);
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postData('reserve/list', 'POST', dataList);
    //     console.log(responseData);
    //     if (responseData.status === 200) setReserveData(responseData.data);
    //     // else if (responseData.status === 401) history.push('/');
    //   }
    // )();

    // if (useScheduleData.length === 0) return;
    
    let schedules = useScheduleData.slice();
    let scheduleIds = [];

    for (let i = 0; i < schedules.length; i++) {
      scheduleIds.push(schedules[i].scheduleId);
    }

    let undefinedCount = 0;

    for (let i = 0 ; i < schedules.length; i++) {
      if (scheduleIds[i] === undefined) undefinedCount += 1; 
    }

    if (scheduleIds.length === undefinedCount) return;

    // 조회할 데이터가 있을경우 //
    if (scheduleIds.length > 0) {
      let requestData = {
        scheduleIds: scheduleIds,
        classDate: schedules[0].classDate.replaceAll('.', '-') + 'T00:00:00'
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/status/check', 'POST', requestData);
          
          if (responseData.status === 200) {
            setAllReserveLock(!responseData.data.isAllLock);
            setReserveData(responseData.data.status);
          }
          // else if (responseData.status === 201) setAllReserveLock(true);
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 조회할 데이터가 없을경우 //
    else setAllReserveLock(false);
  }, [useScheduleData, reserveDataReLoad])

  // Schedule 예약 전체 잠금상태에 따라 버튼 텍스트 변경
  useEffect(() => {
    if (allReserveLock) setAllReserveLockText('예약 전체 잠금');
    else setAllReserveLockText('전체 잠금 해제')
  }, [allReserveLock])

  // useEffect(() => {
  //   if (selectFormat === 'day') {
  //     DailyRef.current.visible();
  //     WeeklyRef.current.unVisible();
  //     MonthlyRef.current.unVisible();
  //   }
  //   else if (selectFormat === 'week') {
  //     DailyRef.current.unVisible();
  //     WeeklyRef.current.visible();
  //     MonthlyRef.current.unVisible();
  //   }
  //   else if (selectFormat === 'month') {
  //     // DailyRef.current.unVisible();
  //     // WeeklyRef.current.unVisible();
  //     // MonthlyRef.current.visible();
  //     DailyRef.current.unVisible();
  //     WeeklyRef.current.visible();
  //     MonthlyRef.current.unVisible();
  //   }
  // }, [selectFormat])

  useEffect(() => {
    if (selectFormat === 'day') setClickDate(date.format('YYYY.MM.DD'));
  }, [date, selectFormat])

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }

  function dateClick(value) {
    // setClickDate(value.format('YYYY.MM.DD'));
    setClickDate(value);
    setDate(moment(value));

    if (calenderClick) CalenderRef.current.setValue(value);
  }

  function openReserveLockModal(index) {
    setUseScheduleIndex(index);
    setReserveClick(true);
  }

  function addButtonClick() {
    setAddClick(false);
    setRendering(!rendering);
    setDeletedData(undefined);
    setScheduleId(-1);

    setDefaultClassTime('');
    setDefaultClassDate('');
    setDefaultApplyWeek('');
  }

  function breakButtonClick() {
    setBreakClick(false);
    setRendering(!rendering);
  }

  function reservationButtonClick() {
    setReserveClick(false);
    setRendering(!rendering);
  }

  // 수업 상세정보 또는 주간 스케줄표 클릭시
  function onClickScheduleDetail(scheduleId, deletedData) {
    setDeletedData(deletedData);
    setScheduleId(scheduleId);
    setAddClick(true);
  }

  // 날짜 이전 버튼 클릭시 //
  function onClickPrevDate() {
    setDate(date.clone().subtract(1, selectFormat));

    if (selectFormat === 'month') setIsLoading(true);
  }

  // 날짜 다음 버튼 클릭시 //
  function onClickNextDate() {
    setDate(date.clone().add(1, selectFormat));
    
    if (selectFormat === 'month') setIsLoading(true);
  }

  // 시간표에서 빈칸 클릭시 //
  function onClickPlusColumn(classDate, classTime, applyWeek) {
    setDefaultClassDate(classDate);
    setDefaultClassTime(classTime);
    setDefaultApplyWeek(applyWeek);
    setClickDate(classDate);
    
    setAddClick(true);
  }

  // 수업 삭제모달 켜기 //
  function onClickDeleteButtonInComponent(data, division) {
    if (data.breakTimeId === undefined) {

      if (division === 'DetailModal') {
        setAddClick(false);
        setIsClickedDeletedInDetailModal(true);
        setIsClickedDelete(true);
      }
      else {
        setDeletedData(data);
        setIsClickedDelete(true);
      }
    }
    else {
      setDeletedBreakTimeData(data);
      setIsClickedBreakTimeDelete(true);
    }
  }


  // 수업 내역 삭제모달 안에서 삭제 클릭시 //
  function onClickOnlyOneDeleteClass() {

    if (deletedData === undefined) {
      alert('삭제할 수업 일정을 선택해주세요');
      return;
    }
    
    let requestData = {
      scheduleId: deletedData.scheduleId,
      changeApplyWeek: moment(deletedData.classDate).format('ddd,'),
      deletedDate: moment(deletedData.classDate).format('YYYY-MM-DDT00:00:00')
    }
    
    // console.log('------OnlyOne------');
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('schedule/delete/only_one', 'POST', requestData);
        
        if (responseData.status === 200) {
          setDeletedData(undefined);
          setIsClickedDelete(false);
          setRendering(!rendering);
          setAddClick(false);
          setScheduleId(-1);
          setIsClickedDeletedInDetailModal(false);
        }
        else if (responseData.status === 401) history.push('/');
        else {
          alert('삭제에 실패하였습니다.\n잠시 후 다시시도해주세요');
          setIsClickedDelete(false);
          setIsClickedDeletedInDetailModal(false);
          return;
        }
      }
    )();

    setIsClickedDelete(false);
  }

  // 수업 내역 삭제모달 안에서 삭제 클릭시 //
  function onClickDeleteClass() {

    if (deletedData === undefined) {
      alert('삭제할 수업 일정을 선택해주세요');
      return;
    }
    
    let requestData = {
      scheduleId: deletedData.scheduleId,
      changeApplyWeek: moment(deletedData.classDate).format('ddd,'),
      deletedDate: moment(deletedData.classDate).format('YYYY-MM-DDT00:00:00')
    }
    
    // console.log('------All------');
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('schedule/delete', 'POST', requestData);
        // console.log(responseData);

        if (responseData.status === 200) {
          setDeletedData(undefined);
          setIsClickedDelete(false);
          setRendering(!rendering);
          setAddClick(false);
          setScheduleId(-1);
          setIsClickedDeletedInDetailModal(false);
        }
        else if (responseData.status === 401) history.push('/');
        else {
          alert('삭제에 실패하였습니다.\n잠시 후 다시시도해주세요');
          setIsClickedDelete(false);
          setIsClickedDeletedInDetailModal(false);
          return;
        }
      }
    )();

    setIsClickedDelete(false);
  }

  // 삭제 경고 모달 닫기 버튼 클릭시 //
  function onClickDeleteModalClose() {
    if (isClickedDeletedInDetailModal) {
      setIsClickedDelete(false);
      setAddClick(true);
    }
    else {
      setDeletedData(undefined);
      setIsClickedDelete(false);
      setIsClickedDeletedInDetailModal(false);
    }
  }

  // 브레이크 타임 삭제 모달 내에서 삭제 클릭시 //
  function onClickDeleteBreakTime() {
    if (deletedBreakTimeData === undefined) {
      alert('삭제할 브레이크 타임 일정을 선택해주세요');
      return;
    }
    
    let requestData = {
      breakTimeId: deletedBreakTimeData.breakTimeId,
      changeApplyWeek: moment(deletedBreakTimeData.classDate).format('ddd,'),
      deletedDate: moment(deletedBreakTimeData.classDate).format('YYYY-MM-DDT00:00:00')
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('breaktime/delete', 'POST', requestData);
        
        if (responseData.status === 200) {
          setDeletedBreakTimeData(undefined);
          setIsClickedBreakTimeDelete(false);
          setRendering(!rendering);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();

    setIsClickedBreakTimeDelete(false);
  }

  // 브레이크 타임 삭제 경고 모달 내에서 닫기 버튼 클릭시 //
  function onClickDeleteBreakTimeModalClose() {
    setDeletedBreakTimeData(undefined);
    setIsClickedBreakTimeDelete(false);
  }

  return (
    <TopLevelWrapper>
      { calenderClick && <CalenderModal ref={CalenderRef} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick}/>}
      { reserveClick && <ReservationLockModal data={useScheduleData[useScheduleIndex]} cancel={() => reservationButtonClick()}/> }
      { breakClick && <BreakTimeSettingModal date={date} cancel={() => breakButtonClick()}/> }
      { addClick && 
        <ClassAddModal 
          date={moment(clickDate)} 
          scheduleData={scheduleData} 
          scheduleId={scheduleId} 
          onClickDeleteButton={onClickDeleteButtonInComponent}
          cancel={() => addButtonClick()} 
          options={lessonOptions}
          defaultClassTime={defaultClassTime}
          defaultClassDate={defaultClassDate}
          defaultApplyWeek={defaultApplyWeek}/> 
      }
      {/* { allReserveLockClick && <LessonAllLockModal date={date} allReserveLock={allReserveLock} setAllReserveLock={setAllReserveLock} cancel={() => setAllReserveLockClick(false)}/> } */}
      { allReserveLockClick && <NewLessonLockAllModal allReserveLock={allReserveLock} useScheduleData={useScheduleData} rendering={rendering} setRendering={setRendering} cancel={() => setAllReserveLockClick(false)}/> }
      {/* { isClickedDelete && <ClassDeleteModal onClickExit={onClickDeleteModalClose} onClickDelete={onClickDeleteClass}/> } */}
      { isClickedBreakTimeDelete && <BreakTimeDeleteModal onClickExit={onClickDeleteBreakTimeModalClose} onClickDelete={onClickDeleteBreakTime} /> }
      { isClickedDelete && <NewClassDeleteModal deletedData={deletedData} onClickExit={onClickDeleteModalClose} onClickOnlyOneDelete={onClickOnlyOneDeleteClass} onClickAllDelete={onClickDeleteClass}/> }
      <TopBarWrapper>
        <TextWrapper>
          <Title>수업 관리</Title>
          <Explanation>수업 일정을 확인하고 추가할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper>
        <LeftWrapper>
          <DateButtonWrapper>
            <ArrowWrapper onClick={onClickPrevDate} >
              <ArrowImage src={LeftArrow}/>
            </ArrowWrapper>
            <DateText>{selectFormat === 'day' ? date.clone().format('YYYY년 MM월 DD일 (dd)') : date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper onClick={onClickNextDate}>
              <ArrowImage src={RightArrow}/>
            </ArrowWrapper>
          </DateButtonWrapper>
          { selectFormat === 'day' && <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)}/>}
          <Button onClick={() => setDate(moment())}>
            <TodayImage src={TodayButton}/>
          </Button>
          <Button isClicked={selectFormat === 'month'} onClick={() => setSelectFormat('month')}>주</Button>
          <Button isClicked={selectFormat === 'day'} onClick={() => setSelectFormat('day')}>일</Button>
          {/* <Button isClicked={selectFormat === 'month2'} onClick={() => setSelectFormat('month2')}>월</Button> */}
        </LeftWrapper>
        <RightWrapper>
          {
            selectFormat === 'day' && <ReserveLockButton onClick={() => setAllReserveLockClick(true)}>{allReserveLockText}</ReserveLockButton>
          }
          {/* <BreakTimeSettingButton onClick={() => setBreakClick(true)}>브레이크타임 설정</BreakTimeSettingButton> */}
          <ClassAddButton onClick={() => setAddClick(true)}>수업 추가</ClassAddButton>
        </RightWrapper>
      </TopButtonWrapper>
      {/* <BoxView> */}
      {
        selectFormat === 'day' &&
        <DailyClass 
          ref={DailyRef} 
          today={date}
          useScheduleData={useScheduleData} 
          breakTimeData={breakTimeData}
          reserveData={reserveData} 
          reserveDataReLoad={reserveDataReLoad} 
          setClickDate={setClickDate}
          onClickScheduleDetail={onClickScheduleDetail}
          setReserveDataReLoad={setReserveDataReLoad} 
          openReserveLockModal={openReserveLockModal}/>
      }
      {
        selectFormat === 'month' &&
        <WeeklyClassBox 
          ref={WeeklyRef} 
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          scheduleData={scheduleData} 
          breakTimeData={breakTimeData} 
          today={date}
          standardDate={standardDate}
          setClickDate={setClickDate}
          onClickDeleteButton={onClickDeleteButtonInComponent}
          onClickScheduleDetail={onClickScheduleDetail}
          onClickPlusColumn={onClickPlusColumn}/>
      }
        {/* <WeeklyClass 
          ref={WeeklyRef} 
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          scheduleData={scheduleData} 
          breakTimeData={breakTimeData} 
          today={date}
          setClickDate={setClickDate}
          onClickScheduleDetail={onClickScheduleDetail}
          onClickPlusColumn={onClickPlusColumn}/> */}
      {/* { selectFormat === 'month2' && <MonthlyClass ref={MonthlyRef} useScheduleData={monthlyScheduleData} breakTimeData={breakTimeData} date={date}/> } */}
      {/* </BoxView> */}
    </TopLevelWrapper>
  )
}

export default ClassManageView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;

  // margin-left: 34px;
  margin-left: 42px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;
  width: 1020px;

  margin-top: 38px;
  margin-bottom: 16px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 189px;
  height: 38px;

  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  // gap: 37px;
  // gap: 8px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;

  // background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #777777`};
    color: ${props => props.isClicked ? `#FFFFFF` : `#777777`};
  }
`;

const ReserveLockButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 98px;
  width: 129px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;
  
  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const BreakTimeSettingButton = styled.div`
	display: flex;

	justify-content: center;
	align-items: center;

	width: 150px;	
	height: 38px;	

	border: 1px solid #DFDFDF;
	border-radius: 15px;
	background-color: #FFFFFF;
  
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	color: #CBCBCB;

	transition: all 0.3s ease-in-out;
	
	:hover {
		cursor: pointer;
		border:1px solid #777777;
		color: #777777;
	}
`;

const ClassAddButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;	
  height: 40px;

  border-radius: 15px;
  background-color: #58D98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const TodayImage = styled.img`
  width: 20px;
  height: 20px;
`;

const BoxView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 449px;
  // height: 505px;

  padding-top: 28px;
  padding-bottom: 28px;

  margin-top: 16px;
  margin-bottom: 96px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;