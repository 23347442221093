import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import AttendanceRankingMemberList from './AttendanceRankingMemberList';

import Attendance1ImagePath from '../../../image/Attendance1.png';
import Attendance2ImagePath from '../../../image/Attendance2.png';
import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import NotAvailableLeftArrow from '../../../image/NotAvailableLeft.png';
import NotAvailableRightArrow from '../../../image/NotAvailableRight.png';
import moment from 'moment';
import WeeklyAbsenteeMemberList from './WeeklyAbsenteeMemberList';
import NewDataBar from './NewDataBar';


export default function RevenueBox(props) {
  const {
    today,
    salesData,
    startDate,
    endDate,
    calcDateText,

    revenue,
    prevRevenue,
    memberships,

    attendanceTotal,
    attendanceRanking,
    totalMonthAttendanceCount,

    allMembers,
    selectedPartnerGym,

    isRevenueLoading,
    setIsRevenueLoading,

    isOpenPartnerGymSales,
    isWeekAttendanceLoading,
    setIsWeekAttendanceLoading,
    isMonthAttendanceLoading,
    setIsMonthAttendanceLoading
  } = props;


  const monthlyDate = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

  const [thisYearMaxPrice, setThisYearMaxPrice] = useState(0);
  const [thisYear, setThisYear] = useState([]);
  const [maximum, setMaximum] = useState(0);
  const [monthTotalPrice, setMonthTotalPrice] = useState([]);
  const [salesMemberships, setSalesMemberships] = useState([]);

  const [weeklyDate, setWeeklyDate] = useState([]);
  const [weeklyIndex, setWeeklyIndex] = useState(0);
  const [weeklyValidMemberCount, setWeeklyValidMemberCount] = useState(0);
  const [weeklyAttendanceCount, setWeeklyAttendanceCount] = useState(0);
  const [absentee, setAbsentee] = useState([]);


  useEffect(() => {
    let one = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let two = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let three = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let four = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let five = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let six = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let seven = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let eight = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let nine = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let ten = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let eleven = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };
    let twelve = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0, prevTotalPrice: 0, sales: [] };

    let prevOne = 0;
    let prevTwo = 0;
    let prevThree = 0;
    let prevFour = 0;
    let prevFive = 0;
    let prevSix = 0;
    let prevSeven = 0;
    let prevEight = 0;
    let prevNine = 0;
    let prevTen = 0;
    let prevEleven = 0;
    let prevTwelve = 0;

    let maxPrice = 0;

    for (let i = 0; i < revenue.length; i++) {
        if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '01') {
          one.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          one.cashPrice += revenue[i].cashPrice;
          one.cardPrice += revenue[i].cardPrice;
          one.notReceived += revenue[i].notReceivedPrice;
          one.sales.push(revenue[i]);

          if (one.cashPrice + one.cardPrice + one.notReceived > maxPrice) maxPrice = one.cashPrice + one.cardPrice + one.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '02') {
          two.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          two.cashPrice += revenue[i].cashPrice;
          two.cardPrice += revenue[i].cardPrice;
          two.notReceived += revenue[i].notReceivedPrice;
          two.sales.push(revenue[i]);

          if (two.cashPrice + two.cardPrice + two.notReceived > maxPrice) maxPrice = two.cashPrice + two.cardPrice + two.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '03') {
          three.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          three.cashPrice += revenue[i].cashPrice;
          three.cardPrice += revenue[i].cardPrice;
          three.notReceived += revenue[i].notReceivedPrice;
          three.sales.push(revenue[i]);

          if (three.cashPrice + three.cardPrice + three.notReceived > maxPrice) maxPrice = three.cashPrice + three.cardPrice + three.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '04') {
          four.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          four.cashPrice += revenue[i].cashPrice;
          four.cardPrice += revenue[i].cardPrice;
          four.notReceived += revenue[i].notReceivedPrice;
          four.sales.push(revenue[i]);

          if (four.cashPrice + four.cardPrice + four.notReceived > maxPrice) maxPrice = four.cashPrice + four.cardPrice + four.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '05') {
          five.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          five.cashPrice += revenue[i].cashPrice;
          five.cardPrice += revenue[i].cardPrice;
          five.notReceived += revenue[i].notReceivedPrice;
          five.sales.push(revenue[i]);

          if (five.cashPrice + five.cardPrice + five.notReceived > maxPrice) maxPrice = five.cashPrice + five.cardPrice + five.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '06') {
          six.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          six.cashPrice += revenue[i].cashPrice;
          six.cardPrice += revenue[i].cardPrice;
          six.notReceived += revenue[i].notReceivedPrice;
          six.sales.push(revenue[i]);

          if (six.cashPrice + six.cardPrice + six.notReceived > maxPrice) maxPrice = six.cashPrice + six.cardPrice + six.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '07') {
          seven.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          seven.cashPrice += revenue[i].cashPrice;
          seven.cardPrice += revenue[i].cardPrice;
          seven.notReceived += revenue[i].notReceivedPrice;
          seven.sales.push(revenue[i]);

          if (seven.cashPrice + seven.cardPrice + seven.notReceived > maxPrice) maxPrice = seven.cashPrice + seven.cardPrice + seven.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '08') {
          eight.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          eight.cashPrice += revenue[i].cashPrice;
          eight.cardPrice += revenue[i].cardPrice;
          eight.notReceived += revenue[i].notReceivedPrice;
          eight.sales.push(revenue[i]);

          if (eight.cashPrice + eight.cardPrice + eight.notReceived > maxPrice) maxPrice = eight.cashPrice + eight.cardPrice + eight.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '09') {
          nine.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          nine.cashPrice += revenue[i].cashPrice;
          nine.cardPrice += revenue[i].cardPrice;
          nine.notReceived += revenue[i].notReceivedPrice;
          nine.sales.push(revenue[i]);

          if (nine.cashPrice + nine.cardPrice + nine.notReceived > maxPrice) maxPrice = nine.cashPrice + nine.cardPrice + nine.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '10') {
          ten.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          ten.cashPrice += revenue[i].cashPrice;
          ten.cardPrice += revenue[i].cardPrice;
          ten.notReceived += revenue[i].notReceivedPrice;
          ten.sales.push(revenue[i]);

          if (ten.cashPrice + ten.cardPrice + ten.notReceived > maxPrice) maxPrice = ten.cashPrice + ten.cardPrice + ten.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '11') {
          eleven.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          eleven.cashPrice += revenue[i].cashPrice;
          eleven.cardPrice += revenue[i].cardPrice;
          eleven.notReceived += revenue[i].notReceivedPrice;
          eleven.sales.push(revenue[i]);

          if (eleven.cashPrice + eleven.cardPrice + eleven.notReceived > maxPrice) maxPrice = eleven.cashPrice + eleven.cardPrice + eleven.notReceived;
        }
        else if (revenue[i].approvalDate.split('T')[0].split('-')[1] === '12') {
          twelve.totalPrice += (revenue[i].cashPrice + revenue[i].cardPrice + revenue[i].notReceivedPrice);
          twelve.cashPrice += revenue[i].cashPrice;
          twelve.cardPrice += revenue[i].cardPrice;
          twelve.notReceived += revenue[i].notReceivedPrice;
          twelve.sales.push(revenue[i]);

          if (twelve.cashPrice + twelve.cardPrice + twelve.notReceived > maxPrice) maxPrice = twelve.cashPrice + twelve.cardPrice + twelve.notReceived;
        }
    }

    for (let i = 0; i < prevRevenue.length; i++) {
      if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '01') prevOne = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '02') prevTwo = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '03') prevThree = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '04') prevFour = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '05') prevFive = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '06') prevSix = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '07') prevSeven = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '08') prevEight = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '09') prevNine = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '10') prevTen = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '11') prevEleven = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
      else if (prevRevenue[i].approvalDate.split('T')[0].split('-')[1] === '12') prevTwelve = prevRevenue[i].cashPrice + prevRevenue[i].cardPrice;
    }

    let newData = [];

    one.prevTotalPrice = prevOne;
    two.prevTotalPrice = prevTwo;
    three.prevTotalPrice = prevThree;
    four.prevTotalPrice = prevFour;
    five.prevTotalPrice = prevFive;
    six.prevTotalPrice = prevSix;
    seven.prevTotalPrice = prevSeven;
    eight.prevTotalPrice = prevEight;
    nine.prevTotalPrice = prevNine;
    ten.prevTotalPrice = prevTen;
    eleven.prevTotalPrice = prevEleven;
    twelve.prevTotalPrice = prevTwelve;

    newData.push(one);
    newData.push(two);
    newData.push(three);
    newData.push(four);
    newData.push(five);
    newData.push(six);
    newData.push(seven);
    newData.push(eight);
    newData.push(nine);
    newData.push(ten);
    newData.push(eleven);
    newData.push(twelve);

    // console.log(one);
    // console.log(two);
    // console.log(three);
    // console.log(four);
    // console.log(five);
    // console.log(six);
    // console.log(seven);
    // console.log(eight);
    // console.log(nine);
    // console.log(ten);
    // console.log(eleven);
    // console.log(twelve);
    // console.log(newData);

    let totalPrice = 0;
    let totalSales = [];

    if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '01') {
      totalPrice = one.totalPrice;
      totalSales = one.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '02') {
      totalPrice = two.totalPrice;
      totalSales = two.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '03') {
      totalPrice = three.totalPrice;
      totalSales = three.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '04') {
      totalPrice = four.totalPrice;
      totalSales = four.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '05') {
      totalPrice = five.totalPrice;
      totalSales = five.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '06') {
      totalPrice = six.totalPrice;
      totalSales = six.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '07') {
      totalPrice = seven.totalPrice;
      totalSales = seven.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '08') {
      totalPrice = eight.totalPrice;
      totalSales = eight.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '09') {
      totalPrice = nine.totalPrice;
      totalSales = nine.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '10') {
      totalPrice = ten.totalPrice;
      totalSales = ten.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '11') {
      totalPrice = eleven.totalPrice;
      totalSales = eleven.sales;
    }
    else if (today.format('YYYY-MM-DDTHH:mm:ss').split('T')[0].split('-')[1] === '12') {
      totalPrice = twelve.totalPrice;
      totalSales = twelve.sales;
    }

    // for (let i = 0; i < totalSales.length; i++) {
    //   console.log(totalSales[i]);
    // }

    setThisYear(newData);
    setThisYearMaxPrice(maxPrice);
    setMaximum((parseInt(maxPrice) + parseInt(maxPrice * 0.1)));

    setTimeout(() => (
      setIsRevenueLoading(false)
    ), 1000)
  }, [revenue, prevRevenue, today])

  

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>{calcDateText() + ' 회원권별 판매 현황'}</Title>
        {
          isWeekAttendanceLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <MemberBox>
                      <MemberColumn>
                        <Text style={{ marginLeft: '36px', marginRight: `150px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>회원권</Text>
                        <Text style={{ marginRight: `19px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>회원권 가격</Text>
                        <Text style={{ marginRight: `29px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>총 판매 금액</Text>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>비중</Text>
                      </MemberColumn>
                      <MemberListView>
                        {
                          salesMemberships.map((data, index) => (
                            <EngagementMemberWrapper key={'EngagementCurrent-' + index}>
                              <EngagementTextBox width={59}>
                                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{data.userName}</Text>
                              </EngagementTextBox>
                              <EngagementTextBox width={34}>
                                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                              </EngagementTextBox>
                              <EngagementTextBox width={84}>
                                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{data.birthDay.slice(0, 4) + '.' + data.birthDay.slice(4, 6) + '.' + data.birthDay.slice(6, 8)}</Text>
                              </EngagementTextBox>
                              <EngagementTextBox width={150}>
                                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{}</Text>
                              </EngagementTextBox>
                              <EngagementTextBox width={72}>
                                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{moment(data.classDate).format('YYYY.MM.DD')}</Text>
                              </EngagementTextBox>
                            </EngagementMemberWrapper>
                          ))
                        }
                      </MemberListView>
                    </MemberBox>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>

      <BoxDivisionLine />

      <InfoBox>
        <Title>{today.clone().format('YYYY년')} 월 별 매출 비교</Title>
        {
          isMonthAttendanceLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <TotalChartWrapper>
                      <ChartWrapper>
                        <MonthlyDateWrapper>
                          {
                            monthlyDate.map((data, index) => (
                              <MonthlyDateText key={'MonthlyDateText-' + index}>{data}</MonthlyDateText>
                            ))
                          }
                        </MonthlyDateWrapper>
                        <ChartView>
                          {
                            thisYear.map((data, index) => (
                              <NewDataBar
                                key={'DateBar-' + index}
                                data={data}
                                index={index}
                                thisYear={thisYear}
                                maximum={maximum}
                                thisYearMaxPrice={thisYearMaxPrice}
                                totalMaxPrice={0} />
                            ))
                          }
                        </ChartView>
                      </ChartWrapper>
                    </TotalChartWrapper>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 448px;

  gap: 28px;
  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const BoxDivisionLine = styled.div`
  diplay: flex;
  flex-direction: row;

  width: 1px;
  height: 392px;

  background-color: #ECECEC;
  // border: 1px solid #ECECEC;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 44.5%;
  // height: 77.67%;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // width: 454px;
  // width: 44.5%;
  height: 364px;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  // height: 100%;
  height: 364px;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const MemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 454px;
  height: 318px;
`;

const MemberColumn = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  // width: calc(100% - 16px);
  width: 100%;
  height: 32px;

  border-radius: 8px 8px 0px 0px;
  background-color: #ECECEC;
`;

const MemberListView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 288px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #FFFFFF;
		border-radius: 200px;
	}
`;

const EngagementMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  gap: 8px;

  width: calc(100% - 16px);
  min-height: 36px;
  max-height: 36px;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;

    border-radius: 5px;
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const EngagementTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const TopUIWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

const WeekAttendanceIcon = styled.img`
  width: 48px;
  height: 48px;

  margin-right: 8px;
`;

const WeekAttendancePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  // width: 140px;
  height: 28px;

  border-radius: 8px;
  background-color: #6DD2A6;
`;

const MonthAverageAttendancePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  // width: 141px;
  height: 28px;

  border-radius: 8px;
  background-color: #FFA800;
`;

const PersentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 3px 0 0;
`;

const PersentNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  // width: 120px;
  width: ${props => props.width}px;
  height: 1px;

  margin: 0 16px 0 16px;
  border: 1px solid #ECECEC;
`;

const ResultBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 113px;
  width: ${props => props.width}px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const ResultText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ResultNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ListTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const ListTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  // margin: 24px 0 0 0;
  margin: 0 0 0 0;
`;

const WeeklySelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const WeeklySelectButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  border: 1px solid #DFDFDF;
  border-radius: 17px;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const WeeklySelectButtonImage = styled.img`
  width: 10px;
  height: 10px;

  object-fit: contain;

  :hover {
    cursor: pointer;
  }
`;


const PriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  gap: 4px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TotalChartWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  margin-top: 24px;

  // width: 526px;
  // height: 474px;
  width: 453px;
  height: 318px;

  border-top: 1px solid #DFDFDF;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 526px;
  // height: 450px;
  width: 100%;
  height: 304px;

  // margin-top: 24px;
  // border-top: 1px solid #DFDFDF;
`;

const MonthlyDateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-right: 21px;
  // gap: 18px;
  gap: 4px;
  // margin-right: 43px;
`;

const MonthlyDateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ChartView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 450px;
  // height: 440px;
  width: 377px;
  height: 304px;
  // height: 324px;

  border-left: 1px solid #CBCBCB;
  // border-bottom: 1px solid #CBCBCB;

  // background-color: red;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;