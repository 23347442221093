import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const MemberShipNameInput = forwardRef((props, ref) => {
	const { className, placeholder, type} = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return <Input
		ref={inputRef}
		clickCount={clickCount}
		type={type}
		placeholder={defaultPlaceholder}
		value={value}
		changedColor={changedPlaceholderColor}
		className={`${className} ${changed ? "changed" : ""}`}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} />
});

export default MemberShipNameInput;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  width: 336px;
  height: 34px;

	padding: 0 0 0 8px;
	margin-top: 8px;

	outline: none;
	border: 1px solid #DFDFDF;
  border-radius: 5px;
	color: #000000;

	::placeholder {
		color: #DFDFDF;
	}
	
  transition: all 0.5s ease-in-out;
`;