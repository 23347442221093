import { React, useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import FetchModule from '../../Share/Network/FetchModule';
import SecondPasswordInput from '../../../Component/SettingComponent/SecondPasswordInput';

export default function SecondAuthenticationBox(props) {
  const { isSuccessCheckSecondPassword, setIsSuccessCheckSecondPassword } = props;

  const [isInProgress, setIsInProgress] = useState(false);
  const [shaking, setShaking] = useState(false);

  // const [nowFocused, setNowFocused] = useState('');
 
  const FirstRef = useRef();
  const SecondRef = useRef();
  const ThirdRef = useRef();
  const FourseRef = useRef();

  // useEffect(() => {
  //   setTimeout(() => (
  //     FirstRef.current.focus()
  //   ), 250);
  // }, [])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {

        let fourse = FourseRef.current.getValue();
        let third = ThirdRef.current.getValue();
        let second = SecondRef.current.getValue();
        let first = FirstRef.current.getValue();

        if (fourse !== '') {
          FourseRef.current.setValue('');
          FourseRef.current.focus();
        }
        else if (third !== '') {
          ThirdRef.current.setValue('');
          ThirdRef.current.focus();
        }
        else if (second !== '') {
          SecondRef.current.setValue('');
          SecondRef.current.focus();
        }
        else if (first !== '') {
          FirstRef.current.setValue('');
          FirstRef.current.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [])

  useEffect(() => {
    if (!isSuccessCheckSecondPassword) {
      FourseRef.current.reset();
      ThirdRef.current.reset();
      SecondRef.current.reset();
      FirstRef.current.reset();
    }
  }, [isSuccessCheckSecondPassword])

  // 글자 입력시 //
  function onChangeValue(className, number) {
    if (className === 'First') SecondRef.current.focus();
    else if (className === 'Second') ThirdRef.current.focus();
    else if (className === 'Third') FourseRef.current.focus();
    else if (className === 'Fourse') {
      let first = FirstRef.current.getValue();
      let second = SecondRef.current.getValue();
      let third = ThirdRef.current.getValue();
      let fourse = number;
      
      if (first !== ''
        && second !== ''
        && third !== ''
        && fourse !== '') {
          onCheckSecondPassword(first + second + third + fourse);
        }
    }
  }

  // 2차 비밀번호 맞는지 확인
  function onCheckSecondPassword(secondPw) {
    if (isInProgress || isSuccessCheckSecondPassword) {
      return;
    }

    setIsInProgress(true);

    let requestData = {
      secondPw: secondPw
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('second_auth/check', 'POST', requestData);
        // console.log("-----Year Sales");
        // console.log(responseData);
        if (responseData.status === 200) {
          // setIsSuccess(true);
          // setIsFailed(false);

          setTimeout(() => {
            setIsSuccessCheckSecondPassword(true);
            setIsInProgress(false);
          }, 500)
        }
        else if (responseData.status === 201) {
          // setIsFailed(true);
          setShaking(true);

          setTimeout(() => {
            setShaking(false);
          }, 500)

          setTimeout(() => {  
            FourseRef.current.reset();
            ThirdRef.current.reset();
            SecondRef.current.reset();
            FirstRef.current.reset();
            FirstRef.current.focus();
            setIsInProgress(false);
          }, 1500)
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }



  return (
    <TopLevelWrapper>
      <PasswordInputWrapper shaking={shaking}>
        <SecondPasswordInput
          ref={FirstRef} 
          className='First' 
          onChangeValue={onChangeValue}/>
        <SecondPasswordInput 
          ref={SecondRef} 
          className='Second'
          onChangeValue={onChangeValue}/>
        <SecondPasswordInput 
          ref={ThirdRef} 
          className='Third'
          onChangeValue={onChangeValue}/>
        <SecondPasswordInput 
          ref={FourseRef} 
          className='Fourse'
          onChangeValue={onChangeValue}/>
      </PasswordInputWrapper>
    </TopLevelWrapper>
  )
}

const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  20%, 50%, 80% {
    transform: translateX(-4px);
  }
  40%, 60% {
    transform: translateX(4px);
  }
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 8px;

  transition: all 0.3s ease-in-out;
`;

const PasswordInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 314px;
  height: 40px;
  
  gap: 12px;

  animation: ${props => props.shaking ? shake : 'none'} 0.5s ease-in-out;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;