import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import GraphBox from './GraphBox';
import MemberMovementHistoryBox from './MemberMovementHistoryBox';
import NotReceivedHistory from './NotReceivedHistory';
import SalesHistory from './SalesHistory';
import DurationBox from './DurationBox';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import NotAvailableLeftArrow from '../../../image/NotAvailableLeft.png';
import NotAvailableRightArrow from '../../../image/NotAvailableRight.png';

import CalenderBasic from '../../../image/calendarB.png' //캘린더 기본
import CalenderHover from '../../../image/calendarH.png' //캘린더 기본
import CalenderModal from './CalenderModal';
import MaintainMemberAndNewMemberView from './MaintainMemberAndNewMemberView';
import NoticeKakaoBizView from './NoticeKakaoBizView';
import SalesCalendar from './SalesCalendar';
import MemberAttendanceBox from './MemberAttendanceBox';
import MessageSendHistoryView from './MessageSendHistoryView';
import MemberEngagementView from './MemberEngagementView';
import RevenueBox from './RevenueBox';

const StatisticsView = forwardRef((props, ref) => {
  const history = useHistory();

  const [today, setToday] = useState(moment());
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const [isClickedCalender, setIsClickedCalender] = useState(false);
  const [isReregistLoading, setIsReregistLoading] = useState(false);
  const [isAliveLoading, setIsAliveLoading] = useState(true);
  const [isExpiredLoading, setIsExpiredLoading] = useState(true);
  const [isFrequencyLoading, setIsFrequencyLoading] = useState(true);

  const [partnerGyms, setPartnerGyms] = useState([]);
  const [selectedPartnerGym, setSelectedPartnerGym] = useState(undefined);
  const [selectedPartnerGymIndex, setSelectedPartnerGymIndex] = useState(0);
  const [allMembers, setAllMembers] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [aliveData, setAliveData] = useState([]);
  const [frequencyData, setFrequencyData] = useState([]);
  const [bizMessageData, setBizMessageData] = useState([]);
  const [messageData, setMessageData] = useState([]);

  // const [salesTotal, setSalesTotal] = useState([]);
  const [priceAvg, setPriceAvg] = useState(0);
  const [durationMembers, setDurationMembers] = useState([]);
  const [durationAvg, setDurationAvg] = useState(0);

  const [leaveMemberData, setLeaveMemberData] = useState([]);
  const [aliveMemberData, setAliveMemberData] = useState([]);

  const [isOpenPartnerGymSales, setIsOpenPartnerGymSales] = useState(false);

  const [attendanceTotal, setAttendanceTotal] = useState([]);
  const [attendanceRanking, setAttendanceRanking] = useState([]);
  const [totalMonthAttendanceCount, setTotalMonthAttendanceCount] = useState(0);

  const [isLoadingAllMember, setIsLoadingAllMember] = useState(true);
  const [isDoughnutLoading, setIsDoughnutLoading] = useState(true);
  const [isSalesLoading, setIsSalesLoading] = useState(true);
  const [isYearLoading, setIsYearLoading] = useState(true);
  const [isRevenueLoading, setIsRevenueLoading] = useState(true);
  const [isDurationLoading, setIsDurationLoading] = useState(true);
  const [isBizMessageLoading, setIsBizMessageLoading] = useState(true);
  const [isMessageLoading, setIsMessageLoading] = useState(true);
  const [isWeekAttendanceLoading, setIsWeekAttendanceLoading] = useState(true);
  const [isMonthAttendanceLoading, setIsMonthAttendanceLoading] = useState(true);
  const [isEngagementLoading, setIsEngagementLoading] = useState(false);

  const [salesData, setSalesData] = useState([]);
  const [yearSalesData, setYearSalesData] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [prevRevenue, setPrevRevenue] = useState([]);

  const [engagementReserves, setEngagementReserves] = useState([]);
  const [isNotReceivedLoading, setIsNotReceivedLoading] = useState(true);
  const [notReceivedData, setNotReceivedData] = useState([]);

  const TopLevelRef = useRef();
  const CalenderButtonRef = useRef();

  useImperativeHandle(ref, () => ({
    setOpacity: (val) => {
      TopLevelRef.current.style.opacity = val;
    },
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
  }))

  // 파트너 체육관 리스트 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('partner_gym/statistics', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let partnerGyms = responseData.data.slice();

          setPartnerGyms(partnerGyms);

          if (partnerGyms.length > 0) {
            setSelectedPartnerGym(partnerGyms[0]);
            setSelectedPartnerGymIndex(0);
            setIsOpenPartnerGymSales(true);
          }
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [])

  // 조회 날짜 정리 //
  useEffect(() => {
    let startDate = today.format('YYYY-MM-') + '01T00:00:00';
    let endDate = moment(startDate).add(1, 'month').subtract(1, 'second');

    setStartDate(moment(startDate));
    // setEndDate(moment(endDate));

    if (moment().isAfter(endDate)) setEndDate(endDate);
    else setEndDate(moment(moment().format('YYYY-MM-DDT23:59:59')));
  }, [today])

  // 연단위 구매내역 조회 //
  useEffect(() => {
    
    let isLastest = true;

    setIsYearLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      setIsOpenPartnerGymSales(true);

      let requestData = {
        standardYear: today.format('YYYY') + '-01-01T00:00:00'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/history/year', 'POST', requestData);
          // console.log(responseData.data);
          if (responseData.status === 200) {
            if (isLastest) {
              setYearSalesData(responseData.data);
            }
          }
          else if (responseData.status !== 204) {
            if (isLastest) {
              setYearSalesData([]);
            }
          }
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        let requestData = {
          storeId: selectedPartnerGym.storeId,
          standardYear: today.format('YYYY') + '-01-01T00:00:00'
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('sales/history/year/partner_gym', 'POST', requestData);

            if (responseData.status === 200) {
              if (isLastest) {
                setYearSalesData(responseData.data);
              }
            }
            else if (responseData.status === 201) {
              if (isLastest) {
                setIsOpenPartnerGymSales(false);
                setYearSalesData([]);
              }
            }
            else if (responseData.status !== 204) {
              if (isLastest) {
                setYearSalesData([]);
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setYearSalesData([]);
          setIsOpenPartnerGymSales(false);
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, today])


  // 총 매출 분석 데이터 조회 //
  useEffect(() => {
    
    let isLastest = true;

    setIsRevenueLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      setIsOpenPartnerGymSales(true);

      let requestData = {
        standardYear: today.format('YYYY') + '-01-01T00:00:00'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/revenue/year', 'POST', requestData);
          console.log('1111111');
          console.log(responseData.data);
          if (responseData.status === 200) {
            if (isLastest) {
              setMemberships(responseData.data.memberships);
              setRevenue(responseData.data.sales);
            }
          }
          else if (responseData.status !== 204) {
            if (isLastest) {
              setMemberships([]);
              setRevenue([]);
            }
          }
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        let requestData = {
          storeId: selectedPartnerGym.storeId,
          standardYear: today.format('YYYY') + '-01-01T00:00:00'
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('sales/revenue/year/partner_gym', 'POST', requestData);

            if (responseData.status === 200) {
              if (isLastest) {
                setMemberships(responseData.data.memberships);
                setRevenue(responseData.data.sales);
              }
            }
            else if (responseData.status === 201) {
              if (isLastest) {
                setIsOpenPartnerGymSales(false);
                setMemberships([]);
                setRevenue([]);
              }
            }
            else if (responseData.status !== 204) {
              if (isLastest) {
                setMemberships([]);
                setRevenue([]);
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setRevenue([]);
          setIsOpenPartnerGymSales(false);
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, today])


  // // 지난달 총 매출 분석 데이터 조회 //
  // useEffect(() => {
    
  //   let isLastest = true;

  //   setIsRevenueLoading(true);

  //   if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
  //     setIsOpenPartnerGymSales(true);

  //     let requestData = {
  //       standardYear: today.clone().subtract(1, 'year').format('YYYY') + '-01-01T00:00:00'
  //     }

  //     const fetchModule = new FetchModule();
  //     (
  //       async () => {
  //         const responseData = await fetchModule.postDataVersion2('sales/revenue/year', 'POST', requestData);
  //         console.log('2222222');
  //         console.log(responseData.data);
  //         if (responseData.status === 200) {
  //           if (isLastest) {
  //             setPrevRevenue(responseData.data.sales);
  //           }
  //         }
  //         else if (responseData.status !== 204) {
  //           if (isLastest) {
  //             setPrevRevenue([]);
  //           }
  //         }
  //       }
  //     )();
  //   }
  //   else {
  //     if (selectedPartnerGym.isOpenPartnerGymSales) {
  //       let requestData = {
  //         storeId: selectedPartnerGym.storeId,
  //         standardYear: today.format('YYYY') + '-01-01T00:00:00'
  //       }

  //       const fetchModule = new FetchModule();
  //       (
  //         async () => {
  //           const responseData = await fetchModule.postDataVersion2('sales/revenue/year/partner_gym', 'POST', requestData);

  //           if (responseData.status === 200) {
  //             if (isLastest) {
  //               setPrevRevenue(responseData.data.sales);
  //             }
  //           }
  //           else if (responseData.status === 201) {
  //             if (isLastest) {
  //               setIsOpenPartnerGymSales(false);
  //               setPrevRevenue([]);
  //             }
  //           }
  //           else if (responseData.status !== 204) {
  //             if (isLastest) {
  //               setPrevRevenue([]);
  //             }
  //           }
  //         }
  //       )();
  //     }
  //     else {
  //       if (isLastest) {
  //         setPrevRevenue([]);
  //         setIsOpenPartnerGymSales(false);
  //       }
  //     }
  //   }

  //   return () => {
  //     isLastest = false;
  //   }
  // }, [selectedPartnerGym, today])

  // 월단위 구매내역 조회 //
  useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;

    let isLastest = true;

    // 도넛차트 로딩 에니메이션
    setIsDoughnutLoading(true);
    // 구매내역 로딩 에니메이션
    setIsSalesLoading(true);
    // 미수금 로딩 에니메이션
    setIsNotReceivedLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {

      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/history/month', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            let notReceivedList = [];
            let data = responseData.data.slice();

            for (let i = 0; i < data.length; i++) {
              if (data[i].notReceivedPrice > 0 && !data[i].isRefund) {

                let isRefund = false;

                for (let j = 0; j < data.length; j++) {
                  if (data[i].merchantUid === data[j].merchantUid && data[j].isRefund) {
                    isRefund = true;
                    break;
                  }
                }

                if (!isRefund) notReceivedList.push(data[i]);
              }
            }

            if (isLastest) {
              setSalesData(responseData.data);
              setNotReceivedData(notReceivedList);
            }
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {

        let requestData = {
          storeId: selectedPartnerGym.storeId,
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('sales/history/month/partner_gym', 'POST', requestData);

            if (responseData.status === 200) {
              let notReceivedList = [];
              let data = responseData.data.slice();

              for (let i = 0; i < data.length; i++) {
                if (data[i].notReceivedPrice > 0 && !data[i].isRefund) {

                  let isRefund = false;

                  for (let j = 0; j < data.length; j++) {
                    if (data[i].merchantUid === data[j].merchantUid && data[j].isRefund) {
                      isRefund = true;
                      break;
                    }
                  }

                  if (!isRefund) notReceivedList.push(data[i]);
                }
              }

              if (isLastest) {
                setSalesData(responseData.data);
                setNotReceivedData(notReceivedList);
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setSalesData([]);
                setNotReceivedData([]);
                setIsOpenPartnerGymSales(false);
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setSalesData([]);
          setNotReceivedData([]);
          setIsOpenPartnerGymSales(false);
        }
      }
    }
    
    return () => {
      isLastest = false;
    } 
  }, [selectedPartnerGym, startDate, endDate])

  // 평균 회원 지속일 데이터 불러오기 //
  useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;
    
    let isLastest = true;

    // 평균 회원 지속일 로딩 에니메이션 
    setIsDurationLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {

      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('membership/duration/avg', 'POST', requestData);

          if (responseData.status === 200) {
            let memberList = responseData.data.slice();
            let durationSum = 0;

            memberList.sort(function (a, b) {
              let x = a.duration;
              let y = b.duration;

              if (x < y) return 1;
              if (x > y) return -1;
            })

            for (let i = 0; i < memberList.length; i++) {
              if (memberList[i].duration < 1) durationSum += 1;
              else durationSum += memberList[i].duration;
            }

            if (isLastest) {
              setDurationAvg(parseFloat((durationSum / memberList.length).toFixed(1)));
              setDurationMembers(memberList);
              setIsOpenPartnerGymSales(true);
  
              setTimeout(() => {
                setIsDurationLoading(false);
              }, 1000)
            }
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        let requestData = {
          storeId: selectedPartnerGym.storeId,
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('membership/duration/avg/partner_gym', 'POST', requestData);

            if (responseData.status === 200) {
              let memberList = responseData.data.slice();
              let durationSum = 0;

              memberList.sort(function (a, b) {
                let x = a.duration;
                let y = b.duration;

                if (x < y) return 1;
                if (x > y) return -1;
              })

              for (let i = 0; i < memberList.length; i++) {
                if (memberList[i].duration < 1) durationSum += 1;
                else durationSum += memberList[i].duration;
              }

              if (isLastest) {
                setDurationAvg(parseFloat((durationSum / memberList.length).toFixed(1)));
                setDurationMembers(memberList);
                setIsOpenPartnerGymSales(true);
  
                setTimeout(() => {
                  setIsDurationLoading(false);
                }, 1000)
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setDurationAvg(0);
                setDurationMembers([]);
                setIsOpenPartnerGymSales(false);
  
                setTimeout(() => {
                  setIsDurationLoading(false);
                }, 1000)
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setDurationAvg(0);
          setDurationMembers([]);
          setIsOpenPartnerGymSales(false);
  
          setTimeout(() => {
            setIsDurationLoading(false);
          }, 1000)
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, startDate, endDate])

  // 수업 별 수강내역 데이터 불러오기 //
  useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;

    let isLastest = true;

    // 수업 별 수강내역 로딩 애니메이션
    setIsFrequencyLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/check/frequency', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            // setFrequencyData(responseData.data);
            let frequencyData = responseData.data.slice();

            frequencyData.sort(function (a, b) {
              let x = a.classTime;
              let y = b.classTime;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            if (isLastest) {
              setFrequencyData(frequencyData);

              setTimeout(() => {
                setIsFrequencyLoading(false);
              }, 1000)
            }
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        let requestData = {
          storeId: selectedPartnerGym.storeId,
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('reserve/check/frequency/partner_gym', 'POST', requestData);
            // console.log(responseData);
            if (responseData.status === 200) {
              // setFrequencyData(responseData.data);
              let frequencyData = responseData.data.slice();

              frequencyData.sort(function (a, b) {
                let x = a.classTime;
                let y = b.classTime;

                if (x > y) return 1;
                if (x < y) return -1;
              })

              if (isLastest) {
                setFrequencyData(frequencyData);
                setIsOpenPartnerGymSales(true);
  
                setTimeout(() => {
                  setIsFrequencyLoading(false);
                }, 1000)
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setFrequencyData([]);
                setIsOpenPartnerGymSales(false);
  
                setTimeout(() => {
                  setIsFrequencyLoading(false);
                }, 1000)
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setFrequencyData([]);
          setIsOpenPartnerGymSales(false);
  
          setTimeout(() => {
            setIsFrequencyLoading(false);
          }, 1000)
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, startDate, endDate])

  // 카카오 알림톡 사용 내역 데이터 불러오기 //
  useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;

    let isLastest = true;

    // 카카오 알림톡 로딩 애니메이션 //
    setIsBizMessageLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('biz/usage_history', 'POST', requestData);

          if (responseData.status === 200) {
            if (isLastest) {
              setBizMessageData(responseData.data);

              setTimeout(() => {
                setIsBizMessageLoading(false);
              }, 1000)
            }
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        let requestData = {
          storeId: selectedPartnerGym.storeId,
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('biz/usage_history/partner_gym', 'POST', requestData);

            if (responseData.status === 200) {
              if (isLastest) {
                setBizMessageData(responseData.data);
                setIsOpenPartnerGymSales(true);
  
                setTimeout(() => {
                  setIsBizMessageLoading(false);
                }, 1000)
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setBizMessageData([]);
                setIsOpenPartnerGymSales(false);
  
                setTimeout(() => {
                  setIsBizMessageLoading(false);
                }, 1000)
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setBizMessageData([]);
          setIsOpenPartnerGymSales(false);
  
          setTimeout(() => {
            setIsBizMessageLoading(false);
          }, 1000)
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, startDate, endDate])

   // 메시지 사용 내역 데이터 불러오기 //
   useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;

    let isLastest = true;

    // 메시지 로딩 애니메이션 //
    setIsMessageLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('message/usage_history', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            if (isLastest) {
              setMessageData(responseData.data);

              setTimeout(() => {
                setIsMessageLoading(false);
              }, 1000)
            }
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        let requestData = {
          storeId: selectedPartnerGym.storeId,
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('message/usage_history/partner_gym', 'POST', requestData);
            
            if (responseData.status === 200) {
              if (isLastest) {
                setMessageData(responseData.data);
                setIsOpenPartnerGymSales(true);
  
                setTimeout(() => {
                  setIsMessageLoading(false);
                }, 1000)
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setMessageData([]);
                setIsOpenPartnerGymSales(false);
  
                setTimeout(() => {
                  setIsMessageLoading(false);
                }, 1000)
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setMessageData([]);
          setIsOpenPartnerGymSales(false);
  
          setTimeout(() => {
            setIsMessageLoading(false);
          }, 1000)
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, startDate, endDate])

  // 회원 데이터 전체 불러오기 //
  useEffect(() => {
    let isLastest = true;

    setIsLoadingAllMember(true);
    setIsReregistLoading(true);
    setIsAliveLoading(true);
    setIsExpiredLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataVersion2('member/all/statistics', 'GET');
          // console.log('-----all-----');
          // console.log(responseData.data);
          if (responseData.status === 200) {
            if (isLastest) {
              setAllMembers(responseData.data);
              setIsLoadingAllMember(false);
            }
          }
          else if (responseData.status === 401) history.push('/');
          // else alert("서버와 연결이 불안정 합니다. 잠시후 다시시도해 주세요.");
          else {
            if (isLastest) {
              setAllMembers([]);
              setIsLoadingAllMember(false);
            }
          }
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.getDataVersion2('member/all/statistics/partner_gym/' + selectedPartnerGym.storeId, 'GET');
            // console.log('-----all-----');
            // console.log(responseData.data);
            if (responseData.status === 200) {
              if (isLastest) {
                setAllMembers(responseData.data);
                setIsLoadingAllMember(false);
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setAllMembers([]);
                setIsLoadingAllMember(false);
              }
            }
            // else alert("서버와 연결이 불안정 합니다. 잠시후 다시시도해 주세요.");
          }
        )();
      }
      else {
        if (isLastest) {
          setAllMembers([]);
          setIsLoadingAllMember(false);
        }
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym, startDate, endDate])

  // 월 단위 회원 출석 현황 불러오기 //
  useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;

    let isLastest = true;

    // 주간 출석률 로딩 애니메이션 //
    setIsWeekAttendanceLoading(true);
    // 월간 출석률 로딩 애니메이션 //
    setIsMonthAttendanceLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {

      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/monthly/attendance', 'POST', requestData);
          
          if (responseData.status === 200) {
            let attendances = JSON.parse(JSON.stringify(responseData.data));
            let totalAttendanceCount = 0;

            attendances.map((data) => {
              data.attendanceCount = data.reserveAttendances.length;

              if (data.attendanceCount > 0) totalAttendanceCount += 1;
            })

            attendances.sort((a, b) => b.attendanceCount - a.attendanceCount);

            if (isLastest) {
              // console.log(responseData.data); 
              setAttendanceTotal(responseData.data);
              setAttendanceRanking(attendances);
              setTotalMonthAttendanceCount(totalAttendanceCount);
              setIsMonthAttendanceLoading(false);
            }
          }
          else if (responseData.status === 401) history.push('/');
          else {
            if (isLastest) {
              setAttendanceTotal([]);
              setAttendanceRanking([]);
              setTotalMonthAttendanceCount(0);
              setIsMonthAttendanceLoading(false);
            }
          }
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {

        let requestData = {
          storeId: selectedPartnerGym.storeId,
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('sales/monthly/attendance/partner_gym', 'POST', requestData);
           
            if (responseData.status === 200) {
              let attendances = JSON.parse(JSON.stringify(responseData.data));
              let totalAttendanceCount = 0;
  
              attendances.map((data) => {
                data.attendanceCount = data.reserveAttendances.length;
  
                if (data.attendanceCount > 0) totalAttendanceCount += 1;
              })
  
              attendances.sort((a, b) => b.attendanceCount - a.attendanceCount);
  
              if (isLastest) {
                setAttendanceTotal(responseData.data);
                setAttendanceRanking(attendances);
                setTotalMonthAttendanceCount(totalAttendanceCount);
                setIsMonthAttendanceLoading(false);
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setAttendanceTotal([]);
                setAttendanceRanking([]);
                setTotalMonthAttendanceCount(0);
                setIsMonthAttendanceLoading(false);
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {
          setAttendanceTotal([]);
          setAttendanceRanking([]);
          setTotalMonthAttendanceCount(0);
          setIsMonthAttendanceLoading(false);
        }
      }
    }
    
    return () => {
      isLastest = false;
    } 
  }, [selectedPartnerGym, startDate, endDate])

  // 월 단위 연령별 이용 분석 및 실시간 출석 현황 불러오기 //
  useEffect(() => {
    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === endDate.format('YYYY-MM-DDTHH:mm:ss')) return;

    let isLastest = true;

    // 연령별 이용 분석 및 실시간 출석 현황 로딩 애니메이션 //
    setIsEngagementLoading(true);

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {

      let requestData = {
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59')
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('member/engagement', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            if (isLastest) {
              setEngagementReserves(responseData.data);
            }
          }
          else if (responseData.status === 401) history.push('/');
          else {
            if (isLastest) {
              setEngagementReserves(responseData.data);
            }
          }
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {

        let requestData = {
          storeId: selectedPartnerGym.storeId,
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DDT23:59:59')
        }
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('member/engagement/partner_gym', 'POST', requestData);
           
            if (responseData.status === 200) {
             
              if (isLastest) {
                setEngagementReserves(responseData.data);
              }
            }
            else if (responseData.status === 401) history.push('/');
            else {
              if (isLastest) {
                setEngagementReserves(responseData.data);
              }
            }
          }
        )();
      }
      else {
        if (isLastest) {

        }
      }
    }
    
    return () => {
      isLastest = false;
    } 
  }, [selectedPartnerGym, startDate, endDate])

  // 월별 매출 금액 불러오기 //
  useEffect(() => {
    let isLastest = true;

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/total/month', 'POST');
          // console.log(responseData);
          if (responseData.status === 200) {
            // setSalesTotal(responseData.data);

            let data = responseData.data.slice();
            let priceAvg = 0;

            for (let i = 0; i < data.length; i++) {
              priceAvg += (data[i].cardPrice + data[i].cashPrice + data[i].notReceivedPrice);
            }

            if (isLastest) setPriceAvg(Math.round(priceAvg / data.length));
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (selectedPartnerGym.isOpenPartnerGymSales) {
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.getDataVersion2('sales/total/month/partner_gym/' + selectedPartnerGym.storeId, 'GET');
            // console.log(responseData);
            if (responseData.status === 200) {

              let data = responseData.data.slice();
              let priceAvg = 0;

              for (let i = 0; i < data.length; i++) {
                priceAvg += (data[i].cardPrice + data[i].cashPrice + data[i].notReceivedPrice);
              }

              if (isLastest) setPriceAvg(Math.round(priceAvg / data.length));
            }
            else if (responseData.status === 401) history.push('/');
          }
        )();
      }
      else {
        if (isLastest) setPriceAvg(0);
      }
    }

    return () => {
      isLastest = false;
    }
  }, [selectedPartnerGym])

  function onClickNextMonth() {
    if (today.clone().add(1, 'month').format('YYYY.MM') <= moment().format('YYYY.MM')) {
      setIsDoughnutLoading(true);
      setToday(today.clone().add(1, 'month'));
    }
  }

  // 달력 컴포넌트 호버링 //
  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  // 달력 컴포넌트 호버링 //
  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }

  // 통계 날짜 텍스트 정리 //
  function calcDateText() {
    let start = moment(today.format('YYYY-MM-') + '01T00:00:00');
    let end = moment(start).add(1, 'month').subtract(1, 'second');

    if (!moment().isAfter(end)) end = moment(moment().format('YYYY-MM-DDT23:59:59'));

    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === start.format('YYYY-MM-DDTHH:mm:ss')
      && endDate.format('YYYY-MM-DDTHH:mm:ss') === end.format('YYYY-MM-DDTHH:mm:ss')) return today.clone().format('YYYY년 MM월');
    else {
      return startDate.format('YY년 MM월 DD일') + ' - ' + endDate.format('MM월 DD일');
    }
  }

  // 파트너 체육관 이름 표출 //
  function decideSelectedPartnerGymName() {
    if (selectedPartnerGym === undefined) return '';
    else return selectedPartnerGym.storeName;
  }

  // 파트너 체육관 이전 클릭시 //
  function onClickPrevSelectedPartnerGym() {
    if (selectedPartnerGymIndex - 1 >= 0) {
      setSelectedPartnerGym(partnerGyms[selectedPartnerGymIndex - 1]);
      setSelectedPartnerGymIndex(selectedPartnerGymIndex - 1);

      if (partnerGyms[selectedPartnerGymIndex - 1].isMyStore) setIsOpenPartnerGymSales(true);
      else setIsOpenPartnerGymSales(partnerGyms[selectedPartnerGymIndex - 1].isOpenPartnerGymSales);
    }
  }

  // 파트너 체육관 다음 클릭시 //
  function onClickNextSelectedPartnerGym() {
    if (selectedPartnerGymIndex + 1 < partnerGyms.length) {
      setSelectedPartnerGym(partnerGyms[selectedPartnerGymIndex + 1]);
      setSelectedPartnerGymIndex(selectedPartnerGymIndex + 1);

      if (partnerGyms[selectedPartnerGymIndex + 1].isMyStore) setIsOpenPartnerGymSales(true);
      else setIsOpenPartnerGymSales(partnerGyms[selectedPartnerGymIndex + 1].isOpenPartnerGymSales);
    }
  }

  // 파트너 체육관 매출 오픈 여부 확인 //
  function decideOpenSalesForPartnerGyms() {
    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) return true;
    else return selectedPartnerGym.isOpenPartnerGymSales;
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {
        isClickedCalender &&
        <CalenderModal
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onClickExitButton={() => setIsClickedCalender(false)} />
      }
      <TotalTitleWrapper>
        <TitleWrapper>
          <Title>통계</Title>
          <Explanation>센터의 매출 및 회원 등 모든 비즈니스 관련 통계를 확인하세요</Explanation>
        </TitleWrapper>
        {
          partnerGyms.length > 1 &&
          <PartnerGymSelectBox>
            <Button style={{ marginTop: '2px' }} src={selectedPartnerGymIndex === 0 ? NotAvailableLeftArrow : LeftArrow} onClick={onClickPrevSelectedPartnerGym} />
            <StoreNameTextWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#777777' cursor='default'>{decideSelectedPartnerGymName()}</Text>
            </StoreNameTextWrapper>
            <Button style={{ marginTop: '2px' }} src={selectedPartnerGymIndex === partnerGyms.length - 1 ? NotAvailableRightArrow : RightArrow} onClick={onClickNextSelectedPartnerGym} />
          </PartnerGymSelectBox>
        }
      </TotalTitleWrapper>
      <DateTotalWrapper>
        <DateButton>
          <Button src={LeftArrow} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
          {/* <DateText>{today.clone().format('YYYY년 MM월')}</DateText> */}
          <DateText>{calcDateText()}</DateText>
          <Button src={today.clone().format('YYYY.MM') === moment().format('YYYY.MM') ? NotAvailableRightArrow : RightArrow} onClick={onClickNextMonth} />
        </DateButton>
        <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setIsClickedCalender(true)} />
      </DateTotalWrapper>

      <SalesCalendar 
        standardDate={today} 
        yearSales={yearSalesData}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        setIsYearLoading={setIsYearLoading} />

      <GraphBox
        today={today}
        startDate={startDate}
        endDate={endDate}
        data={salesData}
        yearSalesData={yearSalesData}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isDoughnutLoading={isDoughnutLoading}
        setIsDoughnutLoading={setIsDoughnutLoading}
        isYearLoading={isYearLoading}
        setIsYearLoading={setIsYearLoading} />

      {/* <RevenueBox
        today={today}
        startDate={startDate}
        endDate={endDate}
        calcDateText={calcDateText}

        revenue={revenue}
        prevRevenue={prevRevenue}
        memberships={memberships}

        selectedPartnerGym={selectedPartnerGym}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}

        isRevenueLoading={isRevenueLoading}
        setIsRevenueLoading={setIsRevenueLoading}

        isWeekAttendanceLoading={isWeekAttendanceLoading}
        setIsWeekAttendanceLoading={setIsWeekAttendanceLoading}
        isMonthAttendanceLoading={isMonthAttendanceLoading}
        setIsMonthAttendanceLoading={setIsMonthAttendanceLoading}
        
        attendanceTotal={attendanceTotal}
        totalMonthAttendanceCount={totalMonthAttendanceCount}
        attendanceRanking={attendanceRanking}/> */}

      <MemberAttendanceBox
        today={today}
        startDate={startDate}
        endDate={endDate}
        calcDateText={calcDateText}

        selectedPartnerGym={selectedPartnerGym}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}

        isWeekAttendanceLoading={isWeekAttendanceLoading}
        setIsWeekAttendanceLoading={setIsWeekAttendanceLoading}
        isMonthAttendanceLoading={isMonthAttendanceLoading}
        setIsMonthAttendanceLoading={setIsMonthAttendanceLoading}
        
        attendanceTotal={attendanceTotal}
        totalMonthAttendanceCount={totalMonthAttendanceCount}
        attendanceRanking={attendanceRanking}/>
      <MemberMovementHistoryBox
        today={today}
        startDate={startDate}
        endDate={endDate}
        calcDateText={calcDateText}
        // leaveData={leaveMemberData}
        // aliveData={aliveMemberData}
        allMembers={allMembers}
        salesData={salesData}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isLoadingAllMember={isLoadingAllMember}
        isReregistLoading={isReregistLoading}
        setIsReregistLoading={setIsReregistLoading}
        isExpiredLoading={isExpiredLoading}
        setIsExpiredLoading={setIsExpiredLoading} />
      <MaintainMemberAndNewMemberView
        today={today}
        startDate={startDate}
        endDate={endDate}
        calcDateText={calcDateText}
        allMembers={allMembers}
        // leaveData={leaveMemberData}
        // aliveData={aliveMemberData}
        salesData={salesData}
        isLoadingAllMember={isLoadingAllMember}
        isAliveLoading={isAliveLoading}
        setIsAliveLoading={setIsAliveLoading}
        durationAvg={durationAvg}
        durationMembers={durationMembers}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isDurationLoading={isDurationLoading} />
      <DurationBox
        today={today}
        calcDateText={calcDateText}
        priceAvg={priceAvg}
        durationAvg={durationAvg}
        durationMembers={durationMembers}
        frequencyData={frequencyData}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isFrequencyLoading={isFrequencyLoading} />

      <MemberEngagementView
        today={today}
        calcDateText={calcDateText}
        engagementReserves={engagementReserves}
        selectedPartnerGym={selectedPartnerGym}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isEngagementLoading={isEngagementLoading}
        setIsEngagementLoading={setIsEngagementLoading}/>

      <NoticeKakaoBizView
        today={today}
        calcDateText={calcDateText}
        data={bizMessageData}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isLoading={isBizMessageLoading}
        setIsLoading={setIsBizMessageLoading} />
      <MessageSendHistoryView
        today={today}
        calcDateText={calcDateText}
        data={messageData}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isLoading={isBizMessageLoading}
        setIsLoading={setIsBizMessageLoading} />
      <NotReceivedHistory
        // today={today} 
        calcDateText={calcDateText}
        data={notReceivedData}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isNotReceivedLoading={isNotReceivedLoading}
        setIsNotReceivedLoading={setIsNotReceivedLoading} />
      <SalesHistory
        // today={today} 
        calcDateText={calcDateText}
        data={salesData}
        selectedPartnerGym={selectedPartnerGym}
        // isOpenPartnerGymSales={isOpenPartnerGymSales}
        isOpenPartnerGymSales={decideOpenSalesForPartnerGyms()}
        isSalesLoading={isSalesLoading}
        setIsSalesLoading={setIsSalesLoading} />
    </TopLevelWrapper>
  )
})

export default StatisticsView;

const TopLevelWrapper = styled.div`
  // position: relative;
  display: flex;
  flex-direction: column;

  // z-index: -3;
  // opacity: 0;
  z-index: 1;
  opacity: 1;
  
  transition: all 0.3s ease-in-out;
`;

const TotalTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
  // margin-left: 28px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const PartnerGymSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  // width: 251px;
  width: 227px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const DateTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  margin-top: 38px;
`;

const DateButton = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-evenly;
  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  // width: 205px;
  width: 261px;
  height: 38px;

  // gap: 37px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Button = styled.img`
  width: 10px;
  height: 17px;

  :hover {
    cursor: pointer;
  }
`;

const StoreNameTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 80%;
  height: 24px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.size}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
  margin-bottom: 96px;
`;