import React, { useEffect } from 'react';
import styled from 'styled-components';

export default function AppDeepLink({match}) {
  useEffect(() => {
    let appSchemeUrl = match.params.scheme;

    // 사용자 기기 정보 확인
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    // 앱이 설치되어 있다면 열기 위한 커스텀 URL 스킴
    const appScheme = 'linkcoach://' + appSchemeUrl;

    // 앱이 설치되지 않았을 경우 리다이렉트할 스토어 링크
    const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.linkcoach&pli=1';
    const appStoreUrl = 'https://apps.apple.com/app/id1601345842';

    // 현재 시간 기록 (딜레이 측정을 위해)
    const startTime = Date.now();

    // 앱 실행 시도
    window.location.href = appScheme;

    // 앱이 실행되지 않았다면 일정 시간 후 스토어로 이동
    setTimeout(() => {
      // 만약 앱이 실행되었다면 브라우저는 백그라운드로 전환되거나 페이지가 언마운트되었을 가능성이 있으므로,
      // 짧은 시간 내에 여기까지 실행되었다면 앱이 없는 것으로 판단.
      if (Date.now() - startTime < 5000) {
        if (isAndroid) {
          window.location.href = playStoreUrl;
        } else if (isIOS) {
          window.location.href = appStoreUrl;
        }
      }
    }, 2500);
  }, []);

  return (
    <TopLevelWrapper>
      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' cursor='default'>앱을 실행중...</Text>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;