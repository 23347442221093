import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import KakaoTalkSpeechBubbleIcon from '../../../image/KakaoTalkSpeechBubbleIcon.png';
import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import BizMessageSendList from './BizMessageSendList';

export default function NoticeKakaoBizView(props) {
  const { 
    today, 
    calcDateText, 
    data,
    selectedPartnerGym,
    isOpenPartnerGymSales, 
    isLoading, 
    setIsLoading 
  } = props;

  const [totalSendCount, setTotalSendCount] = useState(0);
  const history = useHistory();
  
  // 데이터 세팅 //
  useEffect(() => {
    let totalCount = 0;

    for (let i = 0; i < data.length; i++) {
      totalCount += data[i].successCount;
    }

    setIsLoading(false);
    setTotalSendCount(totalCount);
  }, [data])

  // 발송 내역 클릭시 //
  function onClickData(data) {
    if (data.noticeId !== 0 && (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore)) history.push('/notice/details/' + data.noticeId);
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <TitleWrapper>
          <Title>{calcDateText() + ' 카카오톡 메세지 발송 내역 ( ' + data.length + '회 )'}</Title>
        </TitleWrapper>
        <IconLineWrapper>
          <KakaoIconWrapper>
            <KakaoIconBox>
              <Image src={KakaoTalkSpeechBubbleIcon} />
            </KakaoIconBox>
            <KakaoTextBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>{`총 발송 횟수 ${totalSendCount.toLocaleString('ko-KR')}건`}</Text>
            </KakaoTextBox>
          </KakaoIconWrapper>
          <DivisionLine/>
          <TotalPriceBox>
            <TotalPriceTextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#595959' cursor='default'>{(totalSendCount * 10).toLocaleString('ko-KR')}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#BABABA' cursor='default'>원</Text>
            </TotalPriceTextWrapper>
          </TotalPriceBox>
        </IconLineWrapper>
        {
          isLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <TotalInfoBox>
              {
                isOpenPartnerGymSales ?
                  <BizMessageSendList data={data} onClickData={onClickData} />
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </TotalInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 24px 0px;

  width: 1020px;
  // height: 544px;

  margin-top: 38px;
  margin-bottom: 0px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 97.25%;
  width: 94.5%;
  // height: 544px;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-drection: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 439px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 439px;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  // margin: 28px 0 0 13px;
  margin: 0px;
  padding: 0px;
`;

const DivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 17px;
`;

const DivisionText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#232323` : `#D9D9D9`};

  margin: 0px;
  padding: 0px;

  :hover {
    cursor: pointer;
    color: #232323;
  }

  transition: all 0.3s ease-in-out;
`;

const IconLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 26px;

  width: 100%;
`;

const KakaoIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const KakaoIconBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 42px;
  height: 42px;

  border-radius: 8px;
  background-color: #F9E000;
`;

const KakaoTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 164px;
  height: 28px;

  border-radius: 8px;
  background-color: #F9E000;
`;

const Image = styled.img`
  width: 30px;
  height: 28px;
`;

const TotalPriceBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 108px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const TotalPriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  width: 607px;
  height: 1px;

  // margin: 0 16px 0 16px;
  // border: 1px solid #ECECEC;
  background-color: #ECECEC;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;