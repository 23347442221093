import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const SettingCoachGenderInput = forwardRef((props, ref) => {
  const [nowClickedGender, setNowClickedGender] = useState('Male');

  const MaleButtonRef = useRef();
  const MaleTextRef = useRef();
  const FemaleButtonRef = useRef();
  const FemaleTextRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
			return nowClickedGender;
		},
    setValue: (val) => {
      setNowClickedGender(val);
    }
	}));

  useEffect(() => {
    if (nowClickedGender === 'Male') {
      MaleButtonRef.current.style.backgroundColor = `#FF8B48`;
      MaleTextRef.current.style.color = `#FFFFFF`;
      FemaleButtonRef.current.style.backgroundColor = `#FFFFFF`;
      FemaleTextRef.current.style.color = `#000000`;
    }
    else if (nowClickedGender === 'Female') {
      MaleButtonRef.current.style.backgroundColor = `#FFFFFF`;
      MaleTextRef.current.style.color = `#000000`;
      FemaleButtonRef.current.style.backgroundColor = `#FF8B48`;
      FemaleTextRef.current.style.color = `#FFFFFF`;
    }
  }, [nowClickedGender])

  return(
    <TopLevelWrapper>
      <Button ref={MaleButtonRef} onClick={() => setNowClickedGender('Male')}>
        <ButtonText ref={MaleTextRef}>남성</ButtonText>
      </Button>
      <Button ref={FemaleButtonRef} onClick={() => setNowClickedGender('Female')}>
        <ButtonText ref={FemaleTextRef}>여성</ButtonText>
      </Button>
    </TopLevelWrapper>
  )
})

export default SettingCoachGenderInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direcion: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 167px;
  height: 34px;

  border: 1px solid #DFDFDF;
  border-radius: 5px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;