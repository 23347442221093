import { React, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

// import LockerMemberSearchInput from '../../../Component/LockerComponent/LockerMemberSearchInput';
// import LockerStartInput from '../../../Component/LockerComponent/LockerStartInput';
// import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';

const LockerHistoryModal = forwardRef((props, ref) => {
  const { exitClick, saveClick, data} = props;

  const history = useHistory();

  const [dataList, setDataList] = useState([]);

  const MemberRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const PriceRef = useRef();
  const CardRef = useRef();
  const CashRef = useRef();

  useImperativeHandle(ref, () => ({
		getMemberData: () => {
      return MemberRef.current.getValue();
    },
		getStartDate: () => {
      return StartCalenderRef.current.getValue();
    },
		getEndDate: () => {
      return EndCalenderRef.current.getValue();
    },
		getPrice: () => {
      return PriceRef.current.getValue();
    },
		getCard: () => {
      return CardRef.current.getValue();
    },
		getCash: () => {
      return CashRef.current.getValue();
    },
	}));

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    var requestData = {
      lockerId: data.id
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/usage/', 'POST', requestData);
        
        if (responseData.status === 200) {
          setDataList(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }, [])

  function onClickSendMessageButton(index) {
    let text = dataList[index].userName + '님!, 지금까지 사용하셨던 락커에 물품이 남아있어요!\n빠르게 확인해보세요!! :)';

    let requestData = {
      userId: dataList[index].userId,
      data: text
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("푸시알림을 전송하였습니다!");
        }
        else if (responseData.status === 204) {
          alert("Link Coach를 탈퇴한 회원입니다!");
        }
      }
    )();
  }

  // 전화번호 정리 //
  function calcPhoneView(data) {
    // 휴대폰 번호가 없는 회원인 경우 //
    if (data.phone === '') return '번호 없음';
    else return data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11);
  }

  // 생년월일 정리 //
  function calcBirthView(data) {
    return data.birth.slice(0, 4) + '.' + data.birth.slice(4, 6) + '.' + data.birth.slice(6, 8);
  }


  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <TitleWrapper>
            <Title>이용 내역</Title>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>과거 31번 락카를 이용한 회원 명단을 확인할수 있습니다</Text>
          </TitleWrapper>
          <InfoWrapper>
          {
            dataList.map((data, index) => (
              <InfoBox key={index}>
                <Table>
                  <Body>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `64px` }}>
                          <Name>{data.userName}</Name>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `72px`, marginLeft: `8px` }}>
                          <Phone>{calcBirthView(data)}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `98px`, marginLeft: `8px` }}>
                          <Phone>{calcPhoneView(data)}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `71px`, marginLeft: `8px` }}>
                          <State State={data.state}>{data.state}</State>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ maxHeight: '26px' }}>
                          {
                            data.state !== '사용중' &&
                            <SendButton>
                              <SendButtonText onClick={() => onClickSendMessageButton(index)}>분실물 알림 전송</SendButtonText>
                            </SendButton>
                          }
                        </TextWrapper>
                      </Td>
                    </Tr>
                  </Body>
                </Table>
              </InfoBox>
            ))
          }
        </InfoWrapper>
        <ExitButtonWrapper>
          <ExitButton onClick={exitClick}>닫기</ExitButton>
        </ExitButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerHistoryModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;

  width: 531px;
  height: 468px;

  border-radius: 10px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;


const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 30px;

  // width: 318px;
  width: 100%;
  height: 326px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  {/* width: 318px; */}
  width: 100%;
  // height: 32px;
  height: 40px;
  
  // border-bottom: 1px solid #F5F5F5;
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  {/* margin-left: 28px; */}
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Name = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const Phone = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const State = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.State === '사용중' ? `#5BD98A` : `#FF3131`};

  transition: all 0.2s ease-in-out;
`;

const SendButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  height: 26px;

  border-radius: 5px;
  background-color: #FF8B48;
`;

const SendButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #3E4148;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;