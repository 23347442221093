import { React, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import styled from 'styled-components';

const SecondPasswordInput = forwardRef((props, ref) => {
  const { className, onChangeValue } = props;

  const [value, setValue] = useState('');

  const InputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
		getValue: () => {
      return value;
		},
    reset: () => {
      setValue('');
    },
    focus: () => {
      InputRef.current.focus();
    }
	}));

  // 값 변경시 //
  function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경

    // 숫자 1자리까지만 허용
    if (result.length > 1) {
      result = result.slice(0, 1);
    }
    
    if (result !== '') {
      setValue(result);
      onChangeValue(className, result);
    }
  }

  // 비밀번호 가리개 표출 여부 결정 //
  function decideViewPassword() {
    if (value === '') return false;
    else return true;
  }

  return (
    <TopLevelWrapper>
      <Input
        ref={InputRef} 
        value={value} 
        onChange={handleChange} 
        size={1}/>
      { 
        decideViewPassword() && 
          <PasswordView>
            <Text style={{ marginTop: `14px` }} fontFamily='NotoSansKR-Medium' fontSize={36} color='#FFFFFF' cursor='default'>*</Text>
          </PasswordView> 
      }
    </TopLevelWrapper>
  )
})

export default SecondPasswordInput;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 34px;
  height: 34px;

  // border: ${props => props.border};
  border: 2px solid #FF8B48;
  border-radius: 10px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 38px;
	line-height: 20px;
	
	padding: 0 0 0 0;

	width: 40%;
  height: 100%;

	outline: none;
  
	color: #000000;
  border: 0px;

	// ::placeholder {
	// 	color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
	// }

  &:focus {
    outline: none;
  }
`;

const PasswordView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 8px;
  background-color: #FC6A16;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;